import { CommonService } from '../services/app.service';
class dataLoaders {
  constructor() {
  }

  static processRequest(obj: any) {
    const type = obj.type || 'get';
    switch (type.toLowerCase()) {
      case 'get':
      case 'post':
            return CommonService.reqAPI(obj);
      default:
    }
  };
};

export {
  dataLoaders
};
import { dataLoaders } from './dataLoader';
import { BaFramework } from './baFramework';

class HTMLTemplate {
    containerID: string;
    config: any;
    constructor(container: string, config: any = '') {
        this.containerID = container;
        this.config = config;
        this.renderHTMLTemplate()
    }
    
    public renderHTMLTemplate() {
        let smallScreen = this.config.smallScreen;
        if(this.config.templateId) {
            console.log('fn: renderHTMLTemplate ', this.config.templateId);
            var htmlTemplate = null;
            let requestOptions: any = {
                type: 'get',
                url: this.config.url,
            }
            dataLoaders.processRequest(requestOptions)
                .then((result: any) => {
                    console.log("Template successful");
                    htmlTemplate = JSON.parse(result);
                    this.renderTemplate(this.containerID, htmlTemplate, smallScreen);
                })
                .catch((err: any) => {
                    console.log(`Error while getting the data: ${err}`);
                })
        }
    }

    public renderTemplate(container, templateCode, smallScreen) {
        console.log('fn: renderTemplate ' + container);
        let Element = document.getElementById(container);
        Element.innerHTML = '';
        if(templateCode.template) {
            Element.innerHTML = templateCode.template;
        } else {
            Element.innerHTML = templateCode.templateCode.code;
        }
        let parentContainer = document.getElementsByClassName("htmlContainer") as HTMLCollectionOf<HTMLElement>;
        if(smallScreen === 'true') {
        //   parentContainer[0].style.width = '100%';
        } else {
        //   parentContainer[0].style.width = '600px';
        }
        if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents || templateCode.templateCode.renderComponents) {
            let renderData ;
            if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents) {
                renderData = templateCode.additionalProperties.renderComponents
            } else {
                renderData = templateCode.templateCode.renderComponents;
            }
            console.log(renderData);
            // this.renderComponentsCharts(renderData)
            var framework = new BaFramework();
            framework.renderComponents(renderData);
        }
    }
   
}

export {
    HTMLTemplate
}

import { dataLoaders } from './dataLoader';
import { CommonService } from '../services/app.service';
import {endpoints} from "../config/endpoints"
class analytics {
  USER_INFO: any = {};
  sessionId:any = '';
  // USERInfo: object = {
  //   handleId: 'gjanitt',
  //   // 'handleId': '941cd0bd-b4e1-489e-9b23-981005177606',
  //   userId: this.generateRandomId(), //'sai123',
  //   // 'profileImage': './assets/images/userprofile.png',
  //   userName: 'Tony',
  //   // 'userName': 'Tony Stark',
  //   'tenantId': '59ce808c99298e1e06660c09',//dynamic tenant
  //   'id': this.generateRandomId(),
  //   'channelId': 'CHN0001',
  //   'assetId': '1 101',
  //   'newUserId': 3,
  //   'topic': "Density 01 Magic PingPong Ball",
  //   'userToken': '',
  //   'analyticsSchema': '6139dce6e8d3cd0001430b74'
  // };
  constructor() {
    this.USER_INFO = CommonService.getUserInfo();
  }

  public generateRandomId() {
    return Math.random()
      .toString(13)
      .replace('0.', '')
  }

  public timeStamp() {
    return new Date().getTime()
  }
  public sendAdAnalytics(eventType, obj) {
    let defaultObj = {
      "appId": this.USER_INFO.appId,
      "appName": this.USER_INFO.appName,
      "tenantId": this.USER_INFO.tenantId,
      "userId": this.USER_INFO.userId,
      "timeStamp": this.timeStamp(),
      "sessionId": this.sessionId
    };
    defaultObj['city'] = this.USER_INFO.city || 'San Diego';
    defaultObj['latitude'] = this.USER_INFO.latitude || '0';
    defaultObj['longitude'] = this.USER_INFO.longitude || '0';
    defaultObj = Object.assign(defaultObj, obj);
    defaultObj = Object.assign(defaultObj, CommonService.getServiceInfo());
    defaultObj = Object.assign(defaultObj, CommonService.getDeviceInfo());
    const cookieArray = document.cookie.split(';');
    let loginToken = null;
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith('token=')) {
            loginToken = cookie.substring('token='.length);
            break;
        }
    }
    if(loginToken == null) {
      if(window.location.href.includes("aidtaas.com")){
        loginToken = localStorage.getItem("prodToken");
      }else if(window.location.href.includes("gaiansolutions.com") || window.location.href.includes("localhost")){
        loginToken = localStorage.getItem("devToken");
      }
    }
    let headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Authorization": `Bearer ${loginToken}`
    };
    let requestOptions: any = {
      type: 'POST',
      url: endpoints.SCHEMAS_URL(defaultObj.tenantId, '63087767d9fd420001e370e8'),
      payload: [defaultObj],
      headers: headers
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        // console.log('Analytics Sent success ', eventType);
      })
      .catch((err: any) => {
        console.log('Analytics Sent failed ', err);
      })
  }

  public sendAnalytics(eventType, obj) {
    if (eventType === 'open') {
      this.sessionId = this.generateRandomId();
    } else if (eventType === 'Ad') {
      this.sendAdAnalytics(eventType, obj);
      return;
    }
    let tenant = this.USER_INFO != undefined
    if (this.USER_INFO.tenantId != undefined && this.USER_INFO != '') {
      tenant = this.USER_INFO.tenantId
    } else {
      tenant = obj.tenantId;
    }
    let defaultObj = {
      "appId": this.USER_INFO.appId,
      "appName": this.USER_INFO.appName,
      "tenantId": tenant ,
      "userId": this.USER_INFO.userId,
      "timeStamp": this.timeStamp(),
      "sessionId": this.sessionId
    };
    defaultObj['city'] = this.USER_INFO.city || 'San Diego';
    defaultObj['latitude'] = this.USER_INFO.latitude || '0';
    defaultObj['longitude'] = this.USER_INFO.longitude || '0';
    defaultObj = Object.assign(defaultObj, obj);
    defaultObj = Object.assign(defaultObj, CommonService.getServiceInfo());
    defaultObj = Object.assign(defaultObj, CommonService.getDeviceInfo());
    const cookieArray = document.cookie.split(';');
    let loginToken = null;
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith('token=')) {
            loginToken = cookie.substring('token='.length);
            break;
        }
    }
    if(loginToken == null) {
      if(window.location.href.includes("aidtaas.com")){
        loginToken = localStorage.getItem("prodToken");
      }else if(window.location.href.includes("gaiansolutions.com") || window.location.href.includes("localhost")){
        loginToken = localStorage.getItem("devToken");
      }
    }
    let headers = {
      'Content-Type': 'application/json;charset=UTF-8',
      "Authorization": `Bearer ${loginToken}`
    };
    // if(obj && obj.eventType == 'launch') {
    //   var elem = `<div id="analytics_Cont" style="top: 0; position: absolute; width: 80%; height: 80vh;"><h1 style="position:relative; margin: 0; font-size: 14px;">${JSON.stringify(defaultObj)}</h1></div>`;
    //   document.body.innerHTML += elem;
    // } else {
    //   var elem = `<h1 style="position:relative; margin: 0; font-size: 14px;">${JSON.stringify(defaultObj)}</h1>`;
    //   var ele = document.getElementById('analytics_Cont');
    //   if(ele) {
    //     ele.innerHTML += elem
    //   } else {
    //     document.body.innerHTML += elem;
    //   }
    // }
    // var elem = `<h1 style='color:white; background-color: black;'>Analytics sending ${JSON.stringify(obj)}</h1>`;
            // document.body.innerHTML += elem;
    let requestOptions: any = { 
      type: 'POST',
      url: endpoints.SCHEMAS_POST_URL(defaultObj.tenantId, '6139dce6e8d3cd0001430b74'),
      payload: [defaultObj],
      headers: headers
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        // var elem = `<h1 style="color:white; background-color: black;">Analytics added</h1>`;
                // document.body.innerHTML += elem;
        // console.log('Analytics Sent success ', eventType);
      })
      .catch((err: any) => {
        console.log('Analytics Sent failed ', err);
        // var elem = `<h1 style="color:white; background-color: black;>Analytics send failed</h1>`;
                // document.body.innerHTML += elem;
      })
  }
}

export {
  analytics
}
import { CommonService } from '../services/app.service'
import { BaFramework } from './baFramework';
import { dataLoaders } from './dataLoader';
import { analytics } from './analytics';
import { endpoints } from '../config/endpoints';
// import { connect } from 'socket.io-client';
class clientSocket {
  //   containerID: string;
  db_data:any;
  socketURL: any = '';
  socket: any = '';
  isNetworkConnected: boolean = true;
  responseCodes: any = {
    'userInfo': 1,
    'deviceInfo': 2,
    'serviceInfo': 3,
    'connectionInfo': 4,
    'RMPStart': 5,
    'RMPStop': 6,
    'RMPResize': 7,
    'RMPPause': 8,
    'RMPResume': 9,
    'RMPScaling': 10,
    'BARefresh': 11,
    'CompainionMessage': 12,
    'keysInfo': 43,
    'keysTimeOutInfo': 44
  };
  companionMessgae: any;
  userInfo: any = {};
  deviceInfo: any = {};
  serviceInfo: any = {};
  networkInfo: any = {};
  playerInfo: any = {};
  mbCallBack:any;
  USER_INFO: any = {};
  analyticsInstance:any;
  constructor(socketURL: any = '') {
    this.USER_INFO = CommonService.getUserInfo();
    this.socketURL = socketURL;
    this.db_data = '';
    this.analyticsInstance = new analytics();
    if(this.socketURL) {
      this.connectToRA();
    }
  }

  public addKeyLaunch(elems) { 
    // use-keyboard-input - add this class to parent and 
    elems.forEach(element => {
      var par = element.parentElement;
      var ele = document.createElement('div');
      ele.classList.add('use-keyboard-input', 'focusable');
      var stylesheets = document.styleSheets;
      for (var i = 0; i < stylesheets.length; i++) {
        // @ts-ignore
          try {
            var rules =  stylesheets[i].cssRules || stylesheets[i].rules;
          for (var j = 0; j < rules.length; j++) {
            if(rules[j].type == 1){
              var rule = rules[j];
              // @ts-ignore
              if (element.matches(rule.selectorText)) {
                // @ts-ignore
                  ele.style.cssText = rule.style.cssText;
                break;
              }
            }
            else{
              continue;
            }
          }
          }
          catch (error){
            console.log('error' + error.message);
          }
      }
      // ele.id = element.id;
      element.removeAttribute('id');
      element.style.width = '100%';
      element.style.height = '100%';
      element.style.backgroundColor = 'inherit';
      ele.appendChild(element);
      par.appendChild(ele);
      console.log('element ', element);
    });
    let sendObj = {
      timeStamp: new Date().getMilliseconds(),
      assetName: `keypad elements processed`
    };
    this.analyticsInstance.sendAnalytics('analyticsLog', sendObj);
    // @ts-ignore
    Keyboard.init();

    let sendObj1 = {
      timeStamp: new Date().getMilliseconds(),
      assetName: `keypad init processed`
    };
    this.analyticsInstance.sendAnalytics('analyticsLog', sendObj1);
    setTimeout(() => {
      // @ts-ignore
      SpatialNavigation.init();
          // @ts-ignore
          SpatialNavigation.add({
              selector: '.focusable'
          });
          // @ts-ignore
          SpatialNavigation.makeFocusable();
          // @ts-ignore
          SpatialNavigation.focus();
    }, 100)
  }

  public processKeypad() {
    var elems = Array.from(document.getElementsByTagName('input'));
    if(elems.length > 0) {
      let sendObj = {
        timeStamp: new Date().getMilliseconds(),
        assetName: `processing keypad`
      };
      this.analyticsInstance.sendAnalytics('analyticsLog', sendObj);
        const script = document.createElement('script');
        script.src= endpoints.CONTENT_SERVICE_CDN_URL(this.USER_INFO.tenantId , 'caec5077-3c48-48db-a413-2fd050f99d68_$$keyboardScr.js');
        script.async = true;
        var that = this;
        script.onload = (() => {
          let sendObj = {
            timeStamp: new Date().getMilliseconds(),
            assetName: `keypad script loaded`
          };
          this.analyticsInstance.sendAnalytics('analyticsLog', sendObj);
          that.addKeyLaunch(elems);
          
        });
        document.head.appendChild(script);
    };
  }


  private queryDeviceInfo() {
    console.log('queryDeviceInfo');
    let deviceInfoQuery: any = {
      "jsonrpc": "2.0",
      "method": "org.atsc.query.deviceInfo",
      "id": this.responseCodes.deviceInfo
    };
    this.socket.send(JSON.stringify(deviceInfoQuery));
    return;
  }

  private queryUserInfo() {
    let userInfoQuery: any = {
      "jsonrpc": "2.0",
      "method": "org.atsc.query.userInfo",
      "id": this.responseCodes.userInfo
    };
    this.socket.send(JSON.stringify(userInfoQuery));
    return;
  }

  private queryServiceInfo() {
    let serviceInfoQuery: any = {
      "jsonrpc": "2.0",
      "method": "org.atsc.query.serviceInfo",
      "id": this.responseCodes.serviceInfo
    };
    this.socket.send(JSON.stringify(serviceInfoQuery));
    return;
  }

  private queryConnectionInfo() {
    let connectionInfoQuery: any = {
      "jsonrpc": "2.0",
      "method": "org.atsc.query.networkInfo",
      "id": this.responseCodes.connectionInfo
    };
    this.socket.send(JSON.stringify(connectionInfoQuery));
  }

  private queryRemoteKeysInfo() {
    let remoteKeysInfoQuery: any = { 
      "jsonrpc": "2.0", 
      "method": "org.atsc.request.keys", 
      "params": {"keys": ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Select", "Numeric", "Enter"]}, 
      "id": this.responseCodes.keysInfo 
    };
    this.socket.send(JSON.stringify(remoteKeysInfoQuery));
    let sendObj = {
      timeStamp: new Date().getMilliseconds(),
      assetName: `Socket send() request info: ${JSON.stringify(remoteKeysInfoQuery)}`
    };
    this.analyticsInstance.sendAnalytics('analyticsLog', sendObj);
    // let sendheaders = {
    //   'Content-Type': 'application/json;charset=UTF-8',
    //   "Authorization": `Bearer ${this.USER_INFO.token}`
    // };
    // let sendrequestOptions: any = {
    //   type: 'POST',
    //url: endpoints.SCHEMAS_URL('618b6fdef5dacc0001a6b1b0', '6139dce6e8d3cd0001430b74'),
    //   payload: [sendObj],
    //   headers: sendheaders
    // }
    // dataLoaders
    //   .processRequest(sendrequestOptions)
    //   .then((result: any) => {
    //     console.log('Socket send status added to schema');
    //   })
    //   .catch((err: any) => {
    //     console.log('Socket send status failed to add to schema');
    //   })
    return;
    }

    private queryRemoteKeysTimeOutInfo() {
      let remoteKeysTimeOutInfo: any = {
        "jsonrpc": "2.0",
        "method": "org.atsc.relinquish.keys",
        "params": {
        "keys": ["Select", "ChannelDown"]
        },
        "id": this.responseCodes.keysTimeOutInfo
       };
      this.socket.send(JSON.stringify(remoteKeysTimeOutInfo));
      let sendObj = {
        timeStamp: new Date().getMilliseconds(),
        assetName: `Socket send() request info: ${JSON.stringify(remoteKeysTimeOutInfo)}`
      };
      let sendheaders = {
        'Content-Type': 'application/json;charset=UTF-8',
        "Authorization": `Bearer ${this.USER_INFO.token}`
      };
      let sendrequestOptions: any = {
        type: 'POST',
        url: endpoints.SCHEMAS_URL(this.USER_INFO.tenantId, '6139dce6e8d3cd0001430b74'),
        payload: [sendObj],
        headers: sendheaders
      }
      dataLoaders
        .processRequest(sendrequestOptions)
        .then((result: any) => {
          console.log('Socket send status added to schema');
        })
        .catch((err: any) => {
          console.log('Socket send status failed to add to schema');
        })
      return;
      }

  private queryGaianRA() {
    console.log('query Gaian RA');
    this.queryUserInfo();
    this.queryServiceInfo();
    this.queryConnectionInfo();
    return;
  }

  public getConnectionStatus() {
    return this.isNetworkConnected;
  }

  public getNetworkInfo() {
    return this.networkInfo;
  }

  public registerMBCallBack(callBack) {
    this.mbCallBack = callBack;
    return true;
  }

  public onMessageBoardMessage(msg) {
    if(this.mbCallBack != '') this.mbCallBack(msg);
  }

  public getBARefreshData() {
    console.log('*** GET REFRESH CONFIG  ***');
    let requestOptions = {
      type: 'GET',
      url: './config.json',
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        try {
          var framework = new BaFramework();
          framework.BALoaded();
          var config = JSON.parse(result) || '';
          config = {
            'baAppConfig': {
                'config': JSON.parse(JSON.stringify(config))
            }
          };
          config.baAppConfig.config.bbandEntryPageUrl = config.baAppConfig.config.bbandEntryPageUrl || '';//baAppConfig
          framework.setBroadBandURL(config.baAppConfig.config.bbandEntryPageUrl);
          framework.BAppData('', config, '');
        } catch (e) {

        }
      })
      .catch((err: any) => {
        console.log('get refresh config failed !!!')
      })
  };

  // public sendIndexDB_logs() {
  //   // var keys = Object.keys(sessionStorage);
  //   //     keys.forEach(elem => {
  //   //       if(elem.includes('offline_')) {
  //   //         var sockheaders = {
  //   //           'Content-Type': 'application/json;charset=UTF-8',
  //   //           "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJzdWIiOiJnYWlhbi5jb20iLCJ1c2VyX25hbWUiOiJwb3J0YWxfdGVzdCIsInNjb3BlIjpbInRydXN0IiwicmVhZCIsIndyaXRlIl0sInRlbmFudElkIjoiNjExYmRkMzQyNmE5NDg2MDA1NjkzYjExIiwiaXNzIjoiZ2FpYW4uY29tIiwidXNlck5hbWUiOiJwb3J0YWxfdGVzdCIsImF1dGhvcml0aWVzIjpbIlJPTEVfT01OSV9DT05TVU1FUiIsIlJPTEVfTUFSS0VUUExBQ0VfVVNFUiIsIlJPTEVfT01OSV9VU0VSIl0sImp0aSI6IjgxODE1ZDNmLTY1MTAtNDJkNC05NWZkLTNiZTJmMWYzYjg5ZiIsImVtYWlsIjoicG9ydGFsX3Rlc3RAZ2F0ZXN0YXV0b21hdGlvbi5jb20iLCJjbGllbnRfaWQiOiJnYWlhbiJ9.Mz1gWLt1rujlQWW3SzuwtERk1i6HwG9utVuMUnL-RX4kKtR1jl0eR9MZmNjRZ0znbrr6w8MOj2aAULtpIEYmM9jU_mXGBuqetPIbTuV2d4Hkv6f0qaJZLAIAU3qhgijQI9O4a2yg_rmHnibNhEcZMKEFK5AXw8M_B8XIgnNYlXDkpjEqP6Siv0HJmHA3T1j1XY8PCsluzIwDzIgRr-xqAJcaCnUwGR7XxsF-X0plk8L9qV1Z3bF2EMqqBsednYeqaM3EqwJXk27R5PFU7jn5aOc-_n9DxaGLcuJB5JoqoGW7DeaIKLzMwxvS9vP_bc8vDOxl8xk-zTRAq8goyHV6IQ"
  //   //         };
  //   //         var sockrequestOptions = {
  //   //             type: 'POST',
  //   // url: endpoints.SCHEMAS_URL('618b6fdef5dacc0001a6b1b0', '6139dce6e8d3cd0001430b74'),
  //   //             payload: [{
  //   //               "appId": "Offline",
  //   //               "eventType": sessionStorage.getItem(elem),
  //   //               "appName": "app",
  //   //               "userId": "USR123",
  //   //               "timeStamp": Date.now(),
  //   //               "deviceMake": "Gaian",
  //   //               "tenantId": "SONY",
  //   //             }],
  //   //             headers: sockheaders
  //   //         };
  //   //         dataLoaders
  //   //             .processRequest(sockrequestOptions)
  //   //             .then(function (result) {
  //   //               sessionStorage.removeItem(elem)
  //   //               console.log('Socket before connection status added to schema');
  //   //             });
  //   //       }
  //   //     });
  //   //     var exists;
  //   //     keys.forEach(item => {
  //   //       if(item.includes('offline_')) {
  //   //         exists = true;
  //   //       }
  //   //     })
  //   //     if(!exists) {
  //   //       // @ts-ignore
  //   //       clearInterval(listenNetworkStatus);
  //   //       console.log('Cleared Interval');
  //   //       return true
  //   //     };
  //   var objectStore = this.db_data.transaction("logMessages").objectStore("logMessages");
  //   objectStore.openCursor().onsuccess = function(event) {
  //       var cursor = event.target.result;
  //       if (cursor) {
  //         let sockObj = cursor.value;
          
  //       } else {
  //         console.log("No more entries!");
  //         return true;
  //       }
  //   };
  // }

  public connectToRA() {
    console.log('***************************************************');
    console.log('***************************************************');
    console.log('fn: connectToRA: ' + this.socketURL);
    console.log('***************************************************');
    console.log('***************************************************');
    this.analyticsInstance.sendAnalytics('BA Constructor', {
      "eventType": "connectToRA"
    })
    const request = indexedDB.open('logs', 1);
    request.onerror = function (event) {
      // @ts-ignore
      console.log('Error opening the database:', event.target.errorCode);
    };
    var that = this;
    request.onupgradeneeded = function (event) {
      // @ts-ignore
      const db = event.target.result;
      const objectStore = db.createObjectStore('logMessages', { keyPath: 'id', autoIncrement: true });
      that.db_data = db;
    };

    function saveLogMessage(logMessage) {
      const request = indexedDB.open('logs', 1);
      request.onsuccess = function (event) {
        // @ts-ignore
        that.db_data = event.target.result;
        const transaction = that.db_data.transaction(['logMessages'], 'readwrite');
        const objectStore = transaction.objectStore('logMessages');
        objectStore.add({ message: logMessage, timestamp: Date.now() });
        transaction.oncomplete = function () {
          console.log('Log message saved successfully');
        };
        transaction.onerror = function (event) {
          console.log('Error saving log message:', event.target.errorCode);
        };
      };
    };

    if (navigator.onLine) {
      console.log('Device is online.');
      let sockObj = {
        timeStamp: new Date().getMilliseconds(),
        eventType: "Connecting to RA",
        assetName: `Socket about to connect: ${this.socketURL}`
      };
      this.analyticsInstance.sendAnalytics('analyticsLog', sockObj);
    } else {
      const logMessage = 'Socket about to connect';
      saveLogMessage(logMessage)
    }
    this.analyticsInstance.sendAnalytics('BA Constructor', {
      "eventType": `socket connecting ${this.socketURL}`
    })
    this.socket = new WebSocket(this.socketURL, 'atscCmd');
    this.socket.onopen = () => {
      console.log('Connection established with RA');
      if (navigator.onLine) {
        console.log('Device is online.');
        let openObj = {
          timeStamp: new Date().getMilliseconds(),
          eventType: "Connection Established",
          assetName: `Connection Established: ${this.socketURL}`
        };
        this.analyticsInstance.sendAnalytics('analyticsLog', openObj);
        this.queryDeviceInfo();
      }
      else {
        const logMessage = 'Connection Established';
        this.queryDeviceInfo();
        // sessionStorage.setItem('offline_2', logMessage);
        saveLogMessage(logMessage);
      }
      this.analyticsInstance.sendAnalytics('BA Constructor', {
        "eventType": `socket connected ${this.socketURL}`
      })
    }
    this.socket.onclose = () => {
      console.log('Disconnected from RA');
      if (navigator.onLine) {
        console.log('Device is online.');
        let closeObj = {
          timeStamp: new Date().getMilliseconds(),
          eventType: "Connection Closed",
          assetName: `Connection Closed: ${this.socketURL}`
        };
        this.analyticsInstance.sendAnalytics('analyticsLog', closeObj);
      }
      else {
        const logMessage = 'Connection Closed';
        // sessionStorage.setItem('offline_3', logMessage);
        saveLogMessage(logMessage);
      }
    }
    this.socket.onerror = (sockerror: any) => {
      if (navigator.onLine) {
        console.log('Socket connection error');
        let errorObj = {
          timeStamp: new Date().getMilliseconds(),
          eventType: "Connection Error",
          assetName: `Connection onerror: ${JSON.stringify(sockerror)}`
        };
        this.analyticsInstance.sendAnalytics('analyticsLog', errorObj);
      }
      else {
        const logMessage = 'Connection error';
        // sessionStorage.setItem('offline_4', logMessage);
        saveLogMessage(logMessage);

      }
      this.analyticsInstance.sendAnalytics('BA Constructor', {
        "eventType": `socket error`
      })
    };
    this.socket.onmessage = (msg: any) => {
      if (navigator.onLine) {
        let messageObj = {
          timeStamp: new Date().getMilliseconds(),
          eventType: "Connection onMessage",
          assetName: `Connection OnMessage: ${msg.data}`
        };
        this.analyticsInstance.sendAnalytics('analyticsLog', messageObj);
      }
      else{
        const logMessage = 'Connection OnMessage';
        // sessionStorage.setItem('offline_5', logMessage);
        saveLogMessage(logMessage);
      }
      try {
        console.log('BA: Message from RA: ' + msg.data);
        msg = typeof msg.data == 'string' ? JSON.parse(msg.data) : '';
      } catch (e) { return; }
      if (msg == '')
        return;
      console.log('JSON RPC response code: ' + msg.id);
      console.log('JSON RPC Message: ' + msg);
      saveLogMessage(JSON.stringify(msg));
      let messageObj = {
        timeStamp: new Date().getMilliseconds(),
        assetName: `Connection OnMessage: ${JSON.stringify(msg)}`
      };
      this.analyticsInstance.sendAnalytics('analyticsLog', messageObj);
      switch (msg.id) {
        case this.responseCodes.BARefresh:
          if (msg.method == 'org.atsc.acquire.service' && msg.params.isUpdateAvailable == true) {
            this.getBARefreshData();
            return;
          }
          break;
        case this.responseCodes.CompainionMessage:
          if (msg.method == 'org.atsc.query.messageInfo' && msg.params.message) {
            console.log('**** Reading Compainion Message ****')
            // this.companionMessgae = new MessageBoard();
            this.onMessageBoardMessage(msg.params.message);
            return;
          }
          break;
        case this.responseCodes.BARefresh:
          if (msg.method == 'org.atsc.acquire.service' && msg.params.isUpdateAvailable == true) {
            this.getBARefreshData();
            return;
          }
          break;
        case this.responseCodes.userInfo:
          this.userInfo = typeof msg.result == 'string' ? JSON.parse(msg.result) || {} : msg.result;
          this.userInfo['updatedTime'] = this.updateTime();
          console.log('USER_INFO: ' + JSON.stringify(this.userInfo));
          // @ts-ignore
          USER_INFO = this.userInfo != {} ? this.userInfo : USER_INFO;
          window.localStorage.setItem('userInfo', JSON.stringify(this.userInfo));
          CommonService.setUserInfo(this.userInfo);
          // console.log(window.localStorage.getItem('userInfo'));
          try {
            console.log("userInfo response" + JSON.stringify(this.userInfo));
          } catch (e) { }
          break;
        case this.responseCodes.deviceInfo:
          try {
            console.log('deviceInfo response: ' + JSON.stringify(msg.result));
          } catch (e) {
            console.error('non JSON response');
          }
          this.deviceInfo = typeof msg.result == 'string' ? JSON.parse(msg.result) || {} : msg.result;
          console.log('deviceMake: ' + this.deviceInfo.deviceMake);
          CommonService.setDeviceInfo(this.deviceInfo);
          this.queryGaianRA();
          this.queryRemoteKeysInfo();
          if (this.deviceInfo.deviceMake && this.deviceInfo.deviceMake.toLowerCase() == 'sony') {
            var that = this;
            setTimeout(() => {
              let messageObj = {
                timeStamp: new Date().getMilliseconds(),
                assetName: `adding keypad code`
              };
              that.analyticsInstance.sendAnalytics('adding keypad ', messageObj);
              that.processKeypad();
            }, 2000);
          }
          // this.queryRemoteKeysTimeOutInfo();
          // if (this.deviceInfo.deviceMake && this.deviceInfo.deviceMake.toLowerCase() == 'gaian') {
          //   this.queryGaianRA();
          //   this.queryRemoteKeysInfo();
          // } else { 
          //     this.queryGaianRA();
          //     this.queryRemoteKeysInfo();
          // }
          break;
        case this.responseCodes.serviceInfo:
          this.serviceInfo = typeof msg.result == 'string' ? JSON.parse(msg.result) || {} : msg.result;
          CommonService.setServiceInfo(this.serviceInfo);
          try {
            console.log("serviceInfo response" + JSON.stringify(this.serviceInfo));
          } catch (e) { }
          this.serviceInfo.topic = this.serviceInfo.assetName || '';
          break;
        case this.responseCodes.connectionInfo:
          this.networkInfo = typeof msg.result == 'string' ? JSON.parse(msg.result) || {} : msg.result;
          CommonService.setNetworkInfo(this.networkInfo);
          try {
            console.log("connectionInfo response" + JSON.stringify(this.networkInfo));
          } catch (e) { }
          break;
        case this.responseCodes.keysInfo:
          // this.closeBA();
          let messageObj = {
            timeStamp: new Date().getMilliseconds(),
            assetName: `Connection OnMessage: ${msg.id}`
          };
          this.analyticsInstance.sendAnalytics('analyticsLog', messageObj);
          break;
        case this.responseCodes.keysTimeOutInfo:
          let keysTimeOutInfo = {
            timeStamp: new Date().getMilliseconds(),
            assetName: `Connection OnMessage: ${msg.id}`
          };
          this.analyticsInstance.sendAnalytics('analyticsLog', keysTimeOutInfo);
          break;
        case this.responseCodes.RMPStart:
          // setPosition on receiving successful response of playback
          if (msg && msg.hasOwnProperty('result')) {
            this.resizeRMP(this.playerInfo.positions)
          }
          break;
        default:
          console.log('default case of socket response');
          break;
      }
    }
  }

  private runHttpServer(devicePath: any) {
    const express = require('express');
    const app = express();
    const usb = require('usb');
    const devices = usb.getDeviceList();
    const devicePaths = devices.map(device => device.deviceDescriptor.path);

    const desiredPath = devicePath;
    const foundPath = devicePaths.find(path => path === desiredPath || path.includes(devicePath));

    if (foundPath) {
      console.log('Found path:', foundPath);
      
      app.get(devicePath, (req, res) => {
        console.log('Server Connected Device Path: ' + devicePath);
      });
      app.listen(8000, () => {
        console.log('Server is running on http://localhost:8000');
      });
    } else {
      console.log('Path not found');
    }
  }

  private closeBA() {
    console.log('*** close BA ***');
    try {
        document.getElementsByTagName('body')[0].remove();
        // window.location.href = "/"
        // window?.close();
    } catch (error) {
        console.log('window not closed')
    }
    // System?.closeWebView(true);
  }

  private checkTime(i) {
    if (i < 10) {
      i = "0" + i;
    }
    return i;
  }

  private updateTime() {
    var monthinwords = new Array();
            monthinwords[0] = "Jan";
            monthinwords[1] = "Feb";
            monthinwords[2] = "Mar";
            monthinwords[3] = "Apr";
            monthinwords[4] = "Mar";
            monthinwords[5] = "Jun";
            monthinwords[6] = "Jul";
            monthinwords[7] = "Aug";
            monthinwords[8] = "Sep";
            monthinwords[9] = "Oct";
            monthinwords[10] = "Nov";
            monthinwords[11] = "Dec";
    var hours = new Date().getHours();
    var minutes = new Date().getMinutes();
    var date = new Date().getDate();
    var meridian;
    if (hours > 12) {
      meridian = 'PM';
      hours -= 12;
    } else if (hours < 12) {
      meridian = 'AM';
      if (hours == 0) {
        hours = 12;
      }
    } else {
      meridian = 'PM';
    }
    hours = this.checkTime(hours)
    minutes = this.checkTime(minutes)
    date  = this.checkTime(date)
    return `${monthinwords[new Date().getMonth()]} ${date}, ${hours}:${minutes} ${meridian}`
  }

  private getScalingPositions(containerId: string = '') {
    let container: any = document.getElementById(containerId) || '';
    if (container != '') {
      container.style.backgroundColor = 'transparent';
      let positions: Object = {
        scaleFactor: container.offsetWidth,
        width: container.offsetWidth,
        height: container.offsetHeight
      }
      return positions;
    } else return false
  }

  private getPositions(containerId: string = '') {
    let container: any = document.getElementById(containerId) || '';
    if (container != '') {
      //container.style.backgroundColor = 'transparent';
      let positions: Object = {
        left: container.offsetLeft,
        top: container.offsetTop,
        width: container.offsetWidth,
        height: container.offsetHeight
      }
      return positions;
    } else return false
  }
 
  // initiate playback of RMP with OTT stream
  // rmpUrl = http://stream.wxyz.com/33/program.mpd%22
  // service name = Curious Crew
  public startOTTRMPPlayback(streamingParams: any = "") {
    if(streamingParams == "")
      return false;
    let positions: any = this.getPositions(streamingParams.container);
    if(!positions)
      return false;
    this.playerInfo = {
      operation: "startRMP",
      mode: "broadband",
      rmpurl: streamingParams.otaRmpUrl || '',
      serviceName: streamingParams.otaServiceName || ''
    };
    
    let OTTParams = {
      jsonrpc: '2.0',
      method: 'org.atsc.setRMPURL',
      params: this.playerInfo,
      id: this.responseCodes.RMPStart
    }
    this.socket.send(JSON.stringify(OTTParams));
  }

  public startOTARMPPlayback(streamingParams: any = "") {
    if(streamingParams == "")
      return false;
    this.playerInfo = {
      operation: "startRMP",
      mode: "broadcast",
      frequency: streamingParams.otaFrequency || '',
      serviceName: streamingParams.otaServiceName || ''
    };
    //let reqparams: any = positions;
      //operation: 'startRmp',
      //serviceName: streamingParams.serviceName,

    // if(streamingParams.hasOwnProperty('otaRmpUrl')) {
    //   reqparams.rmpurl = streamingParams.otaRmpUrl;
    //   reqparams.mode = 'broadcast';
    // } else if(streamingParams.hasOwnProperty('otaFrequency')) {
    //   reqparams.frequency = streamingParams.otaFrequency;
    //   reqparams.mode = 'broadcast';
    // }
    let OTAParams = {
      jsonrpc: '2.0',
      method: 'org.atsc.setRMPURL',
      params: this.playerInfo,
      id: this.responseCodes.RMPStart
    }
    this.socket.send(JSON.stringify(OTAParams));
  }

  public stopRMPPlayback() {
    let reqParams: any = {
      jsonrpc: '2.0',
      method: 'org.atsc.setRMPURL',
      params: {
        operation: 'stopRmp'
      },
      id: this.responseCodes.RMPStop
    }
    this.socket.send(JSON.stringify(reqParams));
  }

  public pauseRMPPlayback() {
    let reqParams: any = {
      jsonrpc: '2.0',
      method: 'org.atsc.setRMPURL',
      params: {
        operation: 'pauseService'
      },
      id: this.responseCodes.RMPPause
    }
    this.socket.send(JSON.stringify(reqParams));
  }

  public resumeRMPPlayback() {
    let reqParams: any = {
      jsonrpc: '2.0',
      method: 'org.atsc.setRMPURL',
      params: {
        operation: 'resumeService'
      },
      id: this.responseCodes.RMPResume
    }
    this.socket.send(JSON.stringify(reqParams));
  }
 
  public convertResolution(position: any = {}) {
    let newPositions;
    interface newPositions {
      left: number,
      top: number,
      width: number,
      height: number
    };
    let parentWidth: number = window.innerWidth;
    let parentHeight: number = window.innerHeight;
    newPositions.left = (position.left * 1920) / parentWidth;
    newPositions.top = (position.top * 1080) / parentHeight;
    newPositions.width = (position.width * 1920) / parentWidth;
    newPositions.height = (position.height * 1080) / parentHeight;
    return newPositions;
  }

  public convertScaleResolution(position: any = {}) {
    let newScalePositions = position;
    let parentWidth: number = window.innerWidth;
    let parentHeight: number = window.innerHeight;
    newScalePositions.scaleFactor = (position.scaleFactor * 100) / window.innerWidth
    newScalePositions.xPos = (position.width * 100) / parentWidth;
    newScalePositions.yPos = (position.height * 100) / parentHeight;
    delete newScalePositions.width;
    delete newScalePositions.height;
    return newScalePositions;
  }

  private convertTopToBottom(positions) {
    positions.bottom = window.innerHeight - (positions.top + positions.height);
    delete positions.top;
    return positions;
  }

  public resizeRMP(positions: any = {}) {
    positions = this.convertResolution(positions);
    positions = this.convertTopToBottom(positions);
    let reqParams: any = {
      jsonrpc: '2.0',
      method: 'org.atsc.resizeRMP',
      params: positions,
      id: this.responseCodes.RMPResize
    }
      this.socket.send(JSON.stringify(reqParams));
  }

  public scalingPos(streamingParams: any = ""){
    if(streamingParams == "")
      return false;
    let positions: any = this.getScalingPositions(streamingParams.container);
    if(!positions)
      return false;
    positions = this.convertScaleResolution(positions);

    let reqparams: any = positions;

    let ScalingParams = {
      jsonrpc: '2.0',
      method: 'org.atsc.scale-position',
      params: reqparams,
      id: this.responseCodes.RMPScaling
    }
    this.socket.send(JSON.stringify(ScalingParams));
  }

  public getUserInfo() {
    return this.userInfo;
  }
}

export {
  clientSocket
}
import { CommonService } from '../services/app.service';
import { analytics } from './analytics';
import { payments } from './payments';
// import { BaFramework } from './baFramework';
class Cart {
    activeDetails: any;
    USER_INFO: any = {};
    selectedTitle: any;
    selectedRating: any;
    selectedQuantity: any;
    selectedTotal: any;
    selectedImage: string;
    selectedFundAmount: number;
    activeData: any;
    activeValue: number;
    totalfund: any;
    cartData: any;
    mainContainer: any;
    modes: any;
    changedStyles: any;
    analytics: any = '';
    constructor(container, data, changedStyles) {
        this.activeValue = 0;
        this.cartData = data;
        this.mainContainer = container;
        this.selectedQuantity = 0;
        this.selectedTotal = 0;
        this.selectedFundAmount = 0;
        this.changedStyles = changedStyles;
        this.analytics = new analytics();
        console.log(container, data, changedStyles)
        this.renderCartDom(container, data, changedStyles);
        this.USER_INFO = CommonService.getUserInfo();
    }

    public renderCartDom(container: any, data: any, changedStyles: any) {
        // console.log('Fn: renderCartDom ', container , data);
        document.getElementById(container).innerHTML = '';
        let cartDOM = `
        <div class="Cart_MainContainer " id="CART_MainContainer" style=" position: relative; width: 100%; transform-style: preserve-3d; perspective: 500px; z-index: 2;">
            <div style=" width: 100%; height: 100%; border-radius: 15px;" class="" id="CART_HeaderContainer" >
                <div style="left: 40px;" class="" id="CART_HeaderChildContainer">
                    <div style="margin: 5px; text-align: center;" class="" id="CART_HeaderDiv">
                        <h3 style="color: #fff;font-size: 1.2em;font-weight: bold;" class="" id="CART_Header">Make a Pledge  </h3>
                    </div>
                    <div style="margin: 5px; text-align: center;" id="CART_modes" class=""></div>
                </div>
            </div>
            <div id="CART_cartData" class="">
                <div id="CART_selectedCartDetails" class=""></div>
                <div style="margin: 5px; text-align: center;"  class="" id="CART_cartButton">
                    
                </div>
            </div>
        </div>
        `;
        let ele = document.createElement('div');
        ele.innerHTML = cartDOM;
        ele.style.width = '100%';
        ele.style.height = '100%';
        ele.style.borderRadius = '15px';
        ele.style.background = '#00000080';
        ele.style.border = '1px solid #ffffff66'; 
        document.getElementById(container).appendChild(ele);

        this.modes = Object.keys(data[0]);
        let modesContainer = document.getElementById('CART_modes');
        for (let i = 0; i < this.modes.length; i++) {
            let modeElement = document.createElement('button');
            modeElement.style.cssText += 'border: none; color: #fff; padding: 8px 7px; text-align: center; text-decoration: none; display: inline-block; font-size: 0.71em; margin: 0px 2px; background-color: #3d4006; cursor: pointer; border-radius: 14px;';
            modeElement.setAttribute('data', JSON.stringify(data[0][this.modes[i]]));
            modeElement.innerHTML = this.modes[i];
            modeElement.id = this.modes[i];
            modesContainer.appendChild(modeElement);
        }
        for (let i = 0; i < modesContainer.children.length; i++) {
            modesContainer.children[i].addEventListener('click', (event) => {
                this.selectedTitle = (<HTMLInputElement>event.target).id;
                // let clickedEle = document.getElementById(this.selectedTitle);
                // let seletectedDetails = clickedEle.getAttribute('data');
                // console.log(JSON.parse(seletectedDetails));
                // this.activeData = JSON.parse(seletectedDetails)[this.activeValue];
                this.activeData = data[0][this.selectedTitle][this.activeValue];
                this.selectedQuantity = this.activeData.selectedQuantity;
                this.selectedTotal = this.activeData.selectedTotal;
                this.totalfund = this.totalfund + this.activeData.selectedTotal;
                this.renderCartDynamicData(this.activeData, 'CART_selectedCartDetails', this.selectedTitle);
                this.quantityControl(this.activeData);
            });
        };
        this.activeData = data[0][this.modes[0]][this.activeValue];
        this.selectedTitle = this.modes[0];
        this.selectedQuantity = this.activeData.selectedQuantity;
        this.selectedTotal = this.activeData.selectedTotal;
        this.totalfund = this.activeData.selectedTotal;
        this.selectedImage = this.activeData.imageSrc;
        let cartDetails = {
            "selectedTitle":this.modes[0],
            "selectedQuantity":this.selectedQuantity,
            "selectedTotal":this.selectedTotal,
            "totalFund": this.totalfund,
            "selectedImage": this.selectedImage,
          }
          this.analytics.sendAnalytics('Focusable', cartDetails);
        this.renderCartDynamicData(this.activeData, 'CART_selectedCartDetails', this.selectedTitle);
        this.quantityControl(this.activeData);

        let CART_CHANGEDSTYLES_keys = Object.keys(this.changedStyles);
        for (let i = 0; i < CART_CHANGEDSTYLES_keys.length; i++) {
            let ele = document.getElementById(CART_CHANGEDSTYLES_keys[i]);
            ele.setAttribute('style', this.changedStyles[CART_CHANGEDSTYLES_keys[i]]);
        }
    }

    public renderCartDynamicData(data, container, title) {
        // console.log('renderCartDynamicData ', data);
        let cartdata = `
        <div style="margin: 5px; text-align: center; height: 25vh; position: relative" class="" id="CART_carousalContainer">
            <button style="display: none; position: absolute; top: 50%; left: 5%; border: none; background: rgba(0,0,0,0.6); font-size: 2rem; border-radius: 4px;"><i class="fa fa-angle-left" aria-hidden="true"></i></button>
                <div style="width: 80%; margin: 0 auto; left: 10%; height: 100%;" class="" id="CART_carousalDiv">
                    <img src="${data.imageSrc}" style="height: 100%" class="" id="CART_carousalImage"/>
                </div>
            <button style="display: none; position: absolute; top: 50%; right: 5%; border: none; background: rgba(0,0,0,0.6); font-size: 2rem; border-radius: 4px;"><i class="fa fa-angle-right" aria-hidden="true"></i></button>
        </div>
        <div style="margin: 5px; text-align: center;" class="" id="CART_titleContainer">
            <label style="color: #fff;font-size: 1em;" class=""  id="CART_selectedModeTitle">${title}</label>
        </div>
        <div style="margin: 5px; text-align: center;" class="" id="CART_ratingContainer">
            <label id="CART_ratingStars" class=""></label>
            <span style="color: #fff; font-size: 1em" id="CART_ratingValue" class=""></span>
        </div>
        <div style="margin: 5px; text-align: center;">
            <div style="display: inline-block" class="" id="INNER_CART_quantity">
                <button class="focusable " id="INNER_CART_quantityMinus" style="float: left; padding: 4px 8px; background-color: transparent; border: 1px solid #efefef; color: #fff; cursor: pointer; border-bottom-left-radius: 5px; border-top-left-radius: 5px; ">-</button>
                    <div title="Qty" class="" id="CART_quantityDiv" style=" float: left; padding: 4px 18px; text-align: center; background-color: transparent; border: 1px solid #efefef; color: #ffff;" size="4" pattern="" inputmode=""><span id="inner_count">${this.selectedQuantity}</span></div>
                <button class="focusable " id="INNER_CART_quantityPlus" style="padding: 4px 8px; background-color: transparent; border: 1px solid #efefef; color: #fff; cursor: pointer; border-bottom-right-radius: 5px; border-top-right-radius: 5px; ">+</button>
            </div>
        </div>
        <div style="margin: 5px; text-align: center;">
            <div class="" id="CART_carousalTotal" style="border: none; color: #fff; text-align: center; text-decoration: none; display: inline-block; cursor: pointer; border-radius: 14px; background-color: #2c2e30; padding: 10px 15px; font-size: 1.2em; margin: 5px 4px;">$<span id="inner_totalValue"> ${this.selectedTotal}</span></div>
        </div>`;
        document.getElementById(container).innerHTML = cartdata;
        this.innerQuantityControl(this.activeData);
        this.renderRating(data, 'CART_ratingStars', 'CART_ratingValue');
        let addCartButton = `<button id="CART_addCart" class="focusable " style="border: none; color: #fff; padding: 8px 12px; text-align: center; background-color: #2294e3; text-decoration: none; display: inline-block; font-size: 1em; margin: 0px 2px; cursor: pointer; border-radius: 14px;">ADD TO CART</button>`;
        document.getElementById('CART_cartButton').innerHTML = '';
        document.getElementById('CART_cartButton').innerHTML = addCartButton;
        document.getElementById('CART_addCart').addEventListener('click', () => {
            let cartDynamicData = {
                "imageSrc":data.imageSrc,
                "totalPrice":this.selectedTotal,
                "rating":data.rating,
                "selectedQuantity":this.selectedQuantity,
                "title":title
            }
            this.analytics.sendAnalytics('Focusable', cartDynamicData);
            this.addCart(this.activeData, this.mainContainer);
        });
    }

    public renderRating(data, ratingContainer, rating) {
        let totalRating = 5;
        let clientRating = Math.round(data.rating);
        let xRating = totalRating - clientRating;
        let ratingDom = document.getElementById(ratingContainer);
        ratingDom.innerHTML = '';
        document.getElementById(rating).innerHTML = data.rating;
        for (let i = 0; i < clientRating; i++) {
            let ratingstar = `<span class="fa fa-star" style="color: #ffc107; margin: 0 5px"></span>`;
            ratingDom.innerHTML += ratingstar;
        }
        for (let i = 0; i < xRating; i++) {
            let ratingstar = `<span class="fa fa-star-o" style="color: #ffc107; margin: 0 5px"></span>`;
            ratingDom.innerHTML += ratingstar;
        }
    }

    public innerQuantityControl = (activeMode) => {
        //   console.log('quantityControl ', activeMode);
        let quantityContainer = document.getElementById('INNER_CART_quantity');
        if(quantityContainer) {
            var quantityButtons = quantityContainer.getElementsByTagName('button');
            for (let i = 0; i < quantityButtons.length; i++) {
                quantityButtons[i].addEventListener('click', (event) => {
                    let count;
                    let presentCount = document.getElementById('inner_count').innerHTML;
                    let totalPrice;
                    let quantityType = (<HTMLInputElement>event.target).innerHTML;
                    if (quantityType === '+') {
                        count = Number(presentCount) + 1;
                        totalPrice = (count * activeMode.price);
                        this.selectedQuantity = count;
                        this.selectedTotal = totalPrice;
                        this.activeData['selectedQuantity'] = this.selectedQuantity;
                        this.activeData['selectedTotal'] = this.selectedTotal;
                    } else {
                        count = Number(presentCount) - 1;
                        if (count < 0) {
                            return
                        }
                        totalPrice = (count * activeMode.price);
                        this.selectedQuantity = count;
                        this.selectedTotal = totalPrice;
                        this.activeData['selectedQuantity'] = this.selectedQuantity;
                        this.activeData['selectedTotal'] = this.selectedTotal;
                    }
                    document.getElementById('inner_count').innerHTML = count;
                    document.getElementById('inner_totalValue').innerHTML = totalPrice;
                    this.getTotalFund();
                })
            }
        }
    }

    public quantityControl = (activeMode) => {
        //   console.log('quantityControl ', activeMode);
        try{
        let quantityContainer = document.getElementById('CART_quantity');
        if(quantityContainer) {
            var quantityButtons = quantityContainer.getElementsByTagName('button');
            for (let i = 0; i < quantityButtons.length; i++) {
                quantityButtons[i].addEventListener('click', (event) => {
                    let count;
                    let presentCount = document.getElementById('count').innerHTML;
                    let totalAmount;
                    let totalFundVal = document.getElementById('totalFund').innerHTML;
                    let totalPrice;
                    let quantityType = (<HTMLInputElement>event.target).innerHTML;
                    if (quantityType === '+') {
                        count = Number(presentCount) + 1;
                        totalAmount = Number(totalFundVal) + 1;
                        totalPrice = (count * activeMode.price);
                        this.selectedQuantity = count;
                        this.selectedTotal = totalPrice;
                        this.activeData['selectedQuantity'] = this.selectedQuantity;
                        this.activeData['selectedTotal'] = this.selectedTotal;
                    } else {
                        count = Number(presentCount) - 1;
                        totalAmount = Number(totalFundVal) - 1;
                        if (count < 0) {
                            return
                        }
                        totalPrice = (count * activeMode.price);
                        this.selectedQuantity = count;
                        this.selectedTotal = totalPrice;
                        this.activeData['selectedQuantity'] = this.selectedQuantity;
                        this.activeData['selectedTotal'] = this.selectedTotal;
                    }
                    document.getElementById('count').innerHTML = count;
                    document.getElementById('totalFund').innerHTML = totalAmount;
                    document.getElementById('totalValue').innerHTML = totalPrice;
                    this.getTotalFund();
                })
            }
        }
    }catch(err){
            // console.log(`No MappingIDs found: ${err}`);
          }
    }

    public addCart(data, container) {
        // console.log('addCart ', data);
        this.getTotalFund();
        let ele = `<div style=" width: 100%; height: 100%; border-radius: 15px; background: #00000080; border: 1px solid #ffffff66; ">
            <div style="  left: 40px;">
                <div style="margin: 5px; text-align: center;" class="" id="CART_HeaderDiv">
                    <h3 style="color: #fff; font-size: 1.2em; font-weight: bold;" class="" id="CART_Header">
                        <span id="back" style=" font-size: 1.8em; color: #fff; float: left; cursor: pointer;">
                            <i class="fa fa-arrow-circle-left"></i>
                        </span>  Make a Pledge
                    </h3>
                </div>

                <div style="margin: 5px; text-align: center; clear: both;">
                    <div style="float: left; width:45%; text-align: right">
                        <div style="margin: 5px; text-align: center;">
                            <label style="color: #fff">${this.selectedTitle}</label>
                        </div>
                        <div style="margin: 5px; text-align: center;" id="CART_finalRating">
                            <label id="CART_ratingStars" class=""></label>
                            <span style="color: #fff" id="CART_ratingValue" class=""></span>
                        </div>
                        <div style="margin: 5px; text-align: center;">
                            <div style="display: inline-block; height: 100%" id='CART_quantity'>
                                <button class="focusable " id="CART_quantityMinus" style="float: left; padding: 5px 10px; background-color: transparent; border: 1px solid #efefef; color: #fff; cursor: pointer; border-bottom-left-radius: 5px; border-top-left-radius: 5px; ">-</button>
                                    <div class="" id="CART_quantityDiv" type="number" name="quantity" style=" float: left; padding: 5px 25px; text-align: center; background-color: transparent; border: 1px solid #efefef; color: #ffff;" size="4" pattern="" inputmode=""><span id='count'>${this.selectedQuantity}</span></div>
                                <button class="focusable " id="CART_quantityPlus" style=" padding: 5px 10px; background-color: transparent; border: 1px solid #efefef; color: #fff; cursor: pointer; border-bottom-right-radius: 5px; border-top-right-radius: 5px; ">+</button>
                            </div>
                        </div>
                        <div style="margin: 5px; text-align: center;">
                            <div style="border: none; color: #fff; text-align: center; text-decoration: none; display: inline-block; cursor: pointer; border-radius: 14px; background-color: #2c2e30; padding: 13px 20px; font-size: 1.2em; font-weight: bold; margin: 5px 4px;">$<span id='totalValue'>${this.selectedTotal}</span> </div>
                        </div>
                    </div>
                    <div style="float: left; width:45%;">
                        <div style="margin: 5px; text-align: center;">
                            <img style="max-width: 65%;" src="${this.selectedImage}">
                        </div>
                    </div>
                </div>
                <div style="display: block; clear: both;">
                    <div style="margin: 5px; text-align: center;" class="" id="CART_Total">
                        <label class="" id="CART_totalLabel" style="color:#fff;">Total Fund:</label>
                        <div class="" id="CART_FullTotal" style="border: none; color: #fff; text-align: center; text-decoration: none; display: inline-block; cursor: pointer; border-radius: 1em; background-color: #2c2e30; padding: 10px 15px; font-size: 1.2em; font-weight: bold; margin: 5px 4px;">$<span id='totalFund'>${this.totalfund}</span> </div>
                    </div>
                </div>
                <div style="margin: 5px; text-align: center;" id="CART_addMoreButtons" class="">
                    <button class="" id="CART_Tendollar" style="border: none; width: 50px;height: 50px; color: #fff; text-align: center; text-decoration: none; display: inline-block; font-size: 1em; font-weight: bold; margin: 1%; cursor: pointer; border-radius: 50%; background-color: #b9c100;">+$10</button>
                    <button class="" id="CART_Twentydollar"style="border: none; width: 50px;height: 50px; color: #fff; text-align: center; text-decoration: none; display: inline-block; font-size: 1em; font-weight: bold; margin: 1%; cursor: pointer; border-radius: 50%; background-color: #b9c100;">+$20</button>
                    <button class="" id="CART_Fiftydollar"style="border: none; width: 50px;height: 50px; color: #fff; text-align: center; text-decoration: none; display: inline-block; font-size: 1em; font-weight: bold; margin: 1%; cursor: pointer; border-radius: 50%; background-color: #b9c100;">+$50</button>
                    <button class="" id="CART_hundereddollar"style="border: none; width: 50px;height: 50px; color: #fff; text-align: center; text-decoration: none; display: inline-block; font-size: 1em; font-weight: bold; margin: 1%; cursor: pointer; border-radius: 50%; background-color: #b9c100;">+$100</button>
                    <button class="" id="CART_OneFiftydollar"style="border: none; width: 50px;height: 50px; color: #fff; text-align: center; text-decoration: none; display: inline-block; font-size: 1em; font-weight: bold; margin: 1%; cursor: pointer; border-radius: 50%; background-color: #b9c100;">+$150</button>
                </div>
                <div style="margin: 5px; text-align: center;">
                    <button class="" id="CART_Paynow" style="border: none; color: #fff; padding: 8px 12px; text-align: center; font-weight: bold; background-color: #2294e3; text-decoration: none; display: inline-block; font-size: 1em; margin: 0px 2px; cursor: pointer; border-radius: 14px;">PAY NOW</button>
                </div>
            </div>
        </div>`;
        document.getElementById(container).innerHTML = ele;
        this.renderRating(data, 'CART_ratingStars', 'CART_ratingValue');
        this.quantityControl(data);
        let addMoreButtons = document.getElementById('CART_addMoreButtons').getElementsByTagName('button');
        for (let i = 0; i < addMoreButtons.length; i++) {
            addMoreButtons[i].addEventListener('click', (event) => {
                this.addMoreFund(event);
            })
        }
        document.getElementById('back').addEventListener('click', () => {
            console.log(this.mainContainer, this.cartData, this.changedStyles)
            this.renderCartDom(this.mainContainer, this.cartData, this.changedStyles);
        });
        let payNow = document.getElementById('CART_Paynow');
        payNow.addEventListener('click', () => {
            //// emailAddress is walletId as per payment API
            let paymentToEmailAdds ='sai.sanikala@gaiansolutions.com';
            if (this.USER_INFO.emailAddress) {
                payments.walletToWalletTransfer(this.totalfund, paymentToEmailAdds);
             } else {
                let paymentFormDiv = document.createElement('div');
                paymentFormDiv.style.width = '100%';
                paymentFormDiv.style.height = '100%';
                paymentFormDiv.style.position= 'absolute';
                paymentFormDiv.style.top= '0';
                paymentFormDiv.id = "paynow-container";
                paymentFormDiv.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                document.body.append(paymentFormDiv);
                payments.renderPaymentFromCC(paymentFormDiv.id, this.totalfund, 'USD');
                let paymentFormClose = document.createElement('span');
                paymentFormClose.style.width = '50px';
                paymentFormClose.style.height = '50px';
                paymentFormClose.classList.add('fa','fa-times-circle');
                paymentFormClose.style.top = '1%';
                paymentFormClose.style.left = '1%';
                paymentFormClose.style.backgroundColor = 'white';
                paymentFormClose.style.color = 'black';
                paymentFormClose.style.borderRadius = '50%';
                paymentFormClose.style.textAlign = 'center';
                paymentFormClose.style.lineHeight = '50px';
                paymentFormClose.style.fontSize = '15px';
                paymentFormClose.style.cursor = 'pointer';
                paymentFormClose.style.position = 'absolute';
                // paymentFormClose.style.fontSize = '3vw';
                // paymentFormClose.style.zIndex = '999999';
                paymentFormClose.addEventListener('click', (event) => {
                    document.getElementById('paynow-container').remove();
                });
                paymentFormDiv.appendChild(paymentFormClose);
             }
        });
    }
    
    public getTotalFund() {
        this.totalfund = 0;
        for (let i = 0; i < this.modes.length; i++) {
            for (let j = 0; j < this.cartData[0][this.modes[i]].length; j++) {
                this.totalfund = this.totalfund + this.cartData[0][this.modes[i]][j].selectedTotal;
            }
        }
        this.totalfund += this.selectedFundAmount;
    }

    public addMoreFund(event) {
        let fund = (<HTMLInputElement>event.target).innerHTML.replace('+$', '');
        this.totalfund -= this.selectedFundAmount;
        this.selectedFundAmount = Number(fund);
        this.totalfund += this.selectedFundAmount;
        document.getElementById('totalFund').innerHTML = this.totalfund;
        let moreFund = {
            "totaFund": this.totalfund
        }
        this.analytics.sendAnalytics('Focusable', moreFund);
    }
}

export { Cart }
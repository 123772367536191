import { dataLoaders } from './dataLoader';
import { CommonService } from '../services/app.service';
class payments {

    
    USER_INFO: any = CommonService.getUserInfo();
    constructor() {
        // this.USER_INFO = CommonService.getUserInfo();
        // this.renderPaymentFromCC(container, rupee, type);
    }

    static generateRandomId() {
        return Math.random()
            .toString(13)
            .replace('0.', '')
    }

    public timeStamp() {
        return new Date().getTime()
    }

    /// amount debit from loggedIn user and send it to 'toUserID'
    static walletToWalletTransfer(amount, toWalletId) {
        var ApiUrl = CommonService.getPaymentAPI();
        var USER_INFO = CommonService.getUserInfo();
        USER_INFO.token = USER_INFO.token || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJzdWIiOiJnYWlhbi5jb20iLCJ1c2VyX25hbWUiOiJwb3J0YWxfdGVzdCIsInNjb3BlIjpbInRydXN0IiwicmVhZCIsIndyaXRlIl0sInRlbmFudElkIjoiNjExYmRkMzQyNmE5NDg2MDA1NjkzYjExIiwiaXNzIjoiZ2FpYW4uY29tIiwidXNlck5hbWUiOiJwb3J0YWxfdGVzdCIsImF1dGhvcml0aWVzIjpbIlJPTEVfT01OSV9DT05TVU1FUiIsIlJPTEVfTUFSS0VUUExBQ0VfVVNFUiIsIlJPTEVfT01OSV9VU0VSIl0sImp0aSI6IjgxODE1ZDNmLTY1MTAtNDJkNC05NWZkLTNiZTJmMWYzYjg5ZiIsImVtYWlsIjoicG9ydGFsX3Rlc3RAZ2F0ZXN0YXV0b21hdGlvbi5jb20iLCJjbGllbnRfaWQiOiJnYWlhbiJ9.Mz1gWLt1rujlQWW3SzuwtERk1i6HwG9utVuMUnL-RX4kKtR1jl0eR9MZmNjRZ0znbrr6w8MOj2aAULtpIEYmM9jU_mXGBuqetPIbTuV2d4Hkv6f0qaJZLAIAU3qhgijQI9O4a2yg_rmHnibNhEcZMKEFK5AXw8M_B8XIgnNYlXDkpjEqP6Siv0HJmHA3T1j1XY8PCsluzIwDzIgRr-xqAJcaCnUwGR7XxsF-X0plk8L9qV1Z3bF2EMqqBsednYeqaM3EqwJXk27R5PFU7jn5aOc-_n9DxaGLcuJB5JoqoGW7DeaIKLzMwxvS9vP_bc8vDOxl8xk-zTRAq8goyHV6IQ';
        let reqObj = {
            "amount": amount,
            "fromUserId": USER_INFO.emailAddress,
            "toUserId": toWalletId
        };
        let headers = {
            'Content-Type': 'application/json;charset=UTF-8',
            "Authorization": `Bearer ${USER_INFO.token}`
        };
        let requestOptions: any = {
            type: 'GET',
            url: `${ApiUrl}wallet/debit/${reqObj.fromUserId}/${reqObj.toUserId}/${reqObj.amount}`,
            payload: [reqObj],
            headers: headers
        }
        dataLoaders
            .processRequest(requestOptions)
            .then((result: any) => {
                let succcessResp = JSON.parse(result);
                alert(succcessResp.msg);
               // console.log('success: ', result);
            })
            .catch((err: any) => {
                let errorResp = JSON.parse(err);
                alert(errorResp.msg);
                // console.log('error: ', err);
            })
    }

    static renderPaymentFromCC(container, rupee, type) {
        let formDom = `
        <div id="Form_94ac72c308a3535" class="FormField ba-element" containertype="fineElement" style="width: 31.8089%; text-align: center; padding: 10px; inset: 13.6821% auto auto 29.9797%; height: auto; cursor: text; min-height: 0px; min-width: 0px; background-color: rgb(118, 50, 50);" data-gramm="false" contenteditable="false" title="Form_94ac72c308a3535" isresizing="false" backgroundcolor="#763232" margintop="3"   marginleft="0">
            <div id="FormField_93b114b5c6388a4" class="FormField addingBorder">
                <label id="FormLabel_93b114b5c6388a4"  class="ba-relv" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;">Name<span style="color: red;">*</span></label>
                    <input type="text" class="ba-relv focusable addingBorder" id="DivInput_93b114b5c6388a4" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" ismandatory="true" required="true" paddingtop="0" paddingright="undefined" paddingleft="2" paddingbottom="undefined" placeholder="Enter text">
            </div>
            <div id="FormField_8815c4863b667aa" class="FormField addingBorder">
                <label id="FormLabel_8815c4863b667aa"  class="ba-relv" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="5"   marginleft="0">emailId<span style="color: red;">*</span></label>
                    <input type="email" class="ba-relv focusable addingBorder" id="DivInput_8815c4863b667aa" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" ismandatory="true" required="true" placeholder="Enter text" paddingtop="1" paddingright="undefined" paddingleft="5" paddingbottom="undefined">
            </div>
            <div id="FormField_3980707b3164acb" class="FormField addingBorder">
                <label id="FormLabel_3980707b3164acb"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;">Mobile<span style="color: red;">*</span></label>
                <input type="number" class="ba-relv focusable addingBorder" id="DivInput_3980707b3164acb" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri; line-height: 19px;" ismandatory="true" required="true" placeholder="Enter text">
            </div>
            <div id="FormField_0ac0a2c6b8c3a3" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">Address1<span style="color: red;">*</span></label>
                <input type="text" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" ismandatory="true" required="true" placeholder="Enter text">
            </div>
            <div id="FormField_0ac0a2c6b8c3e4" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">Address2<span style="color: red;">*</span></label>
                <input type="text" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" placeholder="Enter text" ismandatory="true" required="true">
            </div>
            <div id="FormField_0ac0a2c6b8c3e4" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">City<span style="color: red;">*</span></label>
                <input type="text" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" placeholder="Enter text" ismandatory="true" required="true">
            </div>
            <div id="FormField_0ac0a2c6b8c3e4" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">Country<span style="color: red;">*</span></label>
                <input type="text" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" placeholder="Enter text" ismandatory="true" required="true">
            </div>
            <div id="FormField_0ac0a2c6b8c3e4" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">Pin<span style="color: red;">*</span></label>
                <input type="number" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" placeholder="Enter Pin" ismandatory="true" required="true">
            </div>
            <div id="FormField_0ac0a2c6b8c3e4" class="FormField addingBorder">
                <label id="FormLabel_0ac0a2c6b8c3a3"  class="ba-relv addingBorder" style="width: 100%; text-align: left; font-size: 15px; color: rgb(255, 255, 255); font-family: calibri;" margintop="0"   marginleft="0">State<span style="color: red;">*</span></label>
                <input type="text" class="ba-relv focusable addingBorder" id="DivInput_0ac0a2c6b8c3a3" style="width: 96%; max-width: 100%; height: 40px; border-radius: 3px; border: none; font-size: 14px; font-family: calibri;" placeholder="Enter State" ismandatory="true" required="true">
            </div>
            <button class="ba-relv btn btn-success activeElement" id="FormButton" style="margin-top: 5px; font-size: 15px;">Submit</button>
        </div>
        <h3 containertype="fineElement" id="H3_42345a3a070a5c5" style="display: block; margin: 0px; width: 22.1545%; max-width: 100%; inset: 5.03018% auto auto 34.3496%; height: 6.63984%; cursor: text; text-align: center; min-height: 0px; min-width: 0px; font-family: ahronbd;" data-gramm="false" contenteditable="false" class="ba-element" title="H3_42345a3a070a5c5" isresizing="false">Payment form</h3>`
        document.getElementById(container).innerHTML = formDom;
        document.getElementById("FormButton").addEventListener("click", () => {
            this.validatePaymentForm(container, rupee, type);
        });
    }


    static camelize(str) {
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
          if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
          return index === 0 ? match.toLowerCase() : match.toUpperCase()
        })
    }

    static validatePaymentForm(container, totalAmount, currencyType) {
        var USER_INFO = CommonService.getUserInfo();
        let validateContainer = document.getElementById(container)
        let payload = {}
        if (validateContainer) {
            let labels = validateContainer.getElementsByTagName('label');
            let keysArr = []
            for (let i = 0; i < labels.length; i++) {
                let val = labels[i].textContent.trim()
                val = val.replace(/\*/g, '')
                val = val.trim();
                val = this.camelize(val)
                keysArr.push(val)
            }
            console.log('************* ', keysArr);
            let Forminputs = validateContainer.getElementsByTagName('input');
            for (let i = 0; i < Forminputs.length; i++) {
                let mandate: any = Forminputs[i].getAttribute('ismandatory') || ''
                mandate = mandate === '' ? false : mandate === 'true' ? true : false
                let inputType = Forminputs[i].getAttribute('type') || 'text'
                let defaultValue: any = ''
                switch (inputType) {
                  case 'number':
                    defaultValue = 0
                    break
                  case 'text':
                    defaultValue = ''
                    break
                  default:
                    defaultValue = ''
                    break
                }
                if (Forminputs[i].value == '') {
                  if (mandate) {
                    return
                  } else {
                    Forminputs[i].value == defaultValue;
                  }
                }
                let val
                if (inputType === 'number') {
                  val = JSON.parse(Forminputs[i].value)
                } else {
                  val = Forminputs[i].value
                }
                payload[keysArr[i]] = val
            }
            let data = {
                'customer': {
                    // @ts-ignore
                    'mobile': JSON.stringify(payload.mobile) || 0,
                    // @ts-ignore
                    'name': payload.name || '', // @ts-ignore
                    'emailId': payload.emailId || '', // @ts-ignore
                    'phone': '051-527957'|| 0,
                    'profileId': USER_INFO.profileId, // @ts-ignore
                    'address': {// @ts-ignore
                        "address1": payload.address1,// @ts-ignore
                        "address2": payload.address2,// @ts-ignore
                        "city": payload.city,// @ts-ignore
                        "country": payload.country,// @ts-ignore
                        "id": 0,// @ts-ignore
                        "pin": JSON.stringify(payload.pin),// @ts-ignore
                        "state": payload.state, // @ts-ignore
                    }
                },
                'clientKey': USER_INFO.clientKey,
                'totalAmount': totalAmount,
                'currency': currencyType,
                'clientRedirectUrl': USER_INFO.clientRedirectUrl,
                'paymentType': USER_INFO.paymentType,
                'gatewayName': USER_INFO.gatewayName,
                'clientId': USER_INFO.clientId,
                'orderId': 'DIPWALI_1678' || this.generateRandomId(),
                'orderDescription': 'Dipwali Bumpar Dhamaka Offer.'
            }
            this.paymentSubmit(data, container);
        }
    }

    static paymentSubmit(payload, container) {
        var USER_INFO = CommonService.getUserInfo();
      let requestOptions: any = {
        type: 'POST',
        url: `http://dev-ingress-gateway.gaiansolutions.com/payment-service/payments/initiate`,
        payload: payload,
        headers: { "Authorization": `Bearer ${USER_INFO.token}`,
         'Content-Type': 'application/json' }
      }
      dataLoaders
        .processRequest(requestOptions)
        .then((result: any) => {
          console.log('Show Payment Page !!!');
          result = JSON.parse(result);
          let token = result.token;
          let url = result.gatewayUrl;
          this.showPaymentPage(container, token, url)
        })
        .catch((err: any) => {
            alert('Unable to process your request at this moment')
            console.log(`Payment Failure: ${err} !!!`);
        })
    }

    static showPaymentPage(container, token, url) {
        var ele = `<form method="post" action="${url}" id="formAuthorizeNetTestPage" name="formAuthorizeNetTestPage" style="width:100%; height: 100%;">
		    <input type="hidden" name="token" value="${token}" />
            <button id="btnContinue"></button>
        </form>`;
        document.getElementById(container).innerHTML = ele;
        document.getElementById('btnContinue').click();
        // let requestOptions: any = {
        //     type: 'POST',
        //     url: url,
        //     headers: { 
        //         "Authorization": `${token}`, 
        //     }
        //   }
        // dataLoaders
        // .processRequest(requestOptions)
        // .then((result: any) => {
          
        // })
        // .catch((err: any) => {
        //   console.log(`Payment Failure: ${err} !!!`);
        // })
    }

}

export {
    payments
}
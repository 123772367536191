export const API_HOST = process.env.API_HOST;
export const CDN_HOST = process.env.CDN_HOST;
export const DEV_API_HOST = process.env.DEV_API_HOST;
export const endpoints = {
    MULTIAPP_URL: (appid) =>  `${API_HOST}/monet-service/v1.0/multiApp?id=${appid}`,
    ENGAGEMENTS_URL: (tenantID, appID) => `${API_HOST}/engagements-web/v1.0/${tenantID}/engagements/${appID}`,
    GRAPHS_DATA_URL:(tenantID, graphID) => `${API_HOST}/tf-web/v2.0/${tenantID}/graphs/${graphID}/data`,
    GRAPHS_DEFINITION_URL:(tenantID, graphID) => `${API_HOST}/tf-web/v2.0/${tenantID}/graphs/${graphID}/definition`,
    MAPPING_API_PROCESSOR_URL: (tenantID, mapID) => `${API_HOST}/mappingservice-api-processor/v1.0/${tenantID}/map/${mapID}/api/response`,
    SCHEMAS_URL: (tenantID, schemaID) => `${API_HOST}/tf-web/v1.0/${tenantID}/schemas/${schemaID}/instances/list`,
    INTERACTIVITY_SERVICE_URL: (tenantID, userID, assetID, topic) => `${API_HOST}/interactivity-service/v1/${tenantID}/${userID}/asset/${assetID}/questions?topic=${topic}`,
    DASHBOARDS_URL: (tenantID, dashboardID) => `${API_HOST}/tf-web/v2.0/${tenantID}/dashboards/${dashboardID}`,
    BA_STORE_SERVICE_URL: (tenantID, appID) => `${API_HOST}/broadcasterappstore-service/${tenantID}/1194/baApp/${appID}`,
    BA_STORE_SERVICE_V_URL: (tenantID, appID, versionID) => `${API_HOST}/broadcasterappstore-service/${tenantID}/1194/baApp/${appID}/${versionID}`,
    ANALYTIC_QUERIES_URL: (tenantID, aqID) => `${API_HOST}/tf-web/v1.0/${tenantID}/analytic-queries/${aqID}/data`,
    GROUPS_URL: (tenantID, groupID) => `${API_HOST}/tf-web/v1.0/${tenantID}/groups/${groupID}/data`,
    DATA_URL: (tenantID, type, ID) => `${API_HOST}/tf-web/v1.0/${tenantID}/${type}/${groupID}/data`,
    EXPERIENCES_URL: (tenantID, monetID) => `${API_HOST}/engagements-web/v1.0/${tenantID}/experiences/match/${monetID}`,
    SOCIAL_FEATURE_URL: (tenantID) => `${API_HOST}/socialfeatureapis/api/v1/${tenantID}/users`,
    TEMPLATE_SERVICE_URL: (tenantID, templateID) => `${API_HOST}/template-service/${tenantID}/${tenantID}/template/${templateID}`,
    APP_URL: (appID) => `${API_HOST}/monet-service/v1.0/ba-app-hist?id=${appID}`,
    DEPLOYED_APP_URL: (appID) => `${API_HOST}/monet-service/v1.0/ba-app/${appID}?published=true`,
    APP_VERSION_URL: (appID) => `${API_HOST}/monet-service/${appID}/latestVersion`,
    WIDGET_URL: (widgetID, type) => `${API_HOST}/monet-service/v1.0/widgets?${type}=${widgetID}`,
    SCHEMAS_POST_URL: (tenantID, schemaID) => `${API_HOST}/tf-web/v1.0/${tenantID}/schemas/${schemaID}/instances`,


    CONTENT_SERVICE_CDN_URL: (tenantID, contentID) => `${CDN_HOST}/${tenantID}/${tenantID}/${tenantID}/${contentID}`,
    CONTENT_SERVICE_URL: (contentID) => `${API_HOST}/content-service/v1.0/content/${contentID}`,
    CONTENT_SERVICE_BB_URL: (htmlFileID) => `${CDN_HOST}/content-service/ba/broadband/6214f2ce2fbb840001baaf6d_v_1/${htmlFileID}`,
    CONTENT_SERVICE_UPLOAD_URL: (tenantID, boardTag, fileName) => `${API_HOST}/content-service/v1.0/content/upload/${tenantID}/${tenantID}/1194?filePath=${tenantID}/${tenantID}/&tags=${boardTag}&fileName=${fileName}&overrideFile=true`,

    DEV_GRAPH_MAPPING_URL: (tenantID) => `${DEV_API_HOST}/tfw-web/v2/${tenantID}/graph/mapping/list`,
    DEV_CMS_DOWNLOAD_URL: (contentID) => `${DEV_API_HOST}:80/content-service/v1/download/${contentID}`
}



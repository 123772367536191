import { CommonService } from '../services/app.service';
import { API_HOST, endpoints } from '../config/endpoints';

const authToken =  CommonService.getToken() || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJzdWIiOiJnYWlhbi5jb20iLCJ1c2VyX25hbWUiOiJwb3J0YWxfdGVzdCIsInNjb3BlIjpbInRydXN0IiwicmVhZCIsIndyaXRlIl0sInRlbmFudElkIjoiNjExYmRkMzQyNmE5NDg2MDA1NjkzYjExIiwiaXNzIjoiZ2FpYW4uY29tIiwidXNlck5hbWUiOiJwb3J0YWxfdGVzdCIsImF1dGhvcml0aWVzIjpbIlJPTEVfT01OSV9DT05TVU1FUiIsIlJPTEVfTUFSS0VUUExBQ0VfVVNFUiIsIlJPTEVfT01OSV9VU0VSIl0sImp0aSI6IjgxODE1ZDNmLTY1MTAtNDJkNC05NWZkLTNiZTJmMWYzYjg5ZiIsImVtYWlsIjoicG9ydGFsX3Rlc3RAZ2F0ZXN0YXV0b21hdGlvbi5jb20iLCJjbGllbnRfaWQiOiJnYWlhbiJ9.Mz1gWLt1rujlQWW3SzuwtERk1i6HwG9utVuMUnL-RX4kKtR1jl0eR9MZmNjRZ0znbrr6w8MOj2aAULtpIEYmM9jU_mXGBuqetPIbTuV2d4Hkv6f0qaJZLAIAU3qhgijQI9O4a2yg_rmHnibNhEcZMKEFK5AXw8M_B8XIgnNYlXDkpjEqP6Siv0HJmHA3T1j1XY8PCsluzIwDzIgRr-xqAJcaCnUwGR7XxsF-X0plk8L9qV1Z3bF2EMqqBsednYeqaM3EqwJXk27R5PFU7jn5aOc-_n9DxaGLcuJB5JoqoGW7DeaIKLzMwxvS9vP_bc8vDOxl8xk-zTRAq8goyHV6IQ';
class DataCache {
    dataCache: any
    timeout: any
    locks: any

    constructor(timeout) {
        this.dataCache = {};
        this.timeout = timeout || 60000; // 1 minute by default
        this.locks = {};
    }

    async getData(key, offlineURL, filters, elID, recordsPerFetch = 10, recordToFetch, fetchAllRecords) {

        // Check if data is already in cache
        if (this.dataCache[key] && Date.now() - this.dataCache[key].timestamp < this.timeout) {
            return this.dataCache[key].data;
        }

        // Check if data is already being fetched
        if (this.locks[key]) {
            // Wait for the data to be fetched
            await new Promise((resolve) => {
                const waitInterval = setInterval(() => {
                    if (!this.locks[key]) {
                        clearInterval(waitInterval);
                        resolve();
                    }
                }, 100);
            });
            // Return the data from the cache
            return this.dataCache[key].data;
        }

        // Acquire a lock on the data and start fetching it
        this.locks[key] = true;
        try {
            // const data = await fetchDataFromApi(key); 
            var data;
            var type = {};
            if(key.includes('page')) {
              data = fetchDataFromApi(key)
            } else if(recordToFetch) {
              data = fetchDataFromApi(`${key}?page=0&size=${recordToFetch}`)
              type['recordToFetch'] = recordToFetch;
            } else if(fetchAllRecords) {
              data = fetchAllApiData(key);
              type['fetchAllRecords'] = fetchAllRecords;
            } else {
              data = fetchAllApiData(key);
            }
            // const data = !key.includes('page') ? await fetchAllApiData(key) : await fetchDataFromApi(key)
            // const data = await fetchCompleteDataFromApi(key);
            // Store the data in the cache and release the lock
            this.dataCache[key] = { data, timestamp: Date.now(), type };
            // listenToSocket(key, type);
            delete this.locks[key];
            return data;
        } catch (err) {
            delete this.locks[key];
            throw err;
        }
    }

    async getOfflineData(key) {
        try {
            const data = await fetchDataFromApi(`${key}.json`);
            // Store the data in the cache and release the lock
            this.dataCache[key] = { data, timestamp: Date.now() };
            delete this.locks[key];
            return data;
        } catch (err) {
            delete this.locks[key];
            throw err;
        }
        // await new Promise((resolve, reject) => {
        //     try {
        //         var xhttp = new XMLHttpRequest();
        //         xhttp.onreadystatechange = function() {
        //             if (this.readyState == 4 && this.status == 200) {
        //                 var response = xhttp.responseText || '';
        //                 console.log(response)
        //                 if (response != '') {
        //                     const data = JSON.parse(response)
        //                     debugger;
        //                     return data;
        //                 } else {
        //                     return {};
        //                 }
        //             }
        //         }
        //         xhttp.open("GET", `./${id}.json`, true);
        //         xhttp.send();
        //     } catch (error) {
        //         debugger;
        //         reject(error)
        //     }
        // })
    }
}

export {
    DataCache
};


async function fetchDataFromApi(apiURL) {
    // Fetch data from API
    const response = await fetch(apiURL, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`
      }
    });
    if (!response.ok) {
        throw new Error(`Failed to fetch data for ${apiURL}: ${response.status}`);
    }
    const data = await response.json();
    return data;
}


var RECORDS_PER_PAGE = 100;

async function fetchCompleteDataFromApi(apiURL) {
  try {
    var PAGE = 0;
    var FETCHED_COMPLETE_RECORDS = false;
    var TOTAL_DATA = [];
    var data;

    const fetchData = async () => {
      const response = await fetch(
        `${apiURL}?page=${PAGE}&size=${RECORDS_PER_PAGE}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );
      data = await response.json();

      if (data?.model?.entities) {
        data.model.entities.forEach((element) => {
          TOTAL_DATA.push(element);
        });
        if (data.model.entities.length < RECORDS_PER_PAGE) {
          FETCHED_COMPLETE_RECORDS = true;
        } else {
          PAGE++;
          await fetchData();
        }
      } else if (data.entities) {
        data.entities.forEach((element) => {
          TOTAL_DATA.push(element);
        });

        if (data.entities.length < RECORDS_PER_PAGE) {
          FETCHED_COMPLETE_RECORDS = true;
        } else {
          PAGE++;
          await fetchData();
        }
      }
    };

    await fetchData();

    if (FETCHED_COMPLETE_RECORDS) {
      // All data has been fetched
      if(data.model) {
        data.model.entities = TOTAL_DATA;
      } else if(data.entities) {
        data.entities = TOTAL_DATA;
      }
      return data;
    }
  } catch (error) {
    console.error("Error:", error);
  }
}

// Fetch complete data for each API URL
const fetchAllApiData = async (apiURL) => {
  const allApiData = [];
  const apiData = await fetchCompleteDataFromApi(apiURL);
  allApiData.push(apiData);
  return apiData;
};

// Socket code
function processURL(URL) {
  var splitted = URL.split('/');
  var SOURCE_ID = splitted[splitted.length - 2];
  return SOURCE_ID;
}

async function listenToSocket(url, type) {
  var SOURCE_ID = await processURL(url);
  console.log('listenToSocket ', SOURCE_ID);
  let socketConfig = {
      'reconnection': true,
      'reconnectionDelay': 1000,
      'reconnectionDelayMax': 5000,
      'reconnectionAttempts': 50
  };

  var socket = io('https://tf.gaiansolutions.com:443/', socketConfig);
  socket.on('connect', () => {
      console.log('socket connected');
      socket.emit('newConnection', 'groupSession', '', [SOURCE_ID], []);
      socket.on('updateGroup', (updatedData) => {
        var data:any = {} = JSON.stringify(updatedData);
        var apiURL = data?.notificationType == 'GROUP_CHANGED' ? endpoints.GROUPS_URL(data.tenantId, data.queryId) : endpoints.ANALYTIC_QUERIES_URL(data.tenantId, data.queryId);
        console.log("groupChangeEvent = " + JSON.stringify(updatedData), "apiURL = " + apiURL);

        if(apiURL.includes('page')) {
          data = fetchDataFromApi(apiURL)
        } else if(type.recordToFetch != null || type.recordToFetch != "") {
          data = fetchDataFromApi(`${apiURL}?page=0&size=${type.recordToFetch}`)
        } else if(type.fetchAllRecords != undefined || type.fetchAllRecords != "") {
          data = fetchAllApiData(apiURL);
        } else {
          data = fetchDataFromApi(`${apiURL}?page=0&size=10`);
        }
        alert();
      });
  });
  socket.on("connect_error", () => {
    console.error('connect_error')
  });
  socket.on("connect_timeout", () => {
    console.error('connect_timeout')
  });
  socket.on("reconnect_error", () => {
    console.error('reconnect_error')
  });
  socket.on("reconnect_failed", () => {
    console.error('reconnect_failed')
  });
}
import { dataLoaders } from './dataLoader'
class Htmlwidget {
  containerID: string;
  config: any;
 constructor(container: string, config: any = '') {
   this.containerID = container;
   this.config = config;
   this.renderHTMLWidget()
 }    
 public renderHTMLWidget() {
  console.log('fn: renderHTMLWidget');
   let reqUrl = this.config.htmlWidgetUrl;
  
  let requestOptions = {
      type: 'get',
      url: reqUrl
  }
  dataLoaders.processRequest(requestOptions)
      .then((result: any) => {
          console.log("Get Tweets successful: " + result);
          try {
            if (result) {
              result = JSON.parse(result) || '';
              this.containerID = this.containerID ? this.containerID : 'ba_app';
              var divId = document.getElementById(this.containerID);
                divId.innerHTML = result.template || '';
              }
            } catch (e) {
              console.error("Error while processing response: " + e);
            }
      })
      .catch((err: any) => {
          console.log(`Error while getting the data: ${err}`);
      })
  
 }  
}

export {
  Htmlwidget
}
import { endpoints } from '../config/endpoints';
class FirstResponder {
    constructor(container, cameraObj) {
        this.renderFirstResponder(container, cameraObj)
    }

    public renderFirstResponder(container: any, cameraObj: any) {
        let ele = `
        <div style="width: 100%; height: 100%; position: relative;" id="FirstResponder_1628082186217">
            <div id="geoheatmapChart_05789c6c90aac495" style="width: 100%; height: 100%; postion: absolute">
            </div>
            <div id="MessageBoard_1628046354315" messageboard="6788712758444560384" class="ba-relv" style="z-index: 9999; position: absolute; width: 30%; height: 60%; top: 20%; right: 5%;">
            </div>
            <div id="CameraViews" class="ba-relv" style="display: none; position: absolute; width: 20%; max-height: 65%; top: 20%; left: 0px; z-index: 999; background-color: black; overflow-y: auto; overflow-x: hidden; min-height: 20%;">

            </div>
            <div id="CameraTabs" class="ba-relv" style="padding: 10px 5px; position: absolute; width: 30%; height: 10%; top: 10%; right: 12%; z-index: 999;"></div>
        </div>
        `;
        let elem = document.createElement('div');
        elem.style.width = '100%';
        elem.style.height = '100%';
        elem.innerHTML = ele;
        document.getElementById(container).appendChild(elem);
        let cameraTabsContainer = document.getElementById('CameraTabs');
        let cameraViewsContainer = document.getElementById('CameraViews');
        for(let i = 0 ; i < cameraObj.length; i++) {
            console.log(cameraObj[i]);
            let data = JSON.stringify(cameraObj[i]);
            let tab = `
        <div class="camera-tab ba-relv" data=${data} id="Div_${Math.random().toString(13).replace('0.', '')}" style="background-color: #fff; width: 19%; height: 100%; float: left; padding: 10px; margin-right: 1%; border-radius: 3px;">
                    <img class="ba-relv" id="image_${Math.random().toString(13).replace('0.', '')}" src=${endpoints.CONTENT_SERVICE_CDN_URL('59ce808c99298e1e06660c09', 'cctv.png')} alt="cctv" style="width: 80%; float: left; height: 100%;">
                    <label class="ba-relv" id="Span_${Math.random().toString(13).replace('0.', '')}" style="width: 20%; float: right; height: 100%; magin: 0">${i + 1}</label>
                </div>
            `;
            cameraTabsContainer.innerHTML += tab;
            let tabViews = `
                <div class="Videos" style="margin: 5px 0; width: 100%; height: 20%; display: none;" id=${cameraObj[i].bind_to}>
                    <video style="width: 100%; height: 100%" src=${cameraObj[i].videoSrc}></video>
                </div>
            `;
            cameraViewsContainer.innerHTML += tabViews
        }
        let cameraTabs = document.getElementsByClassName('camera-tab');
        for( let i = 0 ; i < cameraTabs.length ; i++) {
            cameraTabs[i].addEventListener('click', () => {
                let obj = cameraTabs[i].getAttribute('data');
                let id = cameraTabs[i].id;
                this.showVideoTab(JSON.parse(obj), id);
            })
        }
    }

    showVideoTab(obj, id) {
        console.log('showVideoTab ', obj, id);
        let cameraViewsContainer = document.getElementById('CameraViews') ;
        let ele = document.getElementById(id);
        let video = document.getElementById(obj.bind_to);
        if(ele.classList.contains('active')){
            ele.classList.remove('active');
            ele.style.backgroundColor = '#fff';
            video.style.display = 'none';
        } else if(!ele.classList.contains('active')) {
            ele.classList.add('active');
            ele.style.backgroundColor = '#6b60e4';
            video.style.display = 'block';
        }

        let v = Array.from(document.getElementsByClassName('Videos') as HTMLCollectionOf<HTMLElement>);
        for(let i = 0 ; i < v.length; i++) {
            let child = v[i].style.display;
            if(child === 'block') {
                cameraViewsContainer.style.display = 'block';
                return;
            } else {
                cameraViewsContainer.style.display = 'none';
            }
        };
    }

}

export {
    FirstResponder
}
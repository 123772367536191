
class Parameterization {
    params: any = {};

    constructor() {
        this.extractParams();
    }

    public extractParams() {
        this.params = this.getQueryParams(window.location.href); // { param1: "value1", param2: "value2" }
    }

    public validateParams() {
        if (this.params.schemaId != undefined) {
            return {type: "schema", params: this.params};
        } else if (this.params.cohortId != undefined) {
            return { type: "cohort", params: this.params };
        } else if (this.params.bigQueryId != undefined) {
            return { type: "bq", params: this.params };
        } else {
            return { type: null, params: this.params };
        }
    }

    getQueryParams(url: string) {
        const queryString = url.split('?')[1];
        const queryParams: Record<string, string> = {};
        if (queryString) {
            const pairs = queryString.split('&');
            for (const pair of pairs) {
                const [key, value] = pair.split('=');
                if (key && value) {
                    if(key != "appId" && key != "disableTV" && key != "newdesigner")
                    queryParams[decodeURIComponent(key)] = decodeURIComponent(value);
                }
            }
        }
        return queryParams;
    }
}

export { Parameterization }
import { dataLoaders } from './dataLoader';
import { BaFramework } from './baFramework';
import { CommonService } from '../services/app.service';

class EmailTemplate {

    constructor() {}
    
    static renderEmailTemplate(container, obj) {
        let smallScreen = obj.smallScreen;
        if(obj.templateCode && obj.templateCode.code) {
            console.log('fn: renderEmailTemplate ', obj.templateCode);
            this.renderTemplate(container, obj, smallScreen)
        } else if(obj.templateId) {
            console.log('fn: renderEmailTemplate ', obj.templateId);
            var emailTemplate = null;
            let requestOptions: any = {
                type: 'get',
                url: `${CommonService.getTemplateAPI()}/${obj.tenantId}/${obj.tenantId}/templates/${obj.templateId}`,
            }
            dataLoaders.processRequest(requestOptions)
                .then((result: any) => {
                    console.log("Template successful");
                    emailTemplate = JSON.parse(result);
                    this.renderTemplate(container, emailTemplate, smallScreen);
                    document.getElementById('vue-app').style.visibility = 'visible';
                    document.getElementById('vue-app').style.overflowY = 'auto';
                })
                .catch((err: any) => {
                    console.log(`Error while getting the data: ${err}`);
                })
        }
    }

    static renderTemplate(container, templateCode, smallScreen) {
        console.log('fn: renderTemplate ' + container);
        let Element = document.getElementById(container);
        Element.innerHTML = '';
        if(templateCode.template) {
            Element.innerHTML = templateCode.template;
        } else {
            Element.innerHTML = templateCode.templateCode.code;
        }
        let parentContainer = Element.getElementsByClassName("emailContainer") as HTMLCollectionOf<HTMLElement>;
        
        if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents || templateCode.templateCode.renderComponents) {
            let renderData ;
            if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents) {
                renderData = templateCode.additionalProperties.renderComponents
            } else {
                renderData = templateCode.templateCode.renderComponents;
            }
            console.log(renderData);
            this.renderComponentsCharts(renderData)
        }
        if(smallScreen === 'true' && parentContainer) {
            parentContainer[0].style.width = '100%';
        } else if(smallScreen === 'false' && parentContainer) {
            parentContainer[0].style.width = '600px';
        }
    }

    static renderComponentsCharts(components) {
        console.log('fn:renderComponentsCharts ', components);
        var chartComp = new BaFramework()
        var chartObj = {
            url: '',
            reqPayload: {},
            headers: {}
        };
        var searchEnable=false;
        for (var key in components) {
          var obj = components[key];
          if(obj.type === 'chart') {
            chartObj.url = obj.url;
            chartObj.reqPayload =
            {
              "httpMethod": "GET",
              "pathParams": {
                "tenantId": obj.tenantID,
                "chartId": obj.chartId
              },
              "urlType": "REST",
              "chartType": obj.chartType
            };
            chartObj.headers = { "Content-Type": "application/json;charset=UTF-8" };
            chartComp.ChartRender(obj.bind_to, chartObj,searchEnable);
          }
        }
    }
}

export {
    EmailTemplate
}

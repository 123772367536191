// import QRCode from 'qrcode';
// import QRCode from '../../../node_modules/qrcode/build/qrcode.js';
import QRCodeStyling from '../../node_modules/qr-code-styling/lib/qr-code-styling.js';
class QrCodeScanner {
  constructor() {
    // https://www.npmjs.com/package/qr-code-styling
    // Refer for options suppoerted
  }
  static generateQR(container: any, options: any, element: any) {

    //*****QRCode options*****//

    // let qrOptions: any = {
    //   imageOptions: {
    //     hideBackgroundDots: boolean(true / false)
    //     imageSize: number(default val 0.4)
    //   },

    //   dotsOptions: { 
    //     color: string(default "#000"),
    //     type: string(('rounded' 'dots' 'square')
    //   },
    //   backgroundOptions: {
    //     color: string
    //   },
    //   typeNumber: number(0 - 40)
    //   mode:
    //   errorCorrectionLevel: string('L' 'M' 'Q' 'H')
    // }
      let containerId: any;
      if(element) {
        containerId = element
      } else {
        containerId = document.getElementById(container);
      }
     
    let isHidden: boolean = false;
    if(containerId.style.display && containerId.style.display == 'none') {
      containerId.style.display = 'block';
      isHidden = true;
    }
    try {
      containerId.removeChild(containerId.getElementsByTagName('canvas')[0]);
    } catch (e) { }
    let qrOptions: any = {
      width: containerId.getBoundingClientRect().width,
      height: containerId.getBoundingClientRect().width,
      data: options.data,
      image: options.image || ''
    };
    let QRGenerator = new QRCodeStyling(qrOptions);
    if(element) {
      QRGenerator.append(element);
      containerId.style.height = containerId.getBoundingClientRect().width + 'px';
    } else {
      QRGenerator.append(document.getElementById(container));
      containerId.style.height = containerId.getBoundingClientRect().width + 'px';
    }
    
    if(isHidden) {
      containerId.style.display = 'none';
    }
    // var qrGen = document.getElementById('qrGen');
    // QRCode.toCanvas(canvas, 'Hellooo!', function (err, url) {
    //   console.log("url="+url)
    // })
    // OR //
    // QRCode.toDataURL(qrGen, 'Hello BA!').then(url => {
    //   console.log("url=" + url)
    // })
    //   .catch(err => {
    //     console.error(err)
    //   })
  }

  static renderCompanion(container: any, options: any) {
    let containerId: any = document.getElementById(container);
    let isHidden: boolean = false;
    if(containerId.style.display && containerId.style.display == 'none') {
      containerId.style.display = 'block';
      isHidden = true;
    }
    containerId.removeChild(containerId.getElementsByTagName('canvas')[0]);
    let qrOptions: any = {
      width: containerId.getBoundingClientRect().width,
      height: containerId.getBoundingClientRect().height,
      data: options.data,
      image: options.image || ''
    };
    let QRGenerator = new QRCodeStyling(qrOptions);
    QRGenerator.append(document.getElementById(container));
    if(isHidden) {
      containerId.style.display = 'none';
    }
  }

}
export {
  QrCodeScanner
}

class BA {
  constructor() {}

  static load(container: any, baOptions: string) {
    let containerId: any = document.getElementById(container) || ''
    if (containerId == '') return
    //let imgS = containerId.getAttribute('src');
    //let imgId = containerId.getAttribute('id');
    //console.log("imag id=", imgId);
    console.log('imageParentChildrens', containerId.children)
    let listChild = containerId.children
    for (let i = 0; i < listChild.length; i++) {
      listChild[i].setAttribute('src', baOptions)
      listChild[i].removeAttribute('v-bind:src')
    }
    // containerId.setAttribute('src', baOptions);
    //containerId.appendChild(containerId);

    //Old Code
    // let iframe: any = document.createElement('iframe');
    // iframe.width = "50%";
    // iframe.height = "50%";
    // iframe.setAttribute('src', baOptions);
    //containerId.appendChild(iframe);
  }

  static slideLoad(container: any, baOptions: any) {
    let containerId: any = document.getElementById(container) || ''
    if (containerId == '') return
    console.log('imageParentChildrens', containerId.children)
    containerId.innerHTML = ''
    var slideImage = document.createElement('img')
    slideImage.style.width = '100%'
    slideImage.style.height = '100%'
    slideImage.style.objectFit = 'contain'
    containerId.appendChild(slideImage)
    var i = 0
    setInterval(function() {
      slideImage.setAttribute('src', baOptions.url[i])
      if (i == baOptions.url.length - 1) {
        i = -1
      }
      i++
      if (containerId.className.includes('animate')) {
        let classes = containerId.className.split(' ')
        classes.forEach(element => {
          if (element.includes('animate')) {
            containerId.classList.remove(element)
            slideImage.onload = function() {
              containerId.classList.add('animate__animated')
              containerId.classList.add(element)
            }
          }
        })
      }
    }, baOptions.interval)
  }
  static videoSlideLoad(
    container: any,
    baOptions: string,
    videoSlideControls: any
  ) {
    let containerId: any = document.getElementById(container) || ''
    if (containerId == '') return
    containerId.innerHTML = ''
    containerId.style.overflow = 'hidden'
    var slideVideo = document.createElement('video')
    slideVideo.style.width = '100%'
    slideVideo.style.height = '100%'
    slideVideo.setAttribute('controls', videoSlideControls.controls)
    slideVideo.setAttribute('muted', 'false')
    slideVideo.setAttribute('loop', videoSlideControls.loop)
    slideVideo.setAttribute('volume', videoSlideControls.volume)
    slideVideo.setAttribute('autoplay', videoSlideControls.autoplay)
    containerId.appendChild(slideVideo)
    var i = 0
    setInterval(function() {
      slideVideo.setAttribute('src', baOptions[i])
      if (i == baOptions.length - 1) {
        i = -1
      }
      i++
    }, 5000)
  }
}

export { BA }

import * as config from './config.json'
import {CDN_HOST, endpoints} from '../config/endpoints'
import { userInfo } from 'os';
var monthinwords = new Array();
    monthinwords[0] = "Jan";
    monthinwords[1] = "Feb";
    monthinwords[2] = "Mar";
    monthinwords[3] = "Apr";
    monthinwords[4] = "Mar";
    monthinwords[5] = "Jun";
    monthinwords[6] = "Jul";
    monthinwords[7] = "Aug";
    monthinwords[8] = "Sep";
    monthinwords[9] = "Oct";
    monthinwords[10] = "Nov";
    monthinwords[11] = "Dec";
// HTTP POST & GET CALLS
var USERINFO: any = {
  'handleId': 'gjanitt',
  'userId': sessionStorage.userId || 'jose',
  'profileImage': sessionStorage.profileUrl || `${CDN_HOST}/${sessionStorage.tenantId}/${sessionStorage.tenantId}/${sessionStorage.tenantId}/770137_man_512x512.png`,
  'userName': sessionStorage.username || 'Jose',
  'tenantId': sessionStorage.tenantId,
  'id': '123',
  'channelId': 'CHN0001',
  'assetId': '1 101',
  'newUserId': 3,
  'topic': "Density 01 Magic PingPong Ball",
  'userToken': sessionStorage.token || '',
  // 'walletId': '0D9005E5-91F5-40FB-8351-9C1C47D712E0',
  'clientKey': 'Adwize002',
  'clientRedirectUrl': 'https://developer.sslcommerz.com/docs.html',
  'paymentType': 'Credit Card(Master)',
  'gatewayName': 'AUTHORIZENET',
  'clientId': 'Adwize002',
  'profileId': '123456',
  'emailId': 'joseanitt.goli@gaiansolutions.com',
  'email': 'joseanitt.goli@gaiansolutions.com',
  'city': 'Port Angeles',
  'address': 'Chula Vista',
  'postalCode': '98362',
  'updatedTime': getUpdatedTime(),
  'latitude': 0,
  'longitude': 0
  // 'emailAddress':'suresh.b@gaiansolutions.com'
};

function checkTime(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function getUpdatedTime() {
  var hours = new Date().getHours();
  var minutes = new Date().getMinutes();
  var date = new Date().getDate();
  var meridian;
  if (hours > 12) {
    meridian = 'PM';
    hours -= 12;
  } else if (hours < 12) {
    meridian = 'AM';
    if (hours == 0) {
      hours = 12;
    }
  } else {
    meridian = 'PM';
  }
  hours = checkTime(hours)
  minutes = checkTime(minutes)
  date  = checkTime(date)
  return `${monthinwords[new Date().getMonth()]} ${date}, ${hours}:${minutes} ${meridian}`
}

var SERVICEINFO: any = {
  "assetId": "123",
  "assetType": "series"
};
var DEVICEINFO: any = {
  "deviceMake": "Acme",
  "deviceModel": "A300"
};
var NETWORKINFO: any = {
  "internetState": true
};

function getToken() {
  const cookieArray = document.cookie.split(';');
  let token = null;
  for (let i = 0; i < cookieArray.length; i++) {
      const cookie = cookieArray[i].trim();
      if (cookie.startsWith('token=')) {
          token = cookie.substring('token='.length);
          break;
      }
  }
  return token;
}

export function patchFetchWithToken() {
  const originalFetch = window.fetch;
  
  const token = getToken();
  const defaultHeaders = {
    'Authorization': `Bearer ${token}`
  };

  window.fetch = function(url, options) {
    options = options || {};
    options.headers = {
      ...defaultHeaders,
      ...options.headers
    };

    return originalFetch(url, options);
  };
}

let request = (obj) => {
  return new Promise((resolve, reject) => {
    let xhr = new XMLHttpRequest();
    xhr.open(obj.type || "GET", obj.url, true);
    let token = getToken();
    if(token == null) {
      if(window.location.href.includes("aidtaas.com")){
        token = localStorage.getItem("prodToken");
      }else if(window.location.href.includes("gaiansolutions.com") || window.location.href.includes("localhost")){
        token = localStorage.getItem("devToken");
      }
    }
    xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    for (let key in obj.headers) {
      xhr.setRequestHeader(key, obj.headers[key]);
    }
    xhr.onload = () => {
      if (xhr.status == 200 && xhr.readyState == 4 || xhr.status == 201) {
        resolve(xhr.response);
      } else {
        reject(xhr.statusText);
      }
    };
    if(obj.payload){      
      if(obj.payload.type=="file"){
        xhr.send(obj.payload.formData);
      } else if(obj.payload.append) {
        xhr.send(obj.payload);
      } else{
        xhr.send(JSON.stringify(obj.payload));
      }
    }else{
      xhr.send(JSON.stringify(obj.payload));
    } 
    
    
  });
};


class CommonService {
  
  

  public static reqAPI(obj) {
    return request(obj)
  }

  public static getMBUserCreationAPI() {
    return endpoints.SOCIAL_FEATURE_URL(USERINFO.tenantId);
  }

  public static getLimitedEmoticonsSchemaId() {
    return '62a0c7a923a93800011ac5f9';
  }
  public static setUserInfo(userInfo = {}) {
    USERINFO = userInfo || {};
  }
  public static setServiceInfo(serviceInfo = {}) {
    SERVICEINFO = serviceInfo || {};
  }
  public static setNetworkInfo(networkInfo = {}) {
    NETWORKINFO = networkInfo || {};
  }
  public static getNetworkInfo() {
    return NETWORKINFO || {};
  }

  public static OTTEnabled() {
    return NETWORKINFO.internetState;
  }
  public static getServiceInfo() {
    return SERVICEINFO || {};
  }

  public static setDeviceInfo(deviceInfo = {}){
    console.log('*****DEVICE INFO****** ' + JSON.stringify(deviceInfo));
    DEVICEINFO = deviceInfo || {};
  }
  public static getDeviceInfo() {
    return DEVICEINFO || {};
  }
  public static getUserInfo() {
    USERINFO.token = USERINFO.token || 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJzdWIiOiJnYWlhbi5jb20iLCJ1c2VyX25hbWUiOiJwb3J0YWxfdGVzdCIsInNjb3BlIjpbInRydXN0IiwicmVhZCIsIndyaXRlIl0sInRlbmFudElkIjoiNjExYmRkMzQyNmE5NDg2MDA1NjkzYjExIiwiaXNzIjoiZ2FpYW4uY29tIiwidXNlck5hbWUiOiJwb3J0YWxfdGVzdCIsImF1dGhvcml0aWVzIjpbIlJPTEVfT01OSV9DT05TVU1FUiIsIlJPTEVfTUFSS0VUUExBQ0VfVVNFUiIsIlJPTEVfT01OSV9VU0VSIl0sImp0aSI6IjgxODE1ZDNmLTY1MTAtNDJkNC05NWZkLTNiZTJmMWYzYjg5ZiIsImVtYWlsIjoicG9ydGFsX3Rlc3RAZ2F0ZXN0YXV0b21hdGlvbi5jb20iLCJjbGllbnRfaWQiOiJnYWlhbiJ9.Mz1gWLt1rujlQWW3SzuwtERk1i6HwG9utVuMUnL-RX4kKtR1jl0eR9MZmNjRZ0znbrr6w8MOj2aAULtpIEYmM9jU_mXGBuqetPIbTuV2d4Hkv6f0qaJZLAIAU3qhgijQI9O4a2yg_rmHnibNhEcZMKEFK5AXw8M_B8XIgnNYlXDkpjEqP6Siv0HJmHA3T1j1XY8PCsluzIwDzIgRr-xqAJcaCnUwGR7XxsF-X0plk8L9qV1Z3bF2EMqqBsednYeqaM3EqwJXk27R5PFU7jn5aOc-_n9DxaGLcuJB5JoqoGW7DeaIKLzMwxvS9vP_bc8vDOxl8xk-zTRAq8goyHV6IQ';
    return USERINFO || {};
  }

  public static getToken() {
    return config.BASE_PATHS.TOKEN
  }

  public static updateUserInfo(obj) {
    USERINFO = Object.assign(USERINFO, obj);
  }

  public static getCMSBasePath() {
    return config.BASE_PATHS.CMS;
  }

  public static getSocialFeatureBasePath() {
    return endpoints.SOCIAL_FEATURE_URL(USERINFO.tenantId);
  }

  public static getSTFBasePath() {
    return config.BASE_PATHS.STF;
  }

  public static getSTFSocket() {
    return config.BASE_PATHS.STF_SOCKET;
  }

  public static getAnalyticSchemaID() {
    return config.analyticSchema;
  }

  public static getAdSchemaId() {
    return config.analyticsAdSchema;
  }

  public static getAnimationCommands() {
    return config.animationsCommand;
  }

  public static getPollsAPI() {
    return config.BASE_PATHS.POLLS;
  }
  
  public static getTemplateAPI() {
    return config.BASE_PATHS.TEMPLATES;
  }
  
  public static getPaymentAPI() {
    return config.BASE_PATHS.PAYMENTS;
  }

  public static getNullSchoolURL() {
    return config.NullSchoolURL;
  }
  public static setTenantId(tenantId:string) {
    USERINFO.tenantId = tenantId;
  }
}

export {
  CommonService
}

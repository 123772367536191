import { dataLoaders } from './dataLoader';
// import { BaFramework } from './baFramework';
import { CommonService } from '../services/app.service';

class TextTemplate {

    constructor() {}
    
    static renderTextTemplate(container, obj) {
        if(obj.templateCode && obj.templateCode.code) {
            console.log('fn: renderTextTemplate ', obj.templateCode);
            this.renderTemplate(container, obj);
        } else if(obj.templateId) {
            console.log('fn: renderTextTemplate ', obj.templateId);
            let textTemplate: any = null;
            let requestOptions: any = {
                type: 'get',
                url: `${CommonService.getTemplateAPI()}/${obj.tenantId}/${obj.tenantId}/templates/${obj.templateId}`,
            }
            dataLoaders.processRequest(requestOptions)
                .then((result: any) => {
                    console.log("Template fetching successful");
                    textTemplate = JSON.parse(result);
                    this.renderTemplate(container, textTemplate);
                })
                .catch((err: any) => {
                    console.log(`Error while fetching the template: ${err}`);
                })
        }
    }

    static renderTemplate(container, templateCode) {
        console.log('fn: renderTextTemplate ' + container);
        let Element = document.getElementById(container);
        Element.innerHTML = '';
        if(templateCode.template) {
            Element.innerHTML = templateCode.template;
        } else {
            Element.innerHTML = templateCode.templateCode.code;
        }
        let parentContainer: HTMLElement = Element;
        if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents || templateCode.templateCode.renderComponents) {
            let renderData ;
            if(templateCode.additionalProperties && templateCode.additionalProperties.renderComponents) {
                renderData = templateCode.additionalProperties.renderComponents
            } else {
                renderData = templateCode.templateCode.renderComponents;
            }
            console.log(renderData);
            // this.renderComponentsCharts(renderData)
        }
        // if(smallScreen === 'true' && parentContainer) {
        //     parentContainer[0].style.width = '100%';
        // } else if(smallScreen === 'false' && parentContainer) {
        //     parentContainer[0].style.width = '600px';
        // }
    }

    // static renderComponentsCharts(components) {
    //     console.log('fn:renderComponentsCharts ', components);
    //     var chartComp = new BaFramework()
    //     var chartObj = {
    //         url: '',
    //         reqPayload: {},
    //         headers: {}
    //     };
    //     var searchEnable=false;
    //     for (var key in components) {
    //       var obj = components[key];
    //       if(obj.type === 'chart') {
    //         chartObj.url = obj.url;
    //         chartObj.reqPayload =
    //         {
    //           "httpMethod": "GET",
    //           "pathParams": {
    //             "tenantId": obj.tenantID,
    //             "chartId": obj.chartId
    //           },
    //           "urlType": "REST",
    //           "chartType": obj.chartType
    //         };
    //         chartObj.headers = { "Content-Type": "application/json;charset=UTF-8" };
    //         chartComp.ChartRender(obj.bind_to, chartObj,searchEnable);
    //       }
    //     }
    // }
}

export {
    TextTemplate
}

import { dataLoaders } from './dataLoader';
import { DataCache } from './dataCache';
// import { RenderCharts } from './charts/renderCharts';
import { QrCodeScanner } from './qrCode';
import { BA } from './ba';
import { MultipleBaFramework } from './multipleBAs';
// import { video } from './video';

// import { MessageBoard } from './messageBoard';
// import { Polls } from './polls';
// import { EmotiIcons } from './emoticons';
// import { loadGamifications } from './loadGamification';
import { CommonService, patchFetchWithToken } from '../services/app.service';
// import { EpubJs } from './epub';
// import { GeoheatMap } from './charts/geoheat';
// import { keplerCharts } from './charts/keplerCharts';
// import { RaceChartMap } from './charts/raceCharts';
// import { DateTimer } from './datetimer';
import { Htmlwidget } from './htmlwidget';
import { EmailTemplate } from './emailTemplate';
import { TextTemplate } from './textTemplate';
import { payments } from './payments';
import { HTMLTemplate } from './htmltemplate';
// import { Weather } from './weather';
import { Events } from './Events';
import { Cart } from './cart';
import { FirstResponder } from './firstResponder';
// import { CameraView } from './cameraView';
// import { Alerting } from './alerting';
// import { D3Wraper } from './charts/D3ChartsWrapper.js';
// import { ECharts } from './charts/echart';
// import { Clock } from './charts/eClock';
import { clientSocket } from './clientSocket';
import { analytics } from './analytics';
import io from '../../node_modules/socket.io-client/dist/socket.io';
import {API_HOST, CDN_HOST, endpoints} from "../config/endpoints";

import L from "leaflet";
// import io from '/home/gaian/BA-Framework/node_modules/socket.io-client/dist/socket.io.js';
// import GridStack from 'gridstack';

// import 3rd party libraries
// import * as $ from "jquery";
// external css
import '../css/spatial_navigation.css';
import '../css/emojione.css';
import '../css/font-awesome.css';
import '../css/style.css';
import '../css/fonts.css';
// import '../../node_modules/c3/c3.min.css';
// import '../css/weather-bulletin.css';
// import { threadId } from 'worker_threads';
// import { template } from '@babel/core';
import { request } from 'http';
// import { Templates } from './template';
// import Container from 'epubjs/types/container';
//import '../../node_modules/leaflet/dist/leaflet.css';
import $ from "jquery";
import lodash from 'lodash'
import { BehaviorSubject } from 'rxjs';
import { Parameterization } from './parameterization';


// import { PI } from "./Changer_using_pi/index"
const { find, filter, get, groupBy, values, map, flatten } = lodash

var multiBASObject: MultipleBaFramework;

enum DefaultEvents {
  CLICK = 'onClick',
  BLUR = 'onBlur',
  FOCUS = 'onFocus',
  INIT = 'onInit',
  MOUSE_ENTER = 'onMouseEnter',
  MOUSE_LEAVE = 'onMouseLeave',
  CHANGE = 'onChange',
  INPUT = 'onInput',
  LONGPRESS = 'longPress',
  ENTER = 'onEnter'
}

interface Variables {
  [key: string]: any;
}

const cache = new DataCache(60000);

class BaFramework {
  broadBandURL: string = window.location.href;
  forcedDisplayElements: any = [];
  baDataConfig: any = {};
  dashboardSocket: any = '';
  parentAlive: boolean = false;
  mappingIDs: any = '';
  dataPage: 0;
  clientSocketInit: any;
  domRendered: any = true;
  completeGroupsData: any = {};
  filteredGroupsData: any = {};
  dataPageSize: 100;
  iterationDataLabels: any = {};
  navigationDetails: any;
  validateConditions: boolean = false;
  OTA_OPTIONS: any;
  Scale_OPTIONS: any;
  Scale_CONTAINER: any;
  currentIndex = 0;
  GROUP_SOCKET = 'http://a556f7c3f28324a9a9ab49bb281cf0a5-7215d20e48bf3090.elb.us-east-1.amazonaws.com:80/';
  newBaseURL = API_HOST;
  randomInstance: any;
  randomMapInstance: any;
  totalChartsInBA: any = 0;
  USERInfo = {
    handleId: 'gjanitt',
    // 'handleId': '941cd0bd-b4e1-489e-9b23-981005177606',
    userId: this.generateRandomId(), //'sai123',
    profileImage: '',
    // 'profileImage': './assets/images/userprofile.png',
    userName: 'Tony',
    // 'userName': 'Tony Stark',
    'tenantId': CommonService.getUserInfo().tenantId ,//dynamic tenant
    'id': this.generateRandomId(),
    'channelId': 'CHN0001',
    'assetId': '1 101',
    'newUserId': 3,
    'topic': "Density 01 Magic PingPong Ball",
    'userToken': ''
  };
  APP_INFO = {
    appId: '',
    appName: '',
    version: '',
  }
  emojiType = '';
  dataCount = 0;
  responseCount = 0;
  dataIterationCount = 0;
  dataIterationResultCount = 0;
  jsonObj = {};
  viewComponent: any = '';
  EventsCapture: any;
  navigator: any;
  searchEnable: boolean = false;
  clientSock: any = '';
  analytics: any = '';
  params: any;
  accessToken: any = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImZmOGYxNjhmLTNmZjYtNDZlMi1iMTJlLWE2YTdlN2Y2YTY5MCJ9.eyJzdWIiOiJnYWlhbi5jb20iLCJ1c2VyX25hbWUiOiJwb3J0YWxfdGVzdCIsInNjb3BlIjpbInRydXN0IiwicmVhZCIsIndyaXRlIl0sInRlbmFudElkIjoiNjExYmRkMzQyNmE5NDg2MDA1NjkzYjExIiwiaXNzIjoiZ2FpYW4uY29tIiwidXNlck5hbWUiOiJwb3J0YWxfdGVzdCIsImF1dGhvcml0aWVzIjpbIlJPTEVfT01OSV9DT05TVU1FUiIsIlJPTEVfTUFSS0VUUExBQ0VfVVNFUiIsIlJPTEVfT01OSV9VU0VSIl0sImp0aSI6IjgxODE1ZDNmLTY1MTAtNDJkNC05NWZkLTNiZTJmMWYzYjg5ZiIsImVtYWlsIjoicG9ydGFsX3Rlc3RAZ2F0ZXN0YXV0b21hdGlvbi5jb20iLCJjbGllbnRfaWQiOiJnYWlhbiJ9.Mz1gWLt1rujlQWW3SzuwtERk1i6HwG9utVuMUnL-RX4kKtR1jl0eR9MZmNjRZ0znbrr6w8MOj2aAULtpIEYmM9jU_mXGBuqetPIbTuV2d4Hkv6f0qaJZLAIAU3qhgijQI9O4a2yg_rmHnibNhEcZMKEFK5AXw8M_B8XIgnNYlXDkpjEqP6Siv0HJmHA3T1j1XY8PCsluzIwDzIgRr-xqAJcaCnUwGR7XxsF-X0plk8L9qV1Z3bF2EMqqBsednYeqaM3EqwJXk27R5PFU7jn5aOc-_n9DxaGLcuJB5JoqoGW7DeaIKLzMwxvS9vP_bc8vDOxl8xk-zTRAq8goyHV6IQ';
  animationsCommand = {};
  BAAppData: any;
  OTTModeElements: any = [];
  vm: any = null;
  tempraryViewComponent: any = {};
  // initCSSAnimationsList: any = [];
  looperSake:any = []; //for maintaining individual looper events
  groupUpdateSocket: any;
  socketGroupIds: any = [];
  autoscrollTimer: any;
  disableAutoScroll: any;
  autoScrollElementDetails: any = [];
  autoScrollInfo: any = [];
  groupIds: any = [];
  duplicateGroupName: any = [];
  validateClick: any;
  cache: { [key: string]: any } = {};
  GLOBAL_VARIABLES: Variables = {};
  IS_OFFLINE: boolean = false;
  PI_data: any;
  APP_DATA: any = {}
  actionHandlers = {
    SHOW_HTML: this.SHOW_HTML,
    SHOW_ALERT: this.SHOW_ALERT,
    SHOW_TOAST: this.SHOW_TOAST,
    SHOW_ELEMENT: this.SHOW_ELEMENT,
    HIDE_ELEMENT: this.HIDE_ELEMENT,
    TOGGLE_ELEMENT: this.TOGGLE_ELEMENT,
    RUN_QUERY: this.RUN_QUERY,
    FLIP_ANIMATION: this.FLIP_ANIMATION,
    CUSTOM_ACTION: this.CUSTOM_ACTION,
    HIGHLIGHT: this.HIGHLIGHT,
    SET_VARIABLE: this.SET_VARIABLE,
    UPDATE_VARIABLE: this.UPDATE_VARIABLE,
    FILTER_VARIABLE: this.FILTER_VARIABLE,
    GET_API: this.GET_API,
    POST_API: this.POST_API,
    DELETE_API: this.DELETE_API,
    SEND_FILE: this.SEND_FILE,
    MULTI_FILTER:this.MULTI_FILTER,
    CONDITIONAL_RENDERING: this.CONDITIONAL_RENDERING,
    REFRESH_VARIABLE: this.REFRESH_VARIABLE,
  }

  appModalEvents = [];
  paramsDetails;
  constructor() {
    this.analytics = new analytics();
    document.body.style.backgroundColor = document.body.style.backgroundColor || 'transparent';
    this.params = this.getQueryParams(window.location.href)
    this.params.wsURL = this.params.wsURL || '';
    if (this.params.wsURL != '') {
      this.clientSock = new clientSocket(this.params.wsURL);
    }
    // @ts-ignore
    this.navigator = SpatialNavigation;
    CommonService.setTenantId(this.params.tenantId);
  }

  clientSocket = (socketURL) => {
    this.clientSock = new clientSocket(socketURL);
  }

  sendLogs = (log) => {
    this.analytics.sendAnalytics('BALog', {
      "assetName": log,
      tenantId: this.params.tenantId
    })
  }

  createMBUser = () => {
    // Hardcoding user
    // let userId = localStorage.getItem('userId') || '';
    let userId = ''
    if (userId != '') {
      // localStorage.setItem('userId', newUserObj.displayName);
      CommonService.updateUserInfo({ 'userId': userId, 'userName': userId, tenantId: this.params.tenantId });
      return;
    }
    let url: string = CommonService.getMBUserCreationAPI();
    let anonymousId: string = this.generateRandomId();
    let newUserObj: any = {
      "creationDate": 0,
      "description": "an anonymous user",
      "displayName": `melschwartzmel`,
      "favouriteCount": 0,
      "firstName": `melschwartzmel`,
      "followeeCount": 0,
      "followerCount": 0,
      "handle": `gjanitt`,
      "id": 0,
      "lastName": "",
      "listedCount": 0,
      "location": "",
      "modificationDate": 0,
      "userIcon": CommonService.getUserInfo().profileImage,
      "profileImageUrl": "string",
      "tenantId": this.params.tenantId,
      "tenantUserId": `melschwartzmel`,
      "tweetCount": 0
    }
    let requestOptions = {
      type: 'POST',
      url: url,
      payload: newUserObj,
      headers: { "Content-Type": "application/json" }
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        localStorage.setItem('userId', newUserObj.displayName);
        CommonService.updateUserInfo({ 'userId': newUserObj.displayName, 'userName': newUserObj.displayName, 'tenantId': this.params.tenantId });
        return;
      })
    return true;
  }

  public listenKeyEvents = () => {
    ['keydown', 'keyup'].forEach(eventName => {
      window.addEventListener(eventName, (event: KeyboardEvent) => {
        let focusableDetails = {
          "TargetElement": (<HTMLInputElement>event.target).id,
          "eventType": event.key || JSON.stringify(event),
          "assetName": `event key: ${event.key}, event keyCode: ${event.keyCode}, event type: ${event.type}, event: ${event}`
        }
        console.log(focusableDetails);
        if (navigator.onLine) {
          this.analytics.sendAnalytics('Focusable', focusableDetails);
          this.sendLogs(`${event}`);
        } else {
          var logMessage = event.key
          // var elem = `<h1 style="position:relative; margin: 0; font-size: 14px;">${logMessage}</h1>`;
          // document.body.innerHTML += elem;
        }
      })
    });
  }

  getQueryParams = url => {
    let queryParams: any = {}
    var anchor = document.createElement('a')
    anchor.href = url
    var queryStrings = anchor.search.substring(1)
    var params = queryStrings.split('&')

    for (var i = 0; i < params.length; i++) {
      var pair = params[i].split('=')
      queryParams[pair[0]] = decodeURIComponent(pair[1])
    }
    this.emojiType = queryParams.emojiType || 'grinning'
    return queryParams
  }

  public connectToRA(wsURL) {
    if (this.clientSock != '')
      this.clientSock = new clientSocket(wsURL);
  }

  public generateRandomId() {
    return Math.random()
      .toString(13)
      .replace('0.', '')
  }

  public getUserInfo() {
    let userInfo = '';
    // userInfo = window.localStorage.getItem('userInfo') || '';
    userInfo = CommonService.getUserInfo();
    return userInfo != '' ? userInfo : this.USERInfo
  }

  // USER_INFO = this.getUserInfo() || this.USERInfo
  USER_INFO = CommonService.getUserInfo();

  public initFrameWorkData() {
    var params = this.getQueryParams(window.location.href)
    // 
    if (params) {
      if (params.hasOwnProperty('userId')) this.USER_INFO.userId = params.userId
      if (params.hasOwnProperty('userName'))
        this.USER_INFO.userName = params.userName
      if (params.hasOwnProperty('tenantId'))
        this.USER_INFO.tenantId = params.tenantId
      if (params.hasOwnProperty('id')) this.USER_INFO.id = params.id
      if (params.hasOwnProperty('assetId'))
        this.USER_INFO.assetId = params.assetId
      if (params.hasOwnProperty('newUserId'))
        this.USER_INFO.newUserId = params.newUserId
      if (params.hasOwnProperty('topic')) this.USER_INFO.topic = params.topic
      if (params.hasOwnProperty('handleId'))
        this.USER_INFO.handleId = params.handleId
      if (params.hasOwnProperty('profileImage'))
        this.USER_INFO.profileImage = params.profileImage
      if (params.hasOwnProperty('channelId'))
        this.USER_INFO.channelId = params.channelId
      if (params.hasOwnProperty('baseUrl'))
        this.USER_INFO.baseUrl = params.baseUrl
      if (params.hasOwnProperty('htmlWidgetID'))
        this.USER_INFO.htmlWidgetID = params.htmlWidgetID
    }
  }

  public getMappingIdsList = () => {
    // `${this.newBaseURL}/tf-web/v2.0/${tenantId}/graphs/mappings`
    let tenantId = this.USER_INFO.tenantId;
    let requestOptions: any = {
      type: 'GET',
      url: `${this.newBaseURL}/tf-web/v2.0/${tenantId}/graphs/mappings`
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        this.mappingIDs = JSON.parse(result);
        // alert('response: ' + this.mappingIDs);
        // alert('response: ' + JSON.stringify(this.mappingIDs));
      })
      .catch((err: any) => {
        console.log(err)
      })
    return true;
  }

  public renderStaticChart = (divId, data, searchString) => {
    // RenderCharts.loadChart(divId, data, searchString);
  }

  public loadMappingIds = (divId: any, obj: any, searchEnable) => {
    // 
    // alert('mappingIDs: ' + this.mappingIDs);
    if (this.mappingIDs != '') {
      this.renderCharts(divId, obj, searchEnable);
      return;
    }
    // @ts-ignore
    let tenantId = this.USER_INFO.tenantId
    let requestOptions: any = {
      type: 'GET',
      url: endpoints.DEV_GRAPH_MAPPING_URL(tenantId)
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        this.mappingIDs = JSON.parse(result);
        // alert('response: ' + this.mappingIDs);
        // alert('response: ' + JSON.stringify(this.mappingIDs));
        this.renderCharts(divId, obj, searchEnable);
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public getMappingID = (type: string) => {

    let mappingId: string = ''
    this.mappingIDs.forEach(item => {
      // this.mappingIDs[item.chartType] = item.configId
      if (item.chartType === type) {
        mappingId = item.configId;
      }
    })
    //
    return mappingId
  }

  public receiveMessage(event) {
    switch (event.data.type) {
      case 'connectionRequest':
        this.parentAlive = true
        window.parent.postMessage({ type: 'message', request: 'userInfo' }, '*')
        break
      case 'message':
        if (event.data.userInfo) {
          CommonService.setUserInfo(event.data.userInfo)
        }
        break
      default:
        break
    }
  }

  public getChartDefinition(obj: any, total: number) {
    // obj.bind_to saikumar
    let elementsList = document.querySelectorAll(`[id="${obj.bind_to}"]`);
    elementsList.forEach((element) => {
      let filterValue = element.getAttribute('filterwith') || '';
      // pass filter if filterValue != '';
      this.DataLoaders({
        type: 'GET',
        url: `${this.newBaseURL}/tf-web/v2.0/${obj.tenantID}/graphs/${obj.chartId}/definition`,
      }).then((result: any) => {
        var result = JSON.parse(result);
        // result.metadata['totalRecords'] = 600;
        result.metadata['pageSize'] = 300;
        result.metadata['page'] = 0;
        if (obj.filters.length > 0) {
          for (let i = 0; i < obj.filters.length; i++) {
            if (obj.filters[i].type == 'attributeValue') {
              obj.filters[i].value = element.getAttribute(obj.filters[i].key) || '';
            } else if (obj.filters[i].type == 'configVariable') {
              obj.filters[i].value = this.USER_INFO[obj.filters[i].key]
            }
            else if (obj.filters[i].type == 'dynamicVariable') {
              obj.filters[i].value = this.jsonObj[obj.filters[i].key];
            } else {
              if (obj.filters[i].key == 'attribute' && obj.filters[i].value == '') {
                obj.filters = []
              }
            }
          }
        }
        this.renderEchart(obj.bind_to, result.metadata, obj, element, total);
      })
        .catch((err: any) => {
          console.log(err)
        })
    });
  }

  public ChartRender(divId: any, obj: any, searchEnable) {
    this.loadMappingIds(divId, obj, searchEnable);
  }

  public renderCharts = (divId: any, obj: any, searchEnable) => {
    // 
    // this.dashboardReferences[container][node.bind_to]
    let requestOptions: any = {};
    let chartId: string = '';
    let mappingID = '';
    if (obj.url) {
      obj.reqPayload.pathParams.tenantId = obj.reqPayload.pathParams.tenantId || this.USER_INFO.tenantId;
      mappingID = this.getMappingID(obj.reqPayload.chartType);
      chartId = obj.reqPayload.pathParams.chartId;
      obj.headers = obj.headers || {};
      obj.headers = Object.assign(obj.headers, { "Authorization": `Bearer ${this.accessToken}` })
      // alert(JSON.stringify(obj.headers));
      obj.reqPayload.url = `${this.newBaseURL}/tf-web/v2.0/${obj.reqPayload.pathParams.tenantId}/graphs/${obj.reqPayload.pathParams.chartId}/data`
      requestOptions = {
        type: 'POST',
        url: `${this.newBaseURL}/mappingservice-api-processor/v1.0/${obj.reqPayload.pathParams.tenantId}/mappings/${mappingID}/process`,
        payload: obj.reqPayload,
        headers: Object.assign(obj.headers, { "Authorization": `Bearer ${this.accessToken}` })
      }
    } else if (obj.data) {
      // RenderCharts.loadChart(divId, obj, searchEnable, '');
      return;
    } else {
      obj.reqPayload.pathParams.tenantId = obj.reqPayload.pathParams.tenantId || this.USER_INFO.tenantId;
      mappingID = this.getMappingID(obj.reqPayload.chartType);
      chartId = obj.reqPayload.pathParams.chartId;
      obj.headers = obj.headers || {};
      obj.headers = Object.assign(obj.headers, { "Authorization": `Bearer ${this.accessToken}` })
      // alert(JSON.stringify(obj.headers));
      obj.reqPayload.url = `${this.newBaseURL}/tf-web/v2.0/${obj.reqPayload.pathParams.tenantId}/graphs/${obj.reqPayload.pathParams.chartId}/data`
      let url: string = `${this.newBaseURL}/mappingservice-api-processor/v1.0/${obj.reqPayload.pathParams.tenantId}/mappings/${mappingID}/process`;
      requestOptions = {
        type: 'POST',
        url: url,
        payload: obj.reqPayload,
        headers: Object.assign(obj.headers, { "Authorization": `Bearer ${this.accessToken}` })
      }
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        // 
        let chartData: any = JSON.parse(result);

        // RenderCharts.loadChart(divId, chartData, searchEnable, chartId);
        return;
      })
    // .catch((err: any) => {
    //   // 
    //   if (obj.data)
    //     RenderCharts.loadChart(divId, obj.data, searchEnable, chartId);
    //   // 
    //   // 
    // })
  }

  public DataLoaders(obj: any) {
    if (obj.interval) {
      setInterval(() => {
        dataLoaders.processRequest(obj).then(result => {
          obj.cb(result)
        })
      }, obj.interval)
    } else {
      if (obj.cb) {
        dataLoaders
          .processRequest(obj)
          .then(result => {
            obj.cb(result)
          })
          .catch((err: any) => {
            console.log(err)
            obj.cb(err)
          })
      } else {
        // 
        return dataLoaders.processRequest(obj)
      }
    }
  }

  // echart initiation
  public renderEchart(container: string = '', options: any = '', chartObj: any = '', containerInstance: any = '', total: number) {
    // let eChart: any = new RenderCharts();
    // return ECharts.renderEChart(container, options, chartObj, containerInstance, total);
  }

  public qrCode(container: string, options: any, element: any) {
    QrCodeScanner.generateQR(container, options, element)
  }

  public companion(container: string, options: any) {
    options.data = this.getBroadBandURL();
    QrCodeScanner.renderCompanion(container, options)
  }

  public renderBA(container: string, baOptions: string) {
    BA.load(container, baOptions)
  }

  public renderMultipleBAs(config: any, framework:any,data:any = {}) {
    multiBASObject = new MultipleBaFramework(config, framework, data)
  }

  public getMultiBAsObject(){
    return multiBASObject;
  }

  public renderCart(container: string, data: any, changedStyles: any) {
    return new Cart(container, data, changedStyles);
  }

  public renderPayment(container: string, totalAmount: number = 0, currencyType: any = 'INR') {
    // return new payments(container, totalAmount, currencyType)
    payments.renderPaymentFromCC(container, totalAmount, currencyType)
  }

  public renderMap(container, theme = 'dark_all', obj = null) {
    let ele = document.createElement('div');
    ele.style.width = '100%';
    ele.style.height = '100%';
    ele.id = `${container}_${this.generateRandomId()}`;
    // if(document.getElementById(container)){
    document.getElementById(container).appendChild(ele);
    this.randomInstance = this.generateRandomId();
    // this.randomInstance = new GeoheatMap();
    // let mapInstance = new GeoheatMap();

    // render empty map in a container initially
    this.randomInstance.renderGeoheatMap('', ele.id, false, theme, obj);

    return this.randomInstance;
  }

  public renderIncidentsMap(container: string, obj: any) {
    let ele = document.createElement('div');
    ele.style.width = '100%';
    ele.style.height = '100%';
    ele.id = `${container}_${this.generateRandomId()}`;
    document.getElementById(container).appendChild(ele);
    // let mapData = {
    //   "geoheatmapChart_05789c6c90aac555": {
    //     bind_to: "geoheatmapChart_05789c6c90aac495",
    //     mappingId: "5f338302eca5527055878e55",
    //     chartId: "60e70173e4662c00061c04f2",
    //     tenantID: "59ce808c99298e1e06660c09",
    //     chartType: "geoheatmap",
    //     type: "chart"
    //   }
    // };
    // let geoHeatInstance = new GeoheatMap();
    // render empty map in a container initially
    // geoHeatInstance.renderGeoheatMap('', ele.id, false);
    // get list of markers and add to existing map
    // geoHeatInstance.addMarkersToMap(true);
    // return geoHeatInstance;
  }

  MAP_REF;
  MAP_CONFIG;
  public renderMapComponent(container: string, obj: any) {

    console.log("renderMapComponent : ", container, obj);

    //     obj.config.maplayers = [
    //       {
    //         "type": "MARKERS",
    //         "config": {
    //          "sourceURL": endpoints.ANALYTIC_QUERIES_URL(CommonService.getUserInfo().tenantId, '62f3e0b283595500019350db'),
    //           "lat": "lat",
    //           "lng": "long",
    //           "style": {
    //             "style": "RED",
    //             "xSize": 35,
    //             "ySize": 40
    //           }
    //         }
    //       },
    //       {
    //         "type": "MARKERS",
    //         "config": {
    //            "sourceURL": endpoints.GROUPS_URL(CommonService.getUserInfo().tenantId, '6305f262d9fd420001e1889a'),
    //           "lat": "lat",
    //           "lng": "long",
    //           "style": {
    //             "style": "BLUE",
    //             "xSize": 35,
    //             "ySize": 40
    //           }
    //         }
    //       },
    //       {
    //         "type": "HEATMAP",
    //         "config": {
    //            "sourceURL": endpoints.ANALYTIC_QUERIES_URL(CommonService.getUserInfo().tenantId, '62f3e0b283595500019350db'),
    //           "lat": "lat",
    //           "lng": "long"
    //         },
    //         "data": {
    //           max: 8,
    //           data: [{lat: 24.6408, lng:46.7728, count: 3},{lat: 50.75, lng:-1.55, count: 1}]
    //         }
    //       },
    //       {
    //         "type": "RASTER",
    //         "data": []
    //       }
    //   ]

    if (obj.config.maplayers) {
      let mapInstance = this.renderMap(container, obj.config.mapTheme, obj);
      this.randomMapInstance = this.generateRandomId();
      this.randomMapInstance = mapInstance;
      obj.config.maplayers.forEach(element => {
        if (element.type == "MARKERS") {
          this.randomMapInstance.newAddMarkers(element.data);
        } else if (element.type == "HEATMAP") {
          this.randomMapInstance.newAddHeatmap(element.data);
        } else if (element.type == "RASTER") {
          console.log("Raster Data!!")
        }
      });
      // this.randomMapInstance.newSetView();
    } else {
      obj.config.mapTheme = obj.config.mapTheme || 'dark_all';
      this.MAP_REF = this.renderMap(container, obj.config.mapTheme, obj);
      this.randomMapInstance = this.generateRandomId();
      this.randomMapInstance = this.MAP_REF;
      // let mapInstance = this.MAP_REF;
      obj.config.type = obj.config.type || '';

      if (obj.type && obj.config.type === 'Markers' || obj.type && obj.type == 'PolygonMap') {
        // mapInstance.addHeatmapLayer(container, obj);

        if (obj.config.userLocation) {
          this.randomMapInstance.showUserLocation();
        }
        if (obj.url) {
          obj.url = obj.url.includes('http://') ? obj.url.replace('http://', 'https://') : obj.url;
          // mapInstance.getMarkers(obj, obj.config.clusterMarkers);
          this.randomMapInstance.getMarkers(obj, obj.config.clusterMarkers);
        }

        // 15, {style: "RED", displayType: "MapContent",  xSize: 35, ySize: 45}
        // obj.config.highlightMarkersEffect = {
        //   enabled: true,
        //   type: 'zoom',
        //   style: {
        //     zoomLevel: 15,
        //     markerStyle: 'RED',
        //     displayContentType: 'MapContent', /*Can be Tooltip | MapContent*/
        //     xIconSize: 35,
        //     yIconSize: 45
        //   }
        // }

        // obj.config.markersEffectConfig = {
        //   enabled: true,
        //   showTooltip: true,
        //   duration: 7,
        //   interval: 5000,
        //   speed: 13.5
        // }

        // obj.config.zoomEffectConfig = {
        //   enabled: true,
        //   min: 1,
        //   max: 1,
        //   desired: 1,
        //   interval: 1,
        //   speed: 1
        // }
        // this.randomMapInstance.getMarkers(obj, obj.config.clusterMarkers);
        // mapInstance.loopMarkersZoomEffect();

      } else if (obj.type && obj.config.type === 'HeatMap') {
        this.randomMapInstance.addHeatmapLayer(container, obj);
        // mapInstance.getMarkers(obj, obj.config.clusterMarkers);
      }
      this.MAP_CONFIG = obj.config;
      this.MAP_CONFIG.imageUrl = obj.config.imageUrl;
      this.MAP_CONFIG.bounds = obj.config.bounds;
      if (this.randomMapInstance && this.MAP_CONFIG.imageUrl && this.MAP_CONFIG.bounds) {
        this.randomMapInstance.addImageOverlay(this.MAP_CONFIG.imageUrl, this.MAP_CONFIG.bounds);
      }
    }
    return true;
  }

  public renderFirstResponder(container: string, obj: any) {
    obj.type = 'messageboard';
    let responderData = {
      "geoheatmapChart_05789c6c90aac495": {
        baseUrl: "",
        chartUrl: "",
        url: endpoints.MAPPING_API_PROCESSOR_URL('59ce808c99298e1e06660c09', '5f338302eca5527055878e55'),
        bind_to: "geoheatmapChart_05789c6c90aac495",
        mappingId: "5f338302eca5527055878e55",
        chartId: "60e70173e4662c00061c04f2",
        tenantID: "59ce808c99298e1e06660c09",
        chartType: "geoheatmap",
        type: "chart"
      },
      "MessageBoard_1628046354315": obj,
    };
    let cameraObj =
      [
        {
          type: "camera1",
          bind_to: "camera_123456",
          videoSrc: `${endpoints.CONTENT_URL}/b3f6067a-66d1-482c-b490-40229c35ea37`,
        },
        {
          type: "camera2",
          bind_to: "camera_123456789",
          videoSrc: `${endpoints.CONTENT_URL}/f2d0bbe4-4fb9-4c94-af02-6a741d0bcc1d`,
        },
        {
          type: "camera3",
          bind_to: "camera_123456789321",
          videoSrc: `${endpoints.CONTENT_URL}/1abc5b20-2352-4f64-9b2b-974b78ff9d1f`,
        },
        {
          type: "camera4",
          bind_to: "camera_123456456",
          videoSrc: `${endpoints.CONTENT_URL}/565b2010-d49b-4c08-9a85-32bd57d1eb35`,
        },
        {
          type: "camera5",
          bind_to: "camera_12346789",
          videoSrc: `${endpoints.CONTENT_URL}/17f34bc1-cdd5-45c6-9c08-28de4f9eaaf1`,
        }
      ]
    new FirstResponder(container, cameraObj);
    this.renderComponents(responderData);
  }

  public renderVideo(container: string, baOptions: string, videoControls) {
    // video.loadVideo(container, baOptions, videoControls)
  }

  public renderAudio(container: string, baOptions: string, audioControls) {
    // video.loadAudio(container, baOptions, audioControls)
  }

  public renderAlerting(container: string, data, changedStyles: any) {
    // let data = [
    //   {
    //     "alertType": "protest",
    //     "alertTitle": "fire alert declared",
    //     "alertDescription": "fire alert declared",
    //     "incidentTime": "2021/07/09 12:00",
    //     "latitude": 41.720882,
    //     "longitude": -86.861486
    //   },
    //   {
    //     "alertType": "fire",
    //     "alertTitle": "fire alert declared",
    //     "alertDescription": "fire alert declared",
    //     "incidentTime": "2021/07/07 14:00",
    //     "latitude": 41.710918,
    //     "longitude": -86.894521
    //   },
    //   {
    //     "alertType": "fire",
    //     "alertTitle": "fire alert declared",
    //     "alertDescription": "fire alert declared",
    //     "incidentTime": "2021/07/08 10:00",
    //     "latitude": 41.717270,
    //     "longitude": -86.907173
    //   },
    //   {
    //     "alertType": "fire",
    //     "alertTitle": "fire alert declared",
    //     "alertDescription": "fire alert declared",
    //     "incidentTime": "2021/07/05 09:00",
    //     "latitude": 41.729690,
    //     "longitude": -86.885414
    //   }
    // ];
    // return new Alerting(container, data, changedStyles);
    // let chartObj = {
    // url: endpoints.GRAPHS_DATA_URL('59ce808c99298e1e06660c09', '5dcd621aeebdc1114bdb7f2a'),

    //   type: 'GET'
    // }
    // this.renderClusterChart(chartObj, data, container, changedStyles);
    // new Alerting(container, data)
  }

  renderClusterChart(reqObj, data, container, changedStyles) {
    dataLoaders.processRequest(reqObj)
      .then((result: any) => {
        var chartObj = JSON.parse(result) || '';
        this.ChartRender("container", chartObj, this.searchEnable);
        setTimeout(() => {

          // return new Alerting(container, data, changedStyles);
        }, 500);
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public showOTAWidget(container: string, options: any) {
    options.container = container;
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.startOTARMPPlayback(options);
    return true;
  };

  public sendOfflineQueLogs() {
    return this.clientSock.sendIndexDB_logs();
  }

  public showOTTWidget(container: string, options: any) {
    options.container = container;
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.startOTTRMPPlayback(options);
    return true;
  }

  public stopOTAWidget() {
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.stopRMPPlayback();
    return true;
  }

  public pauseOTAWidget() {
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.pauseRMPPlayback();
    return true;
  }

  public resumeOTAWidget() {
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.resumeRMPPlayback();
    return true;
  }

  public resizeOTAWidget(container: string, options: any) {
    options.container = container;
    // this.clientSocketInit = new clientSocket(this.params.wsURL)
    this.clientSock.resizeRMP(options);
    return true;
  }

  public scalingOTAWidget(container: string, options: any) {
    this.Scale_CONTAINER = container;
    this.Scale_OPTIONS = options;
    options.container = container;
    this.clientSock.scalingPos(options);
    return true;
  }

  public renderEChartClock(container: string, options: any) {
    // return Clock.renderEChartClock(container, options);
  }

  public renderNullSchoolWidget(container: string, options: any) {
    // render null school ui
    let containerElement: any = document.getElementById(container);
    let ifrm: any = containerElement.getElementsByTagName('iframe');
    if (ifrm.length > 0) {
      containerElement.removeChild(ifrm[0]);
    }
    ifrm = document.createElement('iframe');
    ifrm.setAttribute('style', 'width: 100%; height:100%;');
    options.view = options.view || 'wind';
    let latitude: any = containerElement.getAttribute('latitude') || '';
    let longitude: any = containerElement.getAttribute('longitude') || '';
    let url: string = `${CommonService.getNullSchoolURL()}?view=${options.view}&showDefault=true&latitude=${latitude}&longitude=${longitude}`;
    ifrm.setAttribute('src', url);
    containerElement.appendChild(ifrm);
  }

  public loadMessageBoard(container: string, options: any, editMode: any, changedStyles: any, CB: any = '', canvasBoard: any) {
    // const socket = io('ws://echo.websocket.org');
    // new MessageBoard(socketIp, baOptions);
    // return new MessageBoard(container, options, editMode, changedStyles, CB, canvasBoard,);
  }

  public loadPolls(divId: string, reqObj: any, editMode: any, changedStyles: any, CB: any = '') {
    let obj = JSON.parse(JSON.stringify(reqObj));
    obj.type = 'GET';
    dataLoaders.processRequest(obj)
      .then((result: any) => {
        let pollsData: any = JSON.parse(result);

        // return new Polls(divId, pollsData, editMode, changedStyles, CB);
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public loadWb(divId: string, config: any) {

    // return new Weather(divId, config)
  }

  public loadEmotiIcons(container: string, config: any = '', options: any, styles: any, editMode: any, CB: any = '') {
    // return new EmotiIcons(container, config, options, styles, editMode, CB);
  }

  public Gamifications(container: string, type: string, CB: any = '') {
    // return new loadGamifications(container, type, CB)
  }

  public loadEpub(container: string, options: any) {
    // return new EpubJs(container, options);
  }

  public renderEbook(container: string, options: string) {
    // return new EpubJs(container, options, true);
  }

  public loadHtmlwidgetTemplate(container: string, options: any) {
    return new Htmlwidget(container, options)
  }

  public loadRaceChart(container: string, options: any) {
    // new RaceChartMap().renderRaceChart(container, options)
  }

  public loadBubbleChart(container: string, options: any) {
    // new D3Wraper.D3Chart(container, options);
  }

  public loadTime(container: string, options: any, element: any) {
    // DateTimer.loadDate(container, options, element)
  }

  public loadCountDownTimer(container: string, options: any, element: any) {
    // DateTimer.loadCountDownTimer(container, options, element)
  }

  public loadEmail(container: string, obj: any) {
    // 
    EmailTemplate.renderEmailTemplate(container, obj)
  }

  public loadTemplates(container: string, obj: any) {
    // 
    // Templates.renderAllTemplates(container, obj)
  }

  public loadSMS(container: string, obj: any) {
    // 
    TextTemplate.renderTextTemplate(container, obj)
  }

  public loadHtmlTemplate(container: string, obj: any) {
    // 
    return new HTMLTemplate(container, obj)
  }

  // pass reqType, options with headers, params to get the response as a request model
  public getRequestFormat(requestType: string = 'GET', options: any = {}) {
    let postParams: any = {}
    let queryParams: string = '';
    let headers: any = {};
    options.params.forEach((obj) => {
      if (requestType == 'GET') {
        queryParams += queryParams == '' ? `?${obj.key}=${obj.value}` : `&${obj.key}=${obj.value}`;
      } else {
        postParams[obj.key] = obj.value;
      }
    })
    options.headers.forEach((obj) => {
      headers[obj.key] = obj.value;
    })
    return {
      postParams: postParams,
      queryParams: queryParams,
      headers: headers
    };
  }

  // in-progress: get ad XML and render in a container
  public getAdFromDSP(requestType: string = 'GET', dspUrl: string = '', options: any = {}) {
    let requestParams: any = this.getRequestFormat();
    if (requestType == 'GET') {
      this.DataLoaders({
        type: 'GET',
        url: dspUrl + requestParams.queryParams,
        headers: requestParams.headers
      })
        .then((result: any) => {
        })
        .catch((err: any) => {
          console.log(err)
        })
    } else {
      let requestOptions: any = {
        type: 'POST',
        url: dspUrl,
        payload: requestParams.postParams,
        headers: requestParams.headers
      }
      dataLoaders
        .processRequest(requestOptions)
        .then((result: any) => { })
        .catch((err: any) => {
          console.log(err)
        })
    }
    return true;
  }

  public renderBAImageSlide(container: string, baOptions: string) {
    BA.slideLoad(container, baOptions)
  }

  public renderBAVideoSlide(container: string, baOptions: string, videoSlideControls) {
    BA.videoSlideLoad(container, baOptions, videoSlideControls)
  }

  public getAdvertise(bind_to, config) {
    let requestObj;
    if (config.AdType && config.AdType == "Full Screen") {
      requestObj = {
        // url: `${ endpoints.MARKETPLACE_SERVICE_URL}/ad/1100042525/130626424/Banner`
        url: `${ endpoints.MARKETPLACE_SERVICE_URL}/ads/xp/app/type?appId=62f10d08fe91f100015f3180&experienceProviderId=${config.experienceProviderId}&type=${config.AdType}`
      }
    } else {
      requestObj = {
        url: `${ endpoints.MARKETPLACE_SERVICE_URL}/ads/xp/app/type?appId=62fca4cdfe91f100015f3268&experienceProviderId=${config.experienceProviderId}&type=Banner`
      }
    }
    this.DataLoaders({
      type: 'GET',
      url: requestObj.url
    })
      .then((result: any) => {
        result = JSON.parse(result) || '';
        var jsSnippet = result.jsSnippet
        var jsSnippet = jsSnippet.split('\n')
        var domTxt = jsSnippet[0];

        var scr = jsSnippet[1];
        scr = scr.split("src=\"")[1]
        scr = scr.split("\"")[0];

        jsSnippet.splice(0, 3);
        jsSnippet.splice(jsSnippet.length - 1, 1);
        var jsCode = jsSnippet.join("\n");

        document.getElementById(bind_to).innerHTML = domTxt;
        var my_awesome_script = document.createElement('script');
        my_awesome_script.setAttribute('src', scr);
        document.body.appendChild(my_awesome_script);
        var my_second_script = document.createElement('script');
        my_second_script.innerHTML = jsCode;

        setTimeout(() => {
          document.body.appendChild(my_second_script);
        }, 3000);
        // var str = jsCode;
        // eval(str);
        // 
        let that = this;
        let obj = {
          "adType": result.adType,
          "eventType": "Ad",
          "experienceProviderId": config.experienceProviderId,
        }
        document.getElementById(bind_to).addEventListener('click', function () {
          that.adAnalytics(bind_to, obj);
        })
        this.analytics.sendAdAnalytics('Ad', obj);
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public renderComponents(components: any) {

    setTimeout(function () {
      try {
        let editableElements: any = document.querySelectorAll(
          '[contenteditable="true"]'
        )
        editableElements.forEach(function (ele: any, ind: number) {
          if (ele.getAttribute('header') == 'true') {
            ele.contentEditable = true;
          }
          else {
            ele.contentEditable = false;
          }
          ele.removeAttribute('onkeyup')
        })
        editableElements = document.querySelectorAll(
          '[contenteditable="false"]'
        )
        editableElements.forEach(function (ele: any, ind: number) {
          ele.removeAttribute('onkeyup')
        })
        // @ts-ignore
        document.querySelector(".ba-element").style.position = 'absolute !important';
      } catch (e) { console.log(e) }
    }, 2000)
    for (var key in components) {
      console.log(key);
      let obj = components[key];
      let chartObj: any = {}
      let cType = obj.type;
      switch (obj.type.toUpperCase()) {
        case 'SLIDER':
          let elements = document.querySelectorAll(`[id="${obj.bind_to}"]`);
          if (elements.length > 1) {
            this.autoSlidingElements(obj);
          } else {
            this.autoSlidingSingleElements(elements, obj);
          }
          break;
        case 'PAYMENT':
          this.renderPayment('container', 8500, 'INR')
          break;
        case 'MAP':
        case 'POLYGONMAP':
        case 'GEOHEATMAP':
        case 'MARKERMAP':
        case 'MAPMARKER':
        case 'MAPPOLYGON':
          obj.config = typeof obj.config == 'string' ? JSON.parse(obj.config) : obj.config;
          obj.dataMaping = typeof obj.dataMaping == 'string' ? JSON.parse(obj.dataMaping) : obj.dataMaping;
          this.renderMapComponent(obj.bind_to, obj);
          setTimeout(() => {
            if (obj.config.mapTransparent == true) {
              let elementId = document.getElementById(obj.bind_to).children[0].id;
              if (elementId.includes('MarkerMap_')) {
                // @ts-ignore
                document.getElementById(obj.bind_to).children[0].style.background = 'none';
                let x = document.getElementById(obj.bind_to);
                // @ts-ignore
                x.getElementsByClassName('leaflet-map-pane')[0].style.opacity = '0.75';
              }
            }
          }, 2000);
          break;
        case 'VIDEOAD':
        case 'BANNERAD':
        case 'IMAGEAD':
          this.getAdvertise(key, obj);
          // obj.bannerAd = obj.bannerAd || '1920x1080';
          // // key element ID
          // obj.APIParams = obj.APIParams || '';
          // obj.requestUrl  = obj.requestUrl || '';
          // obj.requestType = obj.requestType || 'GET';
          // if(obj.requestUrl != '' && obj.APIParams != '') {
          //   let options: any = {
          //     params: obj.APIParams || {},
          //     headers: obj.headers || {}
          //   };
          //   this.getAdFromDSP(obj.requestType, obj.requestUrl, obj.APIParams);
          // }
          break;
        case 'CHART':

          //chartObj.url = obj.baseUrl + obj.tenantID + '/graph/model/' + obj.chartId + '/data';
          if (obj && obj.provider && obj.provider == 'echarts') {
            obj.filters = obj.filters || [];
            this.getChartDefinition(obj, this.totalChartsInBA);
            // RenderCharts.updateSocket(obj.chartId, obj)
          } else {
            chartObj.url = obj.url //(With VPN Connection directly access the chartUrl)
            chartObj.reqPayload = {
              httpMethod: 'GET',
              pathParams: {
                tenantId: obj.tenantID,
                chartId: obj.chartId
              },
              // "url": obj.chartUrl,
              "urlType": "REST",
              "chartType": obj.chartType
            };

            if (obj.filter) {
              chartObj.reqPayload.requestBody = {}
              chartObj.reqPayload.requestBody[obj.filter] = this.USER_INFO[obj.filter] || '';
            }
            chartObj.headers = { "Content-Type": "application/json;charset=UTF-8" };

            this.ChartRender(obj.bind_to, chartObj, this.searchEnable);
          }
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'FIRSTRESPONDER':
          this.renderFirstResponder(key, obj)
          break
        case 'CAMPUSALERTING':
          this.renderAlerting(obj.bind_to, obj.data, obj.newStyles)
          break;
        case 'IMAGE':
          // chartObj = obj.url
          // this.renderBA(key, chartObj)
          break
        case 'VIDEO':
          chartObj = obj.url;
          let videoControls = {
            "autoplay": obj.autoplay,
            "loop": obj.loop,
            "controls": obj.controls,
            "volume": obj.volume
          }
          this.renderVideo(key, chartObj, videoControls)
          break
        case 'AUDIO':
          chartObj = obj.url;
          let audioControls = {
            "autoplay": obj.autoplay,
            "loop": obj.loop,
            "controls": obj.controls,
            "volume": obj.volume
          }
          this.renderAudio(key, chartObj, audioControls)
          break;
        case 'SLIDESHOWIMAGES':
          chartObj = obj.url;
          this.renderBAImageSlide(key, obj);
          break;
        case 'SLIDESHOWVIDEOS':
          chartObj = obj.url;
          let videoSlideControls = {
            "autoplay": obj.autoplay,
            "loop": obj.loop,
            "controls": obj.controls,
            "volume": obj.volume
          }
          this.renderBAVideoSlide(key, chartObj, videoSlideControls);
          break;
        case 'MESSAGEBOARD':
          let mbOptions = {
            boardId: obj.boardId,
            socketIp: obj.socketIp,
            boardLogo: obj.boardLogo,
            boardTitle: obj.boardTitle,
            boardDescription: obj.boardDescription,
            changeStyles: obj.newStyles,
            editMode: obj.editMode,
          }
          this.loadMessageBoard(
            obj.bind_to,
            mbOptions,
            mbOptions.editMode,
            mbOptions.changeStyles,
            '',
            false
          )
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'CART':

          this.renderCart(obj.bind_to, obj.data, obj.newStyles);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'POLLS':
          let requestOptions = {
            type: obj.url_type,
            url: obj.url
          };
          requestOptions.url = requestOptions.url.includes('http://') ? requestOptions.url.replace('http://', 'https://') : requestOptions.url;

          this.loadPolls(obj.bind_to, requestOptions, obj.editMode, obj.newStyles);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'OTAWIDGET':
          this.scalingOTAWidget(obj.bind_to, obj);
          if (obj.url) {
            this.showOTTWidget(obj.bind_to, obj);
          } else {
            this.showOTAWidget(obj.bind_to, obj);
          }
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'CLOCKWIDGET':
          this.renderEChartClock(obj.bind_to, obj);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'WEATHERUI':
          this.renderNullSchoolWidget(obj.bind_to, obj);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'QR':
          let elementsList = document.querySelectorAll(`[id="${obj.bind_to}"]`);
          var processDynamic;
          if (elementsList.length > 1) {
            for (let i = 0; i < elementsList.length; i++) {
              const element = elementsList[i];
              // obj.value = element.getAttribute('href') || element.getAttribute('qr') || obj.value;
              if (element.getAttribute('dynamicqr')) {
                obj.value = element.getAttribute('dynamicqr');
                processDynamic = element.getAttribute(`attr`);
              } else {
                obj.value = element.getAttribute('href') || element.getAttribute('qr') || obj.value;
              }
              if (processDynamic) {
                let eleLength = processDynamic.split(',');
                if (eleLength.length > 1) {
                  for (let i = 0; i < eleLength.length; i++) {
                    let val = element.getAttribute(`attr${i}`);
                    obj.value = obj.value.replace(`{{${eleLength[i]}}}`, val)
                  }
                } else {
                  let val = element.getAttribute(`attr1`);
                  obj.value = obj.value.replace(`{{${eleLength}}}`, val)
                }
              }
              let qrOptions = {
                data: obj.value,
                //'image': 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
                correctionLevel: 'H'
              }
              this.qrCode(obj.bind_to, qrOptions, element);
            }
          } else {
            let QRContainer: any = document.getElementById(obj.bind_to) || '';
            if (QRContainer == '')
              break;
            if (QRContainer.getAttribute('dynamicqr')) {
              obj.value = QRContainer.getAttribute('dynamicqr');
              processDynamic = QRContainer.getAttribute(`attr`);
            } else {
              obj.value = QRContainer.getAttribute('href') || QRContainer.getAttribute('qr') || obj.value;
            }
            if (processDynamic) {
              let eleLength = processDynamic.split(',');
              if (eleLength.length > 1) {
                for (let i = 0; i < eleLength.length; i++) {
                  let val = QRContainer.getAttribute(`attr${i}`);
                  obj.value = obj.value.replace(`{{${eleLength[i]}}}`, val)
                }
              } else {
                let val = QRContainer.getAttribute(`attr1`);
                obj.value = obj.value.replace(`{{${eleLength}}}`, val)
              }
            }
            let qrOptions = {
              data: obj.value,
              //'image': 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
              correctionLevel: 'H'
            }
            this.qrCode(obj.bind_to, qrOptions, '');
          }
          this.renderCSSAnimation(obj.bind_to);
          break
        case 'COMPANION':
          let companionOptions = {
            data: obj.value,
            //'image': 'https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png',
            correctionLevel: 'H'
          }
          this.companion(obj.bind_to, companionOptions);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'DASHBOARD':
          this.DataLoaders({
            type: 'GET',
            url: obj.dashboardUrl
          })
            .then((result: any) => {
              result = JSON.parse(result) || '';
              this.renderNewDashboard(obj.bind_to, result);
            })
            .catch((err: any) => {
              console.log(err)
            })
          break
        case 'EMOTIICONS':
          let reqObj = {
            type: obj.type,
            url: obj.url,
            styles: obj.newStyles,
            editMode: false,
            socketUrl: obj.socketUrl,
            schemaId: obj.schemaId,
            title: obj.title,
            limitedEmojies: obj.limitedEmojies,
            titleImage: obj.titleImage,
          };

          this.loadEmotiIcons(key, reqObj, reqObj, reqObj.styles, reqObj.editMode);
          this.renderCSSAnimation(obj.bind_to);
          break;
        case 'GAMIFICATION':
          this.Gamifications(key, this.emojiType);
          this.renderCSSAnimation(obj.bind_to);
          break
        case 'EBOOK':
          let options = {
            book: obj.url || 'assets/ebook/book1/book1.epub'
          };
          this.renderEbook(key, options.book);
          break
        case 'GEOHEAT':
          // framework.loadGeoHeat(key, '');
          break;
        case 'DATE':
          let dateElementList = document.querySelectorAll(`[id="${obj.bind_to}"]`);
          if (dateElementList.length > 1) {
            for (let i = 0; i < dateElementList.length; i++) {
              const element = dateElementList[i];
              this.loadTime(obj.bind_to, obj.format, element);
            }
          } else {
            this.loadTime(obj.bind_to, obj.format, '');
          }
          break;
        case 'TIMER':
        case 'COUNTDOWNTIMER':
          let timerOptions = {
            hours: 1,
            minutes: 1,
            seconds: 10,
            days: 1,
            months: 0
          }
          let itm = [];
          let timerElementList = document.querySelectorAll(`[id="${obj.bind_to}"]`);
          if (timerElementList.length > 1) {
            for (let i = 0; i < timerElementList.length; i++) {
              let currentEle = timerElementList[i];
              // 
              for (const valu in obj.value) {
                if (obj.value[valu] == "attributeValue") {
                  obj.value[valu] = Number(document.getElementById(obj.bind_to).getAttribute(valu));
                }
              }
              this.loadCountDownTimer(obj.bind_to, obj.value, currentEle);
              for (let item in itm) {
                obj.value[itm[item]] = 'attributeValue'
              }
            }
          } else {
            for (const val in obj.value) {
              if (obj.value[val] == "attributeValue") {
                if (document.getElementById(obj.bind_to)) {
                  obj.value[val] = Number(document.getElementById(obj.bind_to).getAttribute(val));
                }
              }
            }
            this.loadCountDownTimer(obj.bind_to, obj.value, '')
          }
          this.renderCSSAnimation(obj.bind_to);
          break
        case 'TEMPLATE':
          let tempOptions = {
            htmlWidgetID: this.USER_INFO.htmlWidgetID,
            tenantID: this.USER_INFO.tenantId,
            htmlWidgetUrl: endpoints.TEMPLATE_SERVICE_URL(this.USER_INFO.tenantId, this.USER_INFO.htmlWidgetID)
          }
          this.loadHtmlwidgetTemplate('container', tempOptions);
          this.renderCSSAnimation(obj.bind_to);
          break
        case 'WEATHERBULLETIN':
        case 'WEATHER':
          let container = obj.bind_to || 'container'
          let configObj = {
            alertId: obj.alertId,
            tenantId: this.USER_INFO.tenantId,
            boardId: obj.boardId,
            socketIp: obj.socketIp,
            boardTitle: obj.boardTitle,
            boardDescription: obj.boardDescription
          }
          this.loadWb(container, configObj);
          this.renderCSSAnimation(obj.bind_to);
          break
        case 'COMPONENT':

          // obj.url = [
          //   // fliplib
          //   {src: `${endpoints.CONTENT_URL}/download/93dc0f65-ac18-4ba1-8bec-aa83f24d3766`, type: "js"},
          //   // pageScript
          //   {src: `${endpoints.CONTENT_URL}/download/b64fcbb0-a35f-4959-90bc-bedbc3f61a84`, type: "js"},
          //   // pageStyles
          //   {src: `${endpoints.CONTENT_URL}/download/6d57ef7a-643f-471a-87d5-41267afc377c`, type: "css"}
          // ]

          this.renderCustomComponent(obj);
          break
        default:

          break
      }
    }
    // SpatialNavigation.init();
    // SpatialNavigation.add({
    //   selector: '.focusable'
    // });
    // SpatialNavigation.makeFocusable();
    // SpatialNavigation.focus();
  }

  async renderCustomComponent(obj: any) {

    let divId = obj.divId;
    let files = obj.files || [];
    let bind = obj.bind || [];

    try {
      for (const flObject of files) {
        if (flObject.type == "js") {
          await this.loadJSCustomFile(flObject.url);
        } else if (flObject.type == "css") {
          await this.loadCSSCustomFile(flObject.url);
        }
      }

      for (const bindObject of bind) {
        if (bindObject.inputKey) {
          let ctxElement = document.getElementById(bindObject.inputKey).innerHTML = this.jsonObj[bindObject.valueKey];
        }
      }

    } catch (error) {
      console.log(error)
    }
  }

  loadJSCustomFile(src) {
    return new Promise<any>((resolve, reject) => {
      try {
        var script = document.createElement('script');
        script.onload = function () {
          console.log("Load JS FILE");
          resolve(true)
        };
        script.src = src;
        document.body.appendChild(script);
      } catch (e) {
        console.log(e);
        reject(e)
      }
    })
  }

  loadCSSCustomFile(src) {
    return new Promise<any>((resolve, reject) => {
      try {
        var link = document.createElement('link');
        link.onload = function () {
          console.log("Load CSS FILE");
          resolve(true)
        };
        link.rel = 'stylesheet';
        link.href = src;
        link.media = 'all';
        link.type = 'text/css';
        document.head.appendChild(link);
      } catch (e) {
        console.log(e);
        reject(e)
      }
    })
  }

  public renderCSSAnimation(ele) {
    try {
      let element = document.getElementById(ele);
      let options = ["bounce", "flash", "pulse", "rubberBand", "shakeX", "shakeY", "headShake", "swing", "tada", "wobble", "jello", "heartBeat", "backInDown", "backInLeft", "backInRight", "backInUp", "backOutDown", "backOutLeft", "backOutRight", "backOutUp", "bounceIn", "bounceInDown", "bounceInLeft", "bounceInRight", "bounceInUp", "bounceOut", "bounceOutDown", "bounceOutLeft", "bounceOutRight", "bounceOutUp", "fadeIn", "fadeInDown", "fadeInDownBig", "fadeInLeft", "fadeInLeftBig", "fadeInRight", "fadeInRightBig", "fadeInUp", "fadeInUpBig", "fadeInTopLeft", "fadeInTopRight", "fadeInBottomLeft", "fadeInBottomRight", "fadeOut", "fadeOutDown", "fadeOutDownBig", "fadeOutLeft", "fadeOutLeftBig", "fadeOutRight", "fadeOutRightBig", "fadeOutUp", "fadeOutUpBig", "fadeOutTopLeft", "fadeOutTopRight", "fadeOutBottomRight", "fadeOutBottomLeft", "flip", "flipInX", "flipInY", "flipOutX", "flipOutY", "lightSpeedInRight", "lightSpeedInLeft", "lightSpeedOutRight", "lightSpeedOutLeft", "rotateIn", "rotateInDownLeft", "rotateInDownRight", "rotateInUpLeft", "rotateInUpRight", "rotateOut", "rotateOutDownLeft", "rotateOutDownRight", "rotateOutUpLeft", "rotateOutUpRight", "hinge", "jackInTheBox", "rollIn", "rollOut", "zoomIn", "zoomInDown", "zoomInLeft", "zoomInRight", "zoomInUp", "zoomOut", "zoomOutDown", "zoomOutLeft", "zoomOutRight", "zoomOutUp", "slideInDown", "slideInLeft", "slideInRight", "slideInUp", "slideInDown", "slideOutLeft", "slideOutRight", "slideOutUp"]
      let className = '';
      for (let key in options) {
        if (element.className && element.className.includes(options[key])) {
          className = `animate__${options[key]}`;
        }
      }
      if (element.classList)
        element.classList.remove(className);
      setTimeout(() => {
        element.classList.add(className);
      }, 0);
    } catch (e) { }
  }

  public timeStamp() {
    return new Date().getTime()
  }

  public renderNewDashboard(container: string, obj: any) {
    let result = obj;
    let divDashboard = document.createElement('div')
    divDashboard.className = 'grid-stack test'
    divDashboard.id = 'gridStack'
    divDashboard.setAttribute('style', obj.additionalProperties.dashboardDomStyles);
    document.getElementById(container).appendChild(divDashboard);
    //@ts-ignore
    let grid = GridStack.init();
    for (let i = 0; i < obj.components.length; i++) {
      grid.addWidget(obj.components[i].additionalProperties);
      grid.enableMove(false, true)
      grid.enableResize(true, true)
      let id = obj.components[i].additionalProperties.id;
      let node = obj.components[i].additionalProperties;
      let gridStack = document.getElementById('gridStack');
      let chartId = obj.components[i].additionalProperties.bind_to;
      for (let i = 0; i < gridStack.children.length; i++) {
        if (gridStack.children[i].getAttribute('gs-id') === id) {
          if (id.includes('image_')) {
            gridStack.children[i].children[0].innerHTML = `<img id="${id}" class="grid-inner-content"></img>`
          } else if (chartId.includes('chart')) {
            gridStack.children[i].children[0].innerHTML = `<div id="grid_${id}" class="grid-inner-content">
              <label style="width: 100%; text-align: left; padding-left: 10px;" id="title_${node.bind_to}">${node.title}</label>
              <div id="chart_${id}" style="height: 80%; margin-top: 3%"></div>
            </div>`;
          } else if (id.includes('DivElement')) {
            gridStack.children[i].children[0].innerHTML = `<div id=${id} style="height: 100%;"></div>`
          } else if (id.includes('H1')) {
            gridStack.children[i].children[0].innerHTML = `<h1 id=${id}></h1>`
          } else if (id.includes('H2')) {
            gridStack.children[i].children[0].innerHTML = `<h2 id=${id}></h2>`
          } else if (id.includes('H3')) {
            gridStack.children[i].children[0].innerHTML = `<h3 id=${id}></h3>`
          } else if (id.includes('H4')) {
            gridStack.children[i].children[0].innerHTML = `<h4 id=${id}></h4>`
          } else if (id.includes('H5')) {
            gridStack.children[i].children[0].innerHTML = `<h5 id=${id}></h5>`
          } else if (id.includes('H6')) {
            gridStack.children[i].children[0].innerHTML = `<h6 id=${id}></h6>`
          } else if (id.includes('Video')) {
            gridStack.children[i].children[0].innerHTML = `<video controls id="${id}" class="grid-inner-content"></video>`
          } else {
            gridStack.children[i].children[0].innerHTML = `<div id="${id}" class="grid-inner-content"></div>`;
          }
        }
      }
    }

    for (let i = 0; i < obj.components.length; i++) {
      let id = obj.components[i].additionalProperties.id;
      if (obj.components[i].additionalProperties.id && obj.components[i].componentType != 'chart') {
        let element = document.getElementById(id);
        if (id.includes('H1') || id.includes('H2') || id.includes('H3') || id.includes('H4') || id.includes('H5')
          || id.includes('H6')) {
          element = element.parentElement;
        }
        if (obj.components[i].additionalProperties.style)
          element.setAttribute('style', obj.components[i].additionalProperties.style);
        if (obj.components[i].additionalProperties.innerHTML) {
          element.innerHTML = obj.components[i].additionalProperties.innerHTML
        } else if (obj.components[i].additionalProperties.src) {
          element.setAttribute('src', obj.components[i].additionalProperties.src)
        }
      } else if (obj.components[i].componentType === 'chart') {
        let element = document.getElementById('grid_' + obj.components[i].additionalProperties.id);
        element.setAttribute('style', obj.components[i].additionalProperties.style);
      }
    }
    this.renderComponents(obj.renderComponents);
  }

  public renderDashboard(container: string, obj: any) {
    // 
    container = container || ''
    if (container === '') {
      // this.dashboardReferences[container] = {};
      container = 'dashboardContainer'
      let ele = document.createElement('div')
      ele.setAttribute('id', container)
      ele.setAttribute(
        'style',
        'left: 0%; top: 0%; width: 100%; height: 100%; position: absolute;'
      )
      document.body.innerHTML = ''
      document.body.append(ele)
    }
    let result = obj
    let divId = document.getElementById(container)
    this.setDashboardStyles(
      'ba_app',
      obj.additionalProperties.dashboardDomStyles
    )
    if (obj.additionalProperties) {
      // var dashboardTitle = document.createElement('div');
      // dah
      // dashboardTitle.innerHTML = obj.additionalProperties.title;
      // dashboardTitle.style.textAlign = obj.additionalProperties;
      // dashboardTitle.style.fontSize = '2vw';
      // dashboardTitle.style.margin = '1vw';
      // divId.appendChild(dashboardTitle);
      let divDashboardTitle = document.createElement('div')
      divDashboardTitle.className = 'dashboard-title'
      divDashboardTitle.id = 'dashboard-title'
      divDashboardTitle.style.width = '100%'
      divDashboardTitle.style.textAlign = 'center'
      divId.appendChild(divDashboardTitle)
      let divDashboardTitleh2 = document.createElement('h2')
      divDashboardTitleh2.id = 'dashboard-title-h2'
      divDashboardTitleh2.innerText =
        (obj.additionalProperties && obj.additionalProperties.title) ||
        'My Dashboard'
      divDashboardTitleh2.setAttribute('contenteditable', 'true')
      document
        .getElementById(divDashboardTitle.id)
        .appendChild(divDashboardTitleh2)
      this.setDashboardStyles(
        'dashboard-title-h2',
        obj.additionalProperties.style
      )
    }
    let divDashboard = document.createElement('div')
    divDashboard.className = 'grid-stack test'
    divDashboard.id = 'gridStack'
    divId.appendChild(divDashboard)
    let lKey = 'components'
    if (result.graphs) lKey = 'graphs'
    //@ts-ignore
    let grid = GridStack.init()
    this.setDashboardStyles(
      'gridStack', obj.additionalProperties.gridstackStyles
    )
    for (let i = 0; i < result[lKey].length; i++) {
      let type = result[lKey][i].componentType
      let chartType = result[lKey][i].additionalProperties.type
      let title = result[lKey][i].additionalProperties.title
      let tenantId = result.tenantID
      //template, baApp, dashboard
      switch (type) {
        case 'template':
        case 'baApp':
          grid.addWidget(result[lKey][i].additionalProperties)
          //       grid.addWidget($(`<div id="node_${result[lKey][i].appId}"  data-gs-width="${width}" data-gs-height="${height}">
          //     <div class="grid-stack-item-content" id="layer_${result[lKey][i].appId}">
          //         <div class="chart-title">
          //             <label id="title_${title}">${title}</label>
          //         </div>
          //         <div class="grid-chart-block" id="dashboard_baApp_${result[lKey][i].appId}">
          //             ${chartType} chart, Loading Please wait....
          //         </div>
          //     </div>
          // </div>
          // `), result[lKey][i].additionalProperties);
          this.initBApp(
            'dashboard_baApp_' + result[lKey][i].appId,
            result[lKey][i].appId,
            tenantId,
            null,
            false,
            null
          )
          break
        case 'chart':
          //var chartUrl = result[lKey][i].additionalProperties.chartUrl;
          // let tenantId = result.tenantID;
          let width = result[lKey][i].width
          let height = result[lKey][i].height
          grid.enableMove(false, true)
          grid.enableResize(true, true)
          let chartObj1 = {
            //url: result[lKey][i].additionalProperties.url,
            reqPayload: {
              httpMethod: 'GET',
              pathParams: {
                tenantId: tenantId,
                chartId: result[lKey][i].graphId
              },
              //"url": chartUrl,
              urlType: 'REST',
              chartType: chartType
            }
          }
          let node = result[lKey][i].additionalProperties
          grid.addWidget(node)
          //         grid.addWidget($(`<div id="node_${result[lKey][i].graphId}"  data-gs-width="${width}" data-gs-height="${height}">
          //     <div class="grid-stack-item-content" style="background-color:${backgroundColor}; color:${color}"  id="layer_${result.components[i].graphId}">
          //         <div class="chart-title">
          //             <label id="title_${title}">${title}</label>
          //         </div>
          //         <div class="grid-chart-block" id="dashboard_chart_${result[lKey][i].graphId}">
          //             ${chartType} chart, Loading Please wait....
          //         </div>
          //     </div>
          // </div>
          // `), result[lKey][i].additionalProperties);
          let ele = document.getElementsByClassName('grid-stack-item')
          let chartRequestData = JSON.stringify(chartObj1)
          for (let k = 0; k < ele.length; k++) {
            let id = ele[k].getAttribute('gs-id')
            if (id === node.id) {
              ele[
                k
              ].children[0].innerHTML = `<div id="grid_${node.id}" class="grid-inner-content">
                  <div class="grid-stack-item-container" onclick="selectedElementDashboard(event)" id="layer_${node.bind_to}">
                      <div class="grid-chart-title">
                          <label id="title_${node.bind_to}" oninput="updateChartTitle('${node.id}')" contenteditable="true">${node.title}</label>
                          <div class="chart-icons">
                              <span class="fa fa-expand" data='${chartRequestData}' nodeId='${node.id}' id='expandIcon-${node.bind_to}' onclick="expandContainer('${node.bind_to}')"></span>
                          </div>
                      </div>
                      <div class="grid-chart-block" id="${node.bind_to}">
                          ${node.type} chart, Loading Please wait....
                      </div>
                  </div>
              </div>`
            }
          }
          if (result[lKey][i].additionalProperties.style) {
            this.setDashboardStyles(
              `grid_${result[lKey][i].graphId}`,
              result[lKey][i].additionalProperties.style
            )
          }
          // this.dashboardReferences[node.bind_to]['graphId'] = result[lKey][i].graphId;
          this.ChartRender(node.bind_to, chartObj1, this.searchEnable);
          break;
        default:
        // 
      }
    }
  }

  public setDashboardStyles = (elementID, styles) => {
    // 
    let dElement = document.getElementById(elementID)
    for (let sl in styles) {
      if (styles.hasOwnProperty(sl) && styles[sl] !== '') {
        dElement.style[sl] = styles[sl]
      }
    }
  }

  public BALoaded() {
    let BAOpenObj = {
      "eventType": "launch"
    }
    // 1366, 617
    this.analytics.sendAnalytics('open', BAOpenObj);
    // this.scaleUI();
  }

  public sendLog(obj) {
    this.analytics.sendAnalytics('BALog', obj);
  }

  public scaleUI() {
    let scalevalueW = 1;
    let scalevalueH = 1;
    if (window.innerWidth >= 1366) {
      scalevalueW = window.innerWidth / 1920;
      scalevalueH = window.innerHeight / 1080;
      document.body.style.position = 'absolute';
      document.body.style.width = '1920px';
      document.body.style.height = '1080px';
    } else {
      scalevalueW = window.innerWidth / 1366;
      scalevalueH = window.innerHeight / 617;
      document.body.style.position = 'absolute';
      document.body.style.width = '1366px';
      document.body.style.height = '617px';
      document.body.style.fontSize = '14px';
    }
    document.body.style.transform = "scale(" + scalevalueW + "," + scalevalueH + ")";
    document.body.style.transformOrigin = "top left";
  }

  private getPreviewURL() {
    let url = window.location.href;
    let urlPieces = url.split('?');
    urlPieces[0] = `${CDN_HOST}/index.html`;
    return urlPieces.join('?')
  }

  public setBroadBandURL(url = '') {
    this.broadBandURL = url || this.getPreviewURL();
  }

  public getBroadBandURL() {
    return this.broadBandURL;
  }

  public BACloses() {

    let BACloseObj = {
      "eventType": "close"
    }
    this.analytics.sendAnalytics('close', BACloseObj);
  }

  public adAnalytics(bind_to, obj) {
    obj['clickTargetElement'] = bind_to;
    obj['clickType'] = 'ADCLICK';
    this.analytics.sendAdAnalytics('adClick', obj);
  }

  // public async setFavicon(newFaviconUrl) {
  //   const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
  //   link.type = 'image/x-icon';
  //   link.rel = 'icon';
  //   link.href = newFaviconUrl;
    
  //   if (!document.querySelector('link[rel="icon"]')) {
  //   document.head.appendChild(link);
  //   }
  //   }

  public async initBApp(bind_to, appId, tenantId, version, refresh, value, newdesigner?, isDraft?, websiteCrawled?) {
    let baApp = null;
    let appUrl;
    let parameterization = new Parameterization();
    this.paramsDetails = parameterization.validateParams();
    CommonService.updateUserInfo({
      "appId": appId,
      "appid": appId
    })
    CommonService.setTenantId(tenantId);
    if (appId && version) {
      appUrl = endpoints.BA_STORE_SERVICE_V_URL(tenantId, appId, version)
    } else {
      appUrl = endpoints.BA_STORE_SERVICE_URL(tenantId, appId)
    }

    if (newdesigner) {
      // Patch fetch after the script has been added
      await patchFetchWithToken();
      
      // this.DataLoaders({
      //   type: 'GET',
      //   url: endpoints.APP_VERSION_URL(appId)
      // }).then((data: any) => {
      // let appVersion = JSON.parse(data).version;
      this.DataLoaders({
        type: 'GET',
        url: isDraft.toLowerCase() === 'true' ? endpoints.APP_URL(appId) + (version !== null ? `&version=${version}`: '') : endpoints.DEPLOYED_APP_URL(appId)
        // url: `http://localhost:9191/v1.0/ba-app?id=${appId}`
      })
        .then((result: any) => { 
          baApp = JSON.parse(result);
          const baTitle = baApp.title;
          console.log(baApp, result)

          if (baTitle && typeof baTitle === 'string') {
            document.title = baTitle; 
        } else {
            document.title = "Mobius Intelligent Application";
        }

          const wrapper = baApp?.config?.pages?.[0]?.frames?.[0]?.component?.components || baApp.wrapper || []
          const types = baApp.types
          const defaults = baApp.defaults

        if(baApp.hasOwnProperty('events')){
          this.looperSake=baApp.events;
        }

          localStorage.setItem('wrapper', JSON.stringify(wrapper))
          localStorage.setItem('componentsTypes', JSON.stringify(types))
          localStorage.setItem('defaults', JSON.stringify(defaults))
          
          document.getElementById('vue-app').style.visibility = 'visible';

          var elements: any = document.querySelectorAll('[id="vue-app"]');
          for (var i = 0; i < elements.length; i++) {
            elements[i].style.visibility = 'visible';
          }

          const newCss = baApp.css.replace(/#(?![\da-fA-F]{6}|[\da-fA-F]{3})(.+?)[\s|\{]/g, (match, id) => {
            // const newCss = baApp.css.replace(/#(.+?)[\s|\{]/g, (match, id) => {
            return `[id^=${id}]{`;
          });

          function loadScripts(urls, callback) {
            if (!urls || urls.length === 0) {
                if (callback) callback();
                return;
            }
        
            let url = urls.shift(); // Get the first URL and remove it from the list
            if (url.url.includes('http://')) {
              url.url = 'https'+url.url.slice(4,)
            }
            if (url.type === "css") {
              const linkElement = document.createElement("link");
              linkElement.rel = "stylesheet";
              linkElement.href = url.url;
              document.head.appendChild(linkElement); 
              linkElement.onload = function() {
                loadScripts(urls, callback); // Load the next script
              }
            } else if(url.type === "js" || url.type === "script"){
              const srcElement = document.createElement("script");
              srcElement.type = 'text/javascript';
              srcElement.src = url.url;
              document.head.appendChild(srcElement); 
              srcElement.onload = function() {
                loadScripts(urls, callback); // Load the next script
              }
            } else {
              console.log("Unrecognized file type:", url.type);
              loadScripts(urls, callback); // Load the next script
            }
        }

          const abcd = () => {
            document.head.innerHTML += `<style> ${baApp.css} </style>`
            document.body.innerHTML += `<style> #vue-app{visibility: visible !important;} .looper{ border: none !important; } .overflow-auto{ overflow: auto !important; } .slide_looper { box-shadow: unset !important; border: none !important; }${newCss}</style>`
            document.body.style.cssText = newCss;
            if(websiteCrawled) {
              document.body.innerHTML = '';
              document.body.innerHTML = `<body>${baApp.html.body}</body>`;
            } else {
              // var newScript1 = document.createElement('script');
              // newScript1.src = 'https://cdn.tailwindcss.com';
              // document.head.appendChild(newScript1);
              document.getElementById(bind_to).innerHTML = `<body>${baApp.html.body}</body>`;
            }
            this.handleAppVariables(baApp.variables);

            var script = document.createElement("script");
            script.innerHTML = baApp.script;
            document.body.appendChild(script);

            var newScript1 = document.createElement('script');
            newScript1.src = 'https://unpkg.com/leaflet-routing-machine@3.2.12/dist/leaflet-routing-machine.js';
            document.body.appendChild(newScript1);

            var newScript2 = document.createElement('script');
            newScript2.src = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet.markercluster/1.5.3/leaflet.markercluster.js';
            document.body.appendChild(newScript2);

            var newScript3 = document.createElement('script');
            newScript3.src = 'https://ajax.googleapis.com/ajax/libs/jqueryui/1.11.4/jquery-ui.min.js';
            document.body.appendChild(newScript3);

            var newScript4 = document.createElement('script');
            newScript4.src = 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui-touch-punch/0.2.3/jquery.ui.touch-punch.min.js';
            document.body.appendChild(newScript4);

            baApp.events=this.looperSake;

            this.HandleBAppEvents(baApp.events);
            this.addNavigation();
            // Call the new method to enable free dragging
            this.enableFreeDrag();

            // this.setFavicon('http://monet.gaiansolutions.com/favicon.ico');

            // @ts-ignore
            SpatialNavigation.init();
            // @ts-ignore
            SpatialNavigation.add({
              selector: '.focusable'
            });
            // @ts-ignore
            SpatialNavigation.makeFocusable();
            // @ts-ignore
            SpatialNavigation.focus();
            this.BALoaded();
            // document.body.style.display = "none"
            // let PI_data = new PI();
            // let web = window.location.hostname
            // PI_data.RenderData(web,document);
            return true;
          }

          loadScripts(baApp.files, function() {
            // Initialize your component that depends on the third-party libraries
            abcd();
            return true;
          });
        })
        .catch((err: any) => {
          console.log(err)
        })
      // })
      .catch((err: any) => {
        console.log(err)
      })
    }

    if (!newdesigner)
      this.DataLoaders({
        type: 'GET',
        url: `${appUrl}`
      })
        .then((result: any) => {
          baApp = JSON.parse(result);
          this.BAAppData = baApp;
          let appName = baApp.baAppConfig.app_name;
          baApp.baAppConfig.bbandEntryPageUrl = baApp.baAppConfig.bbandEntryPageUrl || '';
          this.setBroadBandURL(baApp.baAppConfig.bbandEntryPageUrl);
          document.title = appName;
          this.addScripts(baApp.baAppConfig.files);
          // let analyticsSchema = '6139dce6e8d3cd0001430b74';
          // this.USER_INFO.tenantId = this.params.tenantId;
          let analyticsSchema = baApp.baAppConfig.config.analyticsSchema || '6139dce6e8d3cd0001430b74';
          CommonService.updateUserInfo({
            "tenantId": tenantId,
            "appName": appName,
            "analyticsSchema": analyticsSchema
          })
          // this.USER_INFO.appName = appName;
          // this.USER_INFO.analyticsSchema = analyticsSchema;

          this.BALoaded();
          this.BAppData(bind_to, baApp, tenantId);
          if (refresh && refresh >= 5) {
            setInterval(() => {

              let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
              this.renderComponents(response1);
              return true;
            }, refresh * 1000);
          } else if (this.BAAppData.baAppConfig.config.refresh) {
            setInterval(() => {

              let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
              this.renderComponents(response1);


              document.body.style.display = "none"

              // let PI_data = new PI();
              // let web = window.location.hostname
              // PI_data.RenderData(web, document);




              return true;
            }, this.BAAppData.baAppConfig.config.refresh * 1000);
          }
        })
        .catch((err: any) => {
          console.log(err)
        })
  }

  public async initBAppWithMulti(bind_to, appId, tenantId, version, refresh, value, newdesigner?) {
      let baApp = null;
      let appUrl;
      // this.USER_INFO.appId = appId;
      CommonService.updateUserInfo({
        "appId": appId,
        "appid": appId
      })
      if (appId && version) {
        appUrl = endpoints.BA_STORE_SERVICE_V_URL(tenantId, appId, version)
      } else {
        appUrl = endpoints.BA_STORE_SERVICE_URL(tenantId, appId)
      }

      this.DataLoaders({
        type: 'GET',
        url: endpoints.APP_URL(appId)
      })
        .then((result: any) => {
          console.log(result)
          baApp = JSON.parse(result);
        
          const wrapper = baApp?.config?.pages?.[0]?.frames?.[0]?.component?.components || baApp.wrapper || []
          const types = baApp.types
          const defaults = baApp.defaults

          localStorage.setItem('wrapper', JSON.stringify(wrapper))
          localStorage.setItem('componentsTypes', JSON.stringify(types))
          localStorage.setItem('defaults', JSON.stringify(defaults))


          // Removing the already rendered applet script and styles
          // Select all script tags
          let scripts = document.querySelectorAll('script');

          // Iterate over each script tag and remove it from the DOM
          scripts.forEach(script => script.remove());

          // Select all style tags
          let styles = document.querySelectorAll('style');

          // Iterate over each style tag and remove it from the DOM
          styles.forEach(style => style.remove());

          document.getElementById('vue-app').style.visibility = 'visible';

          var elements: any = document.querySelectorAll('[id="vue-app"]');
          for (var i = 0; i < elements.length; i++) {
            elements[i].style.visibility = 'visible';
          }

          const newCss = baApp.css.replace(/#(?![\da-fA-F]{6}|[\da-fA-F]{3})(.+?)[\s|\{]/g, (match, id) => {
            // const newCss = baApp.css.replace(/#(.+?)[\s|\{]/g, (match, id) => {
            return `[id^=${id}]{`;
          });
          // console.log("NewCSS", newCss)
          document.head.innerHTML += `<style> ${baApp.css} </style>`
          document.body.innerHTML += `<style> #vue-app{visibility: visible !important;} .looper{ border: none !important; } .overflow-auto{ overflow: auto !important; } .slide_looper { box-shadow: unset !important; border: none !important; }${newCss}</style>`
          document.body.style.cssText = newCss;
          document.getElementById(bind_to).innerHTML = `<body>${baApp.html.body}</body>`;
          this.handleAppVariables(baApp.variables);
          var script = document.createElement("script");
          script.innerHTML = baApp.script;
          document.body.appendChild(script);
          var newScript1 = document.createElement('script');
          newScript1.src = 'https://unpkg.com/leaflet-routing-machine@3.2.12/dist/leaflet-routing-machine.js';
          document.body.appendChild(newScript1);
          var newScript2 = document.createElement('script');
          newScript2.src = 'https://cdnjs.cloudflare.com/ajax/libs/leaflet.markercluster/1.5.3/leaflet.markercluster.js';
          document.body.appendChild(newScript2);

          var newScript4 = document.createElement('script');
          newScript4.src = 'https://cdnjs.cloudflare.com/ajax/libs/jqueryui-touch-punch/0.2.3/jquery.ui.touch-punch.min.js';
          document.body.appendChild(newScript4);
          
          this.HandleBAppEvents(baApp.events);
          this.addNavigation();
          this.BALoaded();

        })
        .catch((err: any) => {
          console.log(err)
        })
  }

  public async initWidget(bind_to, widgetId, version, newdesigner?, isDraft?) {
    let widget = null;
    if (newdesigner) {
      // Patch fetch after the script has been added
      patchFetchWithToken();
      const WidgetType = isDraft.toLowerCase() === 'true' ? 'draftId': 'publishId';
      // Fetch the widget config based on preview type
      this.DataLoaders({
        type: 'GET',
        url: endpoints.WIDGET_URL(widgetId, WidgetType)
      })
      .then((result: any) => { 
        widget = JSON.parse(result);
        const widgetTitle = widget.name;
        // adding title to document
        if (widgetTitle && typeof widgetTitle === 'string') {
          document.title = widgetTitle; 
        } else {
          document.title = "Mobius Intelligent Application";
        }
        if(widget.hasOwnProperty('events')){
          this.looperSake=widget.events; // handles events specific to looper
        }
        // main element in the body 
        document.getElementById('vue-app').style.visibility = 'visible';
        var elements: any = document.querySelectorAll('[id="vue-app"]');
        for (var i = 0; i < elements.length; i++) {
          elements[i].style.visibility = 'visible';
        }
        // css parsing 
        const newCss = widget.css.replace(/#(?![\da-fA-F]{6}|[\da-fA-F]{3})(.+?)[\s|\{]/g, (id) => {
          return `[id^=${id}]{`;
        });
        // callback for all libraries passed, to prcoess before script is added to DOM.
        function loadScripts(urls, callback) {
          if (!urls || urls.length === 0) {
            if (callback) callback();
            return;
          }
      
          let url = urls.shift(); // Get the first URL and remove it from the list
          if (url.url.includes('http://')) {
            url.url = 'https'+url.url.slice(4,)
          }
          if (url.type === "css") {
            const linkElement = document.createElement("link");
            linkElement.rel = "stylesheet";
            linkElement.href = url.url;
            document.head.appendChild(linkElement); 
            linkElement.onload = function() {
              loadScripts(urls, callback); // Load the next library
            }
          } else if(url.type === "js" || url.type === "script"){
            const srcElement = document.createElement("script");
            srcElement.type = 'text/javascript';
            srcElement.src = url.url;
            document.head.appendChild(srcElement); 
            srcElement.onload = function() {
              loadScripts(urls, callback);
            }
          } else {
            console.log("Unrecognized file type:", url.type);
            loadScripts(urls, callback);
          }
        }
        // Initializing widget that depends on the third-party libraries
        const loadWidget = () => {
          document.head.innerHTML += `<style> ${widget.css} </style>`
          document.body.innerHTML += `<style> #vue-app{visibility: visible !important;} .looper{ border: none !important; } .overflow-auto{ overflow: auto !important; } .slide_looper { box-shadow: unset !important; border: none !important; }${newCss}</style>`
          document.body.style.cssText = newCss;
          document.getElementById(bind_to).innerHTML = `${widget.html}`;
          // handling widget based variables
          this.handleAppVariables(widget.variables);
          // script loading of a widget
          var script = document.createElement("script");
          script.innerHTML = widget.script;
          document.body.appendChild(script);

          widget.events=this.looperSake;

          this.HandleBAppEvents(widget.events);
          // Providing the spatial navigation facility for larger screen manual operations
          this.addNavigation();
          // Call the new method to enable free dragging for any particular components 
          this.enableFreeDrag();
          return true;
        }

        loadScripts(widget.files, function() {
          loadWidget();
          return true;
        });
      })
      .catch((err: any) => {
        console.log(err)
      })
    .catch((err: any) => {
      console.log(err)
    })
    }
  }

  public addNavigation() {
    setTimeout(() => {
      window.addEventListener('load', function () {
        // @ts-ignore
        SpatialNavigation.init();
        // @ts-ignore
        SpatialNavigation.add({
          selector: '.focusable'
        });
        // @ts-ignore
        SpatialNavigation.makeFocusable();
        // @ts-ignore
        SpatialNavigation.focus();
      });
    }, 0);
  }


  public enableFreeDrag() {
    const elements = document.getElementsByClassName('free-drag');
    if(elements) {
      Array.from(elements).forEach((element: HTMLElement) => {
        element.style.position = 'absolute';
        element.style.zIndex = '999';
        element.style.cursor = 'move';
        // Retrieve saved position from localStorage
        if(element.classList.contains('state')) {
          const savedPosition = localStorage.getItem(`elementPosition_${element.id}`);
          if (savedPosition) {
            const { top, left } = JSON.parse(savedPosition);
            element.style.top = `${top}px`;
            element.style.left = `${left}px`;
          }
        }
        let pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
        element.onmousedown = dragMouseDown;
  
        function dragMouseDown(e: MouseEvent) {
          const event = e as MouseEvent;
          event.preventDefault();
          pos3 = event.clientX;
          pos4 = event.clientY;
          document.onmouseup = closeDragElement;
          document.onmousemove = elementDrag;
        }
  
        function elementDrag(e: MouseEvent) {
          const event = e as MouseEvent;
          event.preventDefault();
          pos1 = pos3 - event.clientX;
          pos2 = pos4 - event.clientY;
          pos3 = event.clientX;
          pos4 = event.clientY;
          element.style.top = (element.offsetTop - pos2) + 'px';
          element.style.left = (element.offsetLeft - pos1) + 'px';
          if(element.classList.contains('state')) {
            // Save the current position to localStorage
            localStorage.setItem(
              `elementPosition_${element.id}`,
              JSON.stringify({ top: element.offsetTop, left: element.offsetLeft })
            );
          }
        }

        function closeDragElement() {
          document.onmouseup = null;
          document.onmousemove = null;
        }
      });
    }
  }

  public loadDependencies(files: any[]) {
    files.forEach(fl => {
      if (fl.type === 'css') {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = fl.url;
        document.head.appendChild(link);
      } else if (fl.type === 'script') {
        const script = document.createElement('script');
        script.src = fl.url;
        document.head.appendChild(script);
      }
    });
  }

  public HandleBAppEvents(baAppEvents) {
    console.log('baAppEvents', baAppEvents)
    if (baAppEvents) {

      const groupedEvents = groupBy(baAppEvents, (e) => e.elementID + "-" + e.eventType);

      values(groupedEvents).forEach((eventsArray) => {
        const actions = flatten(map(eventsArray, e => e.actions));
        const baEvent = {
            "eventName": eventsArray[0].eventName,
            "eventType": eventsArray[0].eventType,
            "elementID": eventsArray[0].elementID,
            "reRender": eventsArray[0].reRender,
            "isPublic": eventsArray[0].isPublic,
            actions
        };
        switch (baEvent.eventType) {
          case DefaultEvents.INIT:

            $(document).ready(function () {
              $(document).on('init', `[id^="${baEvent.elementID}"]`, function (event) {
                console.log('init', event)
                this.HandleBAppActions(baEvent, event);
              });
            });

            this.HandleBAppActions(baEvent);

            break;

          case DefaultEvents.CLICK:
            $(document).on('click sn:enter-down', `[id^="${baEvent.elementID}"]`, (event: any) => {
              if(actions[0].actionParams.showLoader) {
                this.showLoader();
              }     
              event.stopPropagation();
              event.preventDefault();
              console.log('onclick event fired: ', baEvent.elementID)
              // $(document).on('click', `#${baEvent.elementID}`, (event) => {
             this.clearHTML(baEvent, event);
              this.HandleBAppActions(baEvent, event);
              var that = this;
              if(actions[0].actionParams.showLoader) {
                setTimeout(() => {
                  that.hideLoader();
                }, 500);
               }  
            });
            break;

          case DefaultEvents.BLUR:
            $(document).on('blur', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.HandleBAppActions(baEvent, event)
            });

            break;

          case DefaultEvents.FOCUS:
            $(document).on('focus', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.HandleBAppActions(baEvent, event)
            });
            break;

          case DefaultEvents.MOUSE_ENTER:
            $(document).on('mouseenter', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.HandleBAppActions(baEvent, event)
            });
            break;

          case DefaultEvents.MOUSE_LEAVE:
            $(document).on('mouseleave', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.HandleBAppActions(baEvent, event)
            });
            break;

          case DefaultEvents.CHANGE:
            $(document).on('change', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.HandleBAppActions(baEvent, event)
            });
            break;

          case DefaultEvents.INPUT:
            $(document).on('input', `[id^="${baEvent.elementID}"]`, (event) => {
              $(`[id^="${baEvent.elementID}"]`).val(event.target.value);
              this.clearHTML(baEvent, event)
              this.HandleBAppActions(baEvent, event)
            });
            break;

            case DefaultEvents.LONGPRESS:
              $(document).on('long-press', `[id^="${baEvent.elementID}"]`, (event: any) => {
                // $(document).on('click', `#${baEvent.elementID}`, (event) => {
                this.clearHTML(baEvent, event);
                this.HandleBAppActions(baEvent, event);
              });
              break;
          case DefaultEvents.ENTER:
            $(document).on('keyup', `[id^="${baEvent.elementID}"]`, (event: any) => {
              if (event.keyCode === 13) {
                event.stopPropagation();
                event.preventDefault();
                // this.showLoader();
                console.log('onclick event fired: ', baEvent.elementID)
                this.clearHTML(baEvent, event);
                this.HandleBAppActions(baEvent, event);
                var that = this;
                // setTimeout(() => {
                //   that.hideLoader();
                // }, 500);
              }
            });
            break; 
          default:
            break;
        }
      })
    }
  }

  public async handleAppVariables(baVariables: any[]) {

    baVariables?.forEach(variable => {
      if (variable.type === "STATIC") {
        variable.value = this.tryParseJSON(variable.value)
        this.GLOBAL_VARIABLES[variable.name] = new BehaviorSubject(variable);
      } else if (variable.type === "DYNAMIC") {
        // Set an initial value
        this.GLOBAL_VARIABLES[variable.name] = new BehaviorSubject([]);

        // Fetch data in background and update the variable when the data arrives
        this.getDataLakeSource(variable.value, {}, {}, '', '', '', '')
          .then(data => {
            variable.value = data.model?.entities || [];
            this.GLOBAL_VARIABLES[variable.name].next(variable);
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle your error as you want
          });
      }
    });

  }

  public tryParseJSON(jsonString) {
    try {
      var parsed = JSON.parse(jsonString);

      // Arrays and objects will be parsed, nulls and others will not
      if (Array.isArray(parsed) || typeof parsed === 'object') {
        return parsed;
      }
    } catch (e) {
      // Do nothing, let the function proceed to return the original string
    }
    return jsonString;
  }
  contentsMap = new Map();

  // public clearHTML(baEvent, event) {
  //   this.contentsMap.forEach((value, key) => {
  //     // console.log(key, value)
  //     // let element = document.getElementById(key)
  //     let element = document.querySelectorAll(baEvent.actions[0].actionParams[0].targetSelector);
  //     if (element && element[0]) {
  //       let ctnEl = element[0].innerHTML
  //       element[0].innerHTML = ctnEl.replace(value, '');
  //       console.log(element[0].textContent, 'text cnt')
  //       // this.contentsMap.delete(key);
  //     }

  //   });
  // }
  public clearHTML(baEvent, event) {
    this.contentsMap.forEach((value, key) => {
      console.log(key, value)
      let element = document.getElementById(key)
      if (element) {
        let ctnEl = element.innerHTML
        element.innerHTML = ctnEl.replace(value, '');
        // this.contentsMap.delete(key);
      }

    });
  }

  public showLoader(){
    if(document.getElementById('loader')){
      let loader = document.getElementById('loader');
      loader.style.display = 'block';
      let overlay = document.getElementById('overlay');
      overlay.style.display = 'block';
    } else {
      // Create a new div element for the loader
      let loader = document.createElement('div');
      loader.id = 'loader';
      loader.style.display = 'block';
      loader.style.position = 'fixed';
      loader.style.zIndex = '9999999';
      loader.style.overflow = 'show';
      loader.style.margin = 'auto';
      loader.style.top = '0';
      loader.style.left = '0';
      loader.style.bottom = '0';
      loader.style.right = '0';
      loader.style.width = '40px';
      loader.style.height = '40px';
      loader.style.border = '4px solid #f3f3f3'; 
      loader.style.borderTop = '4px solid #3498db'; 
      loader.style.borderRadius = '50%';
      loader.style.animation = 'spin 2s linear infinite';
      // loader.style.background = 'rgba(0, 0, 0, 0.5)';

      // Create a new div element for the overlay
      let overlay = document.createElement('div');
      overlay.id = 'overlay';
      overlay.style.display = 'block';
      overlay.style.position = 'fixed';
      overlay.style.zIndex = '9999999'; // One less than the loader, so it appears behind it
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
      
      
      // Append the loader to the body
      document.body.appendChild(loader);
      document.body.appendChild(overlay);

      // Add keyframes for spin animation
      let style = document.createElement('style');
      style.innerHTML = `@keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }`;
      document.head.appendChild(style);

      // You can hide the loader by setting the display to 'none'
      // loader.style.display = 'none';
    }
    
  }

  public hideLoader(){
    let loader = document.getElementById('loader');
    let overlay = document.getElementById('overlay');
    if(loader && overlay){
      loader.style.display = 'none';
      overlay.style.display = 'none';
    }
  }

  public SHOW_HTML(elementID, event, actionsParams, newStateMap) {
    actionsParams?.forEach((actionEl: any) => {
      if (actionEl.action == "Added_Toggle") {
        try {
          let elements = document.querySelectorAll(actionEl.targetSelector);
          if (elements && elements[0]) {
            elements[0].innerHTML += actionEl.html
          }
        } catch (error) {
          console.log('Add Error')
        }
      } else if (actionEl.action == "Added") {
        try {
          let elements = document.querySelectorAll(actionEl.targetSelector);
          if (elements && elements[0]) {
            let ctnEl = elements[0].innerHTML;
            if (this.contentsMap.has(elements[0].id) && this.contentsMap.get(elements[0].id).includes(actionEl.html)) {
              this.contentsMap.delete(elements[0].id);
              // elements[0].innerHTML = ctnEl.replace(actionEl.html, '');
            } else if (this.contentsMap.has(elements[0].id)) {
              // this.contentsMap.set(elements[0].id, actionEl.html);
              newStateMap.set(elements[0].id, actionEl.html);
              elements[0].innerHTML += actionEl.html
            } else {
              // this.contentsMap.set(elements[0].id, actionEl.html);
              newStateMap.set(elements[0].id, actionEl.html);
              elements[0].innerHTML += actionEl.html
            }
            // if (this.contentsMap.has(elements[0].id) && ctnEl.includes(actionEl.html)) {
            //   this.contentsMap.delete(elements[0].id);
            //   elements[0].innerHTML = ctnEl.replace(actionEl.html, '');
            // } else if (this.contentsMap.has(elements[0].id)) {
            //   elements[0].innerHTML = ctnEl.replace(this.contentsMap.get(elements[0].id), actionEl.html);
            //   this.contentsMap.set(elements[0].id, actionEl.html);
            // } else {
            //   this.contentsMap.set(elements[0].id, actionEl.html);
            //   elements[0].innerHTML += actionEl.html
            // }
          }
        } catch (error) {
          console.log('Added_Toggle Error')
        }
      } else if (actionEl.action == "Modified") {
        try {
          let elements = document.querySelectorAll(actionEl.targetSelector);
          if (elements && elements[0]) {
            // let splittedHTML = actionEl.html.split('-')
            // let ctnEl = elements[0].innerHTML
            // let newString = ctnEl.replace(splittedHTML[0], splittedHTML[1]);
            // elements[0].innerHTML = newString
            elements[0].innerHTML = actionEl.html;
          }
        } catch (error) {
          console.log('Modified Error')
        }
      } else if (actionEl.action == "Removed") {
        try {
          let elements = document.querySelectorAll(actionEl.targetSelector);
          if (elements && elements[0]) {
            elements[0].innerHTML.style.display = 'none';
          }
        } catch (error) {
          console.log('Modified Error')
        }
      }
    });
  }

  public SHOW_ALERT(elementID, event, actionsParams) {
    return alert(actionsParams.alertType + " " + this.replaceVariables(actionsParams.message, { ...this.GLOBAL_VARIABLES, elementID: (event?.target?.id) ? event.target.id : elementID }));
  }

  public SHOW_TOAST(elementID, event, actionsParams) {
    return;
  }

  public SHOW_ELEMENT(elementID, event, actionsParams){
    return $(`[id^="${actionsParams.elementID}"]`).show();
  }

  public HIDE_ELEMENT(elementID, event, actionsParams){
    return $(`[id^="${actionsParams.elementID}"]`).hide();
  }

  public TOGGLE_ELEMENT(elementID, event, actionsParams) {
    if (actionsParams.toggleParent) {
      $(`[id^="${actionsParams.elementID}"]`).show();
      // $(`[id^="${event.currentTarget.id}"]`).hide();
      $(`[id^="${elementID}"]`).hide();
      $(document).on('click', `[id^="${actionsParams.elementID}"]`, (e) => {
        $(`[id^="${actionsParams.elementID}"]`).hide();
        // $(`[id^="${event.currentTarget.id}"]`).show();
        $(`[id^="${elementID}"]`).show();
      });
    } else {
      $(`[id^="${actionsParams.elementID}"]`).toggle();
    }
  }

  public RUN_QUERY(elementID, event, actionsParams) {
    console.log("Query Running");
  }

  public FLIP_ANIMATION(elementID, event, actionsParams) {
    var showImage1 = true;
    setInterval(function () {
      var images1 = document.querySelectorAll(`.${actionsParams.parentClassName}`),
        images2 = document.querySelectorAll(`.${actionsParams.targetClassName}`);
      if (showImage1) {
        images1.forEach(function (image: any) {
          image.style.opacity = 0;
          image.classList.remove('animate__animated', 'animate__fadeIn');
        });
        images2.forEach(function (image: any) {
          image.classList.add('animate__animated', 'animate__fadeIn');
        });
        showImage1 = false;
      } else {
        images2.forEach(function (image: any) {
          image.style.opacity = 0;
          image.classList.remove('animate__animated', 'animate__fadeIn');
        });
        images1.forEach(function (image: any) {
          image.classList.add('animate__animated', 'animate__fadeIn');
        });
        showImage1 = true;
      }
    }, actionsParams.animationDuration);
  }

  public CUSTOM_ACTION(elementID, event, actionsParams) {
    const fn = new Function('elementID', actionsParams.JsCode); // Bind elementID as an argument
    
    // Call the function with elementID as an argument and return its result
    return fn(elementID);
    
  }

  public HIGHLIGHT(elementID, event, actionsParams){
    return document.querySelector('#'+actionsParams.elementID).setAttribute('style', actionsParams.CssCode);
  }

  public SET_VARIABLE(elementID, event, actionsParams){
    return this.setVariable(actionsParams.variableName, actionsParams.variableValue);
  }

  public UPDATE_VARIABLE(elementID, event, actionsParams){
    var dataId = event?.currentTarget?.getAttribute('data-id') ?  event.currentTarget.getAttribute('data-id') :  event?.target?.getAttribute('data-id') ;
    if(event.currentTarget.type == 'checkbox' && event.currentTarget.value == 'on' || 'off' || 'true' || 'false'){
      return this.setVariable(actionsParams.variableName, dataId ? dataId : event.currentTarget.value);
    }
    else {
     return this.setVariable(actionsParams.variableName, (event.currentTarget.value) ? event.currentTarget.value : dataId);
    }
  } 

  public async FILTER_VARIABLE(elementID, event, actionsParams){
    return await this.filterVariable(elementID,actionsParams);
  }

  public async GET_API(elementID, event, actionsParams) {
    const elmntG = document.getElementById(event?.target?.id)
    const dataIDG = elmntG?.getAttribute('data-id')
    if(actionsParams.url){
      actionsParams['uri'] = actionsParams.url;
    }
    var urii = this.replaceVariables(actionsParams.uri, { ...this.GLOBAL_VARIABLES, elementID: (event?.target?.id) ? event.target.id : elementID, dataID: dataIDG });
    let nestUrl = null;
      let nestPayload = null;
      if (actionsParams.nested){
          nestUrl = this.replaceVariables(actionsParams?.nestedUrl, { ...this.GLOBAL_VARIABLES, elementID: (event?.target?.id) ? event.target.id : elementID, dataID: dataIDG });
          nestPayload =  await this.replaceWithBehaviorSubjectValues(actionsParams?.nestedPayload);
      }
    const tokenG = actionsParams.token;
    var that = this;
    fetch(urii, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenG
      }
    }).then(response => response.json()).then(data => {
      console.log('Success:', data);
      if(actionsParams.successMessage) {
          document.body.innerHTML += `<div id="toast-success" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span class="sr-only">Check icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">${actionsParams.successMessage}</div>
        </div>`;
        setTimeout(() => {
          document.querySelector('#toast-success').remove();
        }, 3000);
      }
      actionsParams.saveResponse ? that.setVariable(actionsParams.saveResponse, data) : console.log('GET API DATA ', data);
      if(actionsParams.nested) {
        if(actionsParams.nestedType.toLowerCase() === 'get'){
          return fetch(nestUrl,  {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + tokenG
            }
          })
          .then(response => response.json())
          .then(secondData => {
            // Handle the data from the second GET request
            console.log(secondData);
            actionsParams.nestedResponse ? that.setVariable(actionsParams.nestedResponse, secondData) : console.log('Nested GET API DATA ', secondData);
          }) 
        }
        else {
        
          return fetch(nestUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': 'Bearer ' + tokenG
            },
            body: nestPayload,
          })
          .then(response => response.json())
          .then(postResponseData => {
            // Handle the data from the POST request
            console.log(postResponseData);
            actionsParams.nestedResponse ? that.setVariable(actionsParams.nestedResponse, postResponseData) : console.log('Nested POST API DATA ', postResponseData);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
        
      }
    }, error => {
      console.log('GET API Error:', error);
      if(actionsParams.failureMessage) {
          document.body.innerHTML += `<div id="toast-danger" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
              </svg>
              <span class="sr-only">Error icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">${actionsParams.failureMessage}</div>
        </div>`;
        setTimeout(() => {
          document.querySelector('#toast-danger').remove();
        }, 3000);
      }
    })
  }

  public async POST_API(elementID, event, actionsParams)  {
      const elmnt = document.getElementById(event?.target?.id)
      const dataID = elmnt?.getAttribute('data-id')
      if(actionsParams.url){
        actionsParams['uri'] = actionsParams.url;
      }
      const uri = this.replaceVariables(actionsParams.uri, { ...this.GLOBAL_VARIABLES, elementID: (event.target.id) ? event.target.id : elementID, dataID: dataID });
      let nestedUrl = null;
      let nestedPayload = null;
      if (actionsParams.nested){
        nestedUrl = this.replaceVariables(actionsParams?.nestedUrl, { ...this.GLOBAL_VARIABLES, elementID: (event.target.id) ? event.target.id : elementID, dataID: dataID });
        nestedPayload = actionsParams.nestedPayload? await this.replaceWithBehaviorSubjectValues(actionsParams.nestedPayload): '';
      }
      let parsedPayload = await this.replaceWithBehaviorSubjectValues(actionsParams.payload);
      if (actionsParams.search) {
        let parsedPayloadNew = JSON.parse(parsedPayload);
        let searchKeywordsValue;
        for (const attributeName in parsedPayloadNew) {
          if (parsedPayloadNew.hasOwnProperty(attributeName)) {
            searchKeywordsValue = parsedPayloadNew[attributeName];
          }
        }
        if (searchKeywordsValue == "") {
          parsedPayload = "{}";
        }
      }
      const token = actionsParams.token;
      var that = this;
      fetch(uri, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        },
        body: parsedPayload
      }).then(response => response.json()).then(data => {
        console.log('Success:', data);
        if(actionsParams.successMessage) {
            document.body.innerHTML += `<div id="toast-success" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;">
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                </svg>
                <span class="sr-only">Check icon</span>
            </div>
            <div class="ml-3 text-sm font-normal">${actionsParams.successMessage}</div>
          </div>`;
          setTimeout(() => {
            document.querySelector('#toast-success').remove();
          }, 3000);
        }
        actionsParams.saveResponse ? that.setVariable(actionsParams.saveResponse, data) : console.log('No Save Varibale to save API Data');
        if(actionsParams.nested) {
          if(actionsParams.nestedType.toLowerCase() === 'get'){
            return fetch(nestedUrl,  {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              }
            })
            .then(response => response.json())
            .then(secondData => {
              // Handle the data from the second GET request
              console.log(secondData);
              actionsParams.nestedResponse ? that.setVariable(actionsParams.nestedResponse, secondData) : console.log('Nested GET API DATA ', secondData);
            }) 
          }
          else {
            fetch(nestedUrl, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
              },
              body: nestedPayload
            })
            .then(response => response.json())
            .then(postResponseData => {
              // Handle the data from the POST request
              console.log(postResponseData);
              actionsParams.nestedResponse ? that.setVariable(actionsParams.nestedResponse, postResponseData) : console.log('Nested POST API DATA ', postResponseData);
            })
            .catch(error => {
              console.error('Error:', error);
            });
          }
        }
      }, error => {
        console.log('Error:', error);
        if(actionsParams.failureMessage) {
            document.body.innerHTML += `<div id="toast-danger" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
            <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
                <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                </svg>
                <span class="sr-only">Error icon</span>
            </div>
            <div class="ml-3 text-sm font-normal">${actionsParams.failureMessage}</div>
          </div>`;
          setTimeout(() => {
            document.querySelector('#toast-danger').remove();
          }, 3000);
        }
      })
  }

  public async DELETE_API(elementID, event, actionsParams) {
    const elmntD = document.getElementById(event?.target?.id)
    const dataIDD = elmntD?.getAttribute('data-id')
    if(actionsParams.url){
      actionsParams['uri'] = actionsParams.url;
    }
    var uriii = this.replaceVariables(actionsParams.uri, { ...this.GLOBAL_VARIABLES, elementID: (event.target.id) ? event.target.id : elementID, dataID: dataIDD });
    let parsedPayloadd = await this.replaceWithBehaviorSubjectValues(actionsParams.payload)
    //const bodyy = (parsedPayloadd) ? JSON.parse(parsedPayloadd) : null;
    const tokenn = actionsParams.token;
    var that = this;
    fetch(uriii, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + tokenn
      },
      body: parsedPayloadd
    }).then(response => response.json()).then(data => {
      console.log('Success:', data);
      if(actionsParams.successMessage) {
          document.body.innerHTML += `<div id="toast-success" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 bg-green-100 rounded-lg dark:bg-green-800 dark:text-green-200">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
              </svg>
              <span class="sr-only">Check icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">${actionsParams.successMessage}</div>
        </div>`;
        setTimeout(() => {
          document.querySelector('#toast-success').remove();
        }, 3000);
      }
      actionsParams.saveResponse ? that.setVariable(actionsParams.saveResponse, data) : console.log('No Save Varibale to save API Data');
    }, error => {
      console.log('Error:', error);
      if(actionsParams.failureMessage) {
          document.body.innerHTML += `<div id="toast-danger" style="position: absolute; left: 50%; transform: translate(-50%, -50%); top: 5%;" class="flex items-center w-auto p-4 mb-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800" role="alert">
          <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-red-500 bg-red-100 rounded-lg dark:bg-red-800 dark:text-red-200">
              <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
              </svg>
              <span class="sr-only">Error icon</span>
          </div>
          <div class="ml-3 text-sm font-normal">${actionsParams.failureMessage}</div>
        </div>`;
        setTimeout(() => {
          document.querySelector('#toast-danger').remove();
        }, 3000);
      }
    })
  }

  public SEND_FILE(elementID, event, actionsParams) {
    var urii = this.replaceVariables(actionsParams.uri, { ...this.GLOBAL_VARIABLES, elementID: (event?.target.id) ? event?.target.id : elementID });
    if(actionsParams.payload) {

    } else if(actionsParams.filePath) {
        fetch(actionsParams.filePath)
        .then(response => response.blob())
        .then(blob => {
          console.log('File received:', blob);
            const formData = new FormData();
            formData.append('file', blob);
            if(formData) {
                fetch(urii, {
                method: 'POST',
                body: formData
                })
                .then(response => response.json())
                .then(data => {
                    // alert("file upload sucess")
                    this.sendLogs('File Upload Sucess !')
                    console.log(data);
                })
                .catch(error => {
                    console.error('Error:', error);
                });
            }
        })
        .catch(error => {
          // Handle any errors that occurred during the request
          console.error('Error:', error);
        });
    };
  }

  
  public MULTI_FILTER(elementID, event, actionsParams) {
    ////adding observable to filter Variables
    console.log(actionsParams);
    function getOrCreateBehaviorSubject(queryparams, globalVariable) {
      if (!globalVariable[queryparams]) {
        globalVariable[queryparams] = new BehaviorSubject({ value: 'initialValue' });
      }
      return globalVariable[queryparams];
    }
    let conditionsArray = JSON.parse(actionsParams.filterarray);  ////parsing array of MultiCheckbox Variables for which observables should be attached.
    const filteredDataVariable = this.GLOBAL_VARIABLES[actionsParams.setdatavariable];  /////variable in which filtered data should be stored
    const getDataVariable = this.GLOBAL_VARIABLES[actionsParams.getdatavariable];   /////variable in which complete data is stored
    const filterationType=actionsParams.filterationtype || 'and';   /////type of filteration to be done ie, OR or AND.
    if(event!==undefined && event.handleObj.type==="click"){         ////for click kind of event there is no need for subscription to variables as filteration should be done after clicking, say button.
      conditionsArray.forEach((condition)=>{
        //getting the complete data
        const data = getDataVariable.getValue().value;
        //boolean for breaking reduce statement
        let continueReduce=true;
        ////logic for filtering the data....
        const filteredData = conditionsArray.reduce((filtered, condition) => {
          if(!continueReduce){
            return filtered;

          }
          // function handle filter conditin of both OR and AND condition
          function actualFilter(filterConditions:any){
            if (filterConditions.length > 0) {
              return filtered.filter(item =>
                filterConditions.some(filter => {
                  if(Array.isArray(item[filter.key])){
                    //if value in the data is of type array we will find weather the filter value is present in array or not
                    return item[filter.key].find(filter.value);
                  }
                  else{
                    return item[filter.key] === filter.value;
                  }
                })
              );
            }
          }
          const filterConditions = this.GLOBAL_VARIABLES[condition].getValue().value;
          if (Array.isArray(filterConditions)) {
            // Check if it's a 1D array, In the case of check box filteration
            if (Array.isArray(filterConditions[0]) === false) {
              if(filterationType==='and'){
                let data=actualFilter(filterConditions);
                return data;
              }
              else if(filterationType==='or'){
                let wholeArray=[];
                conditionsArray.forEach((val,ind)=>{
                  let cond=this.GLOBAL_VARIABLES[val].getValue().value;
                  wholeArray=[...wholeArray,cond];
                })
                let data=actualFilter(wholeArray);
                continueReduce=false;
                return data;
              }
            
               
            } 
         }
         else if (typeof filterConditions === 'object') {
          // It's an object  in the case of looper row filteration
          // Handle the case where filterConditions is an object
          let map=new Map();
          let dataAfterFilteration=[];
          for (const key in filterConditions) {
            if (filterConditions.hasOwnProperty(key)) {
              const mapKey=key.split('#$#')[key.split('#$#').length - 2];
              const mapValue=key.split('#$#')[key.split('#$#').length - 1];
              if(map.has(mapKey)){
                map.set(mapKey,[...map.get(mapKey),{ key: mapKey, value: mapValue }])
              }
              else{
                map.set(mapKey,[{ key: mapKey, value: mapValue }])
              }
            }
          }
         if(filterationType==='and'){
          map.forEach((value, key) => {
            filtered=actualFilter(value);
          });
         }
         else if(filterationType==='or'){
          let data=[];
           map.forEach((value, key) => {
            data=[...data,...value]
          });
          return actualFilter(data);
         }
      }
  
            
  
          return filtered;
        }, data);
       /////setting the data to filteredData Variable to render the UI with filtered Data...
        const newFilteredData = filteredDataVariable.getValue();
        newFilteredData.value = filteredData;
        filteredDataVariable.next(newFilteredData);
      })
    }
    else{
      conditionsArray.forEach((condition) => {
        const observable = getOrCreateBehaviorSubject(condition, this.GLOBAL_VARIABLES);
         //////subscription for every observable and what should be done while filter criteria is changed.
        const subscription = observable.subscribe(updatedVariable => {
          const data = getDataVariable.getValue().value;
          ////logic for filtering the data....
          const filteredData = conditionsArray.reduce((filtered, condition) => {
            function actualFilter(filterConditions:any){
              if (filterConditions.length > 0) {
                return filtered.filter(item =>
                  filterConditions.some(filter => item[filter.key] === filter.value)
                );
              }
            }
            const filterConditions = this.GLOBAL_VARIABLES[condition].getValue().value;
            if (Array.isArray(filterConditions)) {
              // Check if it's a 1D array
              if (Array.isArray(filterConditions[0]) === false) {
                let data=actualFilter(filterConditions);
                filtered=data;
                 
              } 
           }
            return filtered;
          }, data);
         /////setting the data to filteredData Variable to render the UI with filtered Data...
          const newFilteredData = filteredDataVariable.getValue();
          newFilteredData.value = filteredData;
          filteredDataVariable.next(newFilteredData);
        });
      });
    }
    }
  public REFRESH_VARIABLE(elementID,event,actionsParams){
    if(actionsParams?.variableName!==undefined){
      let variable=this.GLOBAL_VARIABLES[actionsParams.variableName];
     const newData = variable.getValue();
         variable.next(variable.getValue());

    }
    
  }
  //Conditional rendering as a event for different cases for example with and without dynamic data
  public CONDITIONAL_RENDERING(elementID, event, actionsParams) {
    const elements_List = actionsParams.elements ? JSON.parse(actionsParams.elements): [];
    switch(actionsParams.dataType) {
      case 'Variable':
      this.conditionalVariable(elementID, actionsParams);
      break;
      case 'Dynamic':
      this.conditionalDynamicData(elementID, actionsParams);
      break;     
      default:
      this.conditionalData(actionsParams, elements_List);
    }
  
  }
 
  // variable within actionsParams with conditional inputs
  public conditionalVariable(elementID: any, actionsParams: any) {
    const conditional_variable = actionsParams.variable;
    var parentEle = document.getElementById(actionsParams?.parent);
    var ElementType = actionsParams.elementType? actionsParams.elementType.toLowerCase(): 'div';
    var attr = actionsParams.elementAttributes? JSON.parse(actionsParams.elementAttributes): [];
    var sel_ele: HTMLElement = document.getElementById(elementID); 

    const act_variable = this.GLOBAL_VARIABLES[conditional_variable];
    if(act_variable) {
      act_variable.subscribe((value) => {
        console.log(value);
        var val_data = typeof(value.value) === 'string'? JSON.parse(value.value): value.value;
        val_data.forEach((val: any)=> {
          ElementType = val.elementType? val.elementType.toLowerCase(): ElementType;
          var new_ele = document.createElement(ElementType);
          new_ele.style = val.style? val.style: 'font-size: 12px';
          if(attr.length > 0) {
            attr.forEach(attributeObj => {
              // Extract the key and value from each object
              const [key, value] = Object.entries(attributeObj)[0];
              // Check if the key and value are present
              if (key && typeof(value) == 'string') {
                sel_ele.setAttribute(key, value);
              }
            });
          };
          new_ele.innerText = val.value;
          parentEle.appendChild(new_ele);
        });
      });
    }
    else {
      attr.forEach(attributeObj => {
        // Extract the key and value from each object
        const [key, value] = Object.entries(attributeObj)[0];
        // Check if the key and value are present
        if (key && typeof(value) == 'string') {
          sel_ele.setAttribute(key, value);
        }
      });
    } 
  }

  // dynamic data within actionsParams with conditional inputs
  public conditionalDynamicData(elementID: any, actionsParams: any) {
    let entities = [];
    let token = actionsParams.token;
    var parentEle = document.getElementById(actionsParams?.parent);
    var ElementType = actionsParams.elementType? actionsParams.elementType.toLowerCase(): 'div';
    var attr = actionsParams.elementAttributes? JSON.parse(actionsParams.elementAttributes): [];
    fetch(actionsParams.url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
      }).then(response => response.json()).then(data => {
      entities = data.model.entities? data.model.entities: data;
      entities.forEach((val: any)=> {
        ElementType = val.elementType? val.elementType.toLowerCase(): ElementType;
        var new_ele = document.createElement(ElementType);
        new_ele.style = val.style? val.style: 'font-size: 12px';
        if(attr.length > 0) {
          attr.forEach(attributeObj => {
            // Extract the key and value from each object
            const [key, value] = Object.entries(attributeObj)[0];
            // Check if the key and value are present
            if (key && typeof(value) == 'string') {
              new_ele.setAttribute(key, value);
            }
          });
        };
        new_ele.innerText = val.value;
        parentEle.appendChild(new_ele);
      });
    });
  
  }

  // custom operations based on parent element.
  public conditionalData(actionsParams: any, elems: any) {  
    const parent = document.getElementById(actionsParams.parent);
    var attr = actionsParams.elementAttributes? JSON.parse(actionsParams.elementAttributes): [];
    const childElems = parent?.children;
    if((actionsParams.operation === 'Background Color' || actionsParams.operation === 'Color') && actionsParams.operationType == 'Alternate'){
     var count = 0; 
     const colors = JSON.parse(actionsParams.colors);
     for(let i=0; i<=childElems.length; i++) {
      const child = childElems[i] as HTMLElement;
      if(child) {
        if(actionsParams.operation === 'Background Color'){
          child.style.backgroundColor = colors[count];
        }
        if(actionsParams.operation === 'Color'){
        child.style.color = colors[count];
        }
        count = colors.length - 1 == count ? 0 : count + 1;
      }
      else {
        return;
      }
    }
    }
    else if((actionsParams.operation === 'Background Color' || actionsParams.operation === 'Color') && actionsParams.operationType == 'Pos/Neg') {
      const two_colors = JSON.parse(actionsParams.colors);
      for(let i=0; i<=childElems.length; i++) {
        const new_child = childElems[i] as HTMLElement;
        if(new_child) {
          if(actionsParams.operation === 'Background Color'){
            if(new_child.innerText.includes('-')){
              new_child.style.backgroundColor = two_colors[0];
            }
            else {
              new_child.style.backgroundColor = two_colors[1];
            }
          }
          if(actionsParams.operation === 'Color'){
            if(new_child.innerText.includes('-')){
              new_child.style.backgroundColor = two_colors[0];
            }
            else {
              new_child.style.backgroundColor = two_colors[1];
            }
          }
        }
        else {
          return;
        }
      }
    }
    else if ((actionsParams.operation === 'Background Color' || actionsParams.operation === 'Color') && actionsParams.operationType == 'Multiple') {
      const multiple_colors = JSON.parse(actionsParams.colors);
      const colorsLength = multiple_colors.length;
      for (let i = 0; i <= childElems.length; i++) {
        const new_child = childElems[i] as HTMLElement;
        if (new_child) {
          if (actionsParams.operation === 'Background Color') {
            new_child.style.backgroundColor = multiple_colors[i % colorsLength];
          }
          if (actionsParams.operation === 'Color') {
            new_child.style.backgroundColor = multiple_colors[i % colorsLength];
          }
        } else {
          return;
        }
      }
    }
    else if(actionsParams.operation === 'Toggle' && actionsParams.operationType == 'Multiple') {
      elems?.forEach((ele: any) => 
      {
        $(`[id^="${ele}"]`).toggle();
      })
    }
    else if(actionsParams.operation === 'Show' && actionsParams.operationType == 'Multiple') {
      elems?.forEach((ele: any) => 
      {
        $(`[id^="${ele}"]`).show();
      })
    }
    else if(actionsParams.operation === 'Hide' && actionsParams.operationType == 'Multiple') {
      elems?.forEach((ele: any) => 
      {
        $(`[id^="${ele}"]`).hide();
      })
    }
    else if(attr.length > 0 && actionsParams.operationType == 'Multiple') {
      elems?.forEach((ele): any => {
        attr.forEach((attributeObj): any => {
          const [key, value] = Object.entries(attributeObj)[0];
          if (key && typeof(value) == 'string') {
            document.getElementById(ele).setAttribute(key, value);
          }
        });
      });
    }
  }

  public async HandleBAppActions(baEvent, event?) {
    const baAppActions = baEvent.actions;
    const elementID = baEvent?.elementID;
    if (baAppActions) {
      const newStateMap = new Map();
      for(const baAction of baAppActions) {
        const actionsParams = baAction.actionParams;
        // if(actionsParams.showLoader){
        //   this.showLoader();
        //   console.log(baAction.actionType, "running handler for");
        // }
        const handler = this.actionHandlers[baAction.actionType];
        if(handler) {
          await handler.bind(this)(elementID, event, actionsParams, newStateMap);
        } 
        console.log(baAction.actionType, "handler completed");
      }
      this.contentsMap = newStateMap;
    }

  }

  public async filterVariable(elementId:any,params: any) {

    console.log('********',elementId,'$$$$$$$$$',params);
    const el=document.getElementById(`${elementId}`);
    if(params.htmlKey && params.filterKey && params.outputKey){
      if(el!==null){
      try{
        let value=el[params.htmlKey];
        let arr=this.GLOBAL_VARIABLES[params.filterSource]?await this.GLOBAL_VARIABLES[params.filterSource].getValue().value:[];
        let filterArray=[];
        for(let i=0;i<arr.length;i++){
          if(arr[i][params.filterKey]===value){
            filterArray=arr[i][params.outputKey];
          }
        }
        console.log('filterArray',filterArray);
        this.setVariable(params.resultVariable, filterArray);
      }
      catch(e){
        console.log('filterVariable',e);
      }
      }
    }
    else{
      let parsedPayload = await this.replaceWithBehaviorSubjectValues(params.filterPayload)
      parsedPayload = JSON.parse(parsedPayload)
  
      console.log("parsedPayload", parsedPayload)
  
      const sourceVariable = this.GLOBAL_VARIABLES[params.filterSource]
        ? await this.GLOBAL_VARIABLES[params.filterSource].getValue()
        : { value: [] };
  
      console.log("sourceVariable", sourceVariable)
  
      const values = sourceVariable.value;
      console.log("values", values)
  
      let filteredData = filter(values, parsedPayload);
      console.log("filteredData", filteredData)
      
      filteredData = (params?.resultPath && params?.resultPath != '') ? get(filteredData, params?.resultPath) : filteredData
  
      console.log("result", filteredData)
      this.setVariable(params.resultVariable, filteredData)
    }
    


  }

  public async replaceWithBehaviorSubjectValues22(inputString: string) {
    console.log("Input string: ", inputString);

    const regex = /\$_[A-Z]+/g;
    const matches = inputString.match(regex) || []; // Find all matches

    // Resolve all promises
    const replacements = await Promise.all(
      matches.map(async (match) => {
        const variableName = match.substring(2);
        console.log("variableName", variableName);
        console.log("variableValue", this.GLOBAL_VARIABLES[variableName]);
  
        return this.GLOBAL_VARIABLES[variableName]
          ? await this.GLOBAL_VARIABLES[variableName].getValue()
          : match;
      })
    );
    console.log("Replacements", replacements)

    // Replace all instances in the string
    matches.forEach((match, index) => {
      inputString = inputString.replace(match, `"${replacements[index].value}"` );
    });

    console.log("inputString", inputString)

    return inputString;
  }

  public async replaceWithBehaviorSubjectValues(inputString: string) {
    console.log("inputString", inputString);
    // const regex = /\$_[A-Za-z]+/g;
    const regex = /\$_[A-Za-z]+\w*(\.[A-Za-z_]+|\[\d+\])*/g;
    // const regex = /^\$_[A-Za-z]+\.[A-Za-z_]+|\[\d+\])*/g;
    const matches = inputString.match(regex) || []; // Find all matches
  
    // Resolve all promises
    const replacements = await Promise.all(
      matches.map(async (match) => {
        const variablePath = match.substring(2);
        const value = this.getVariableByPath(variablePath);
        return value !== undefined ? value : match;
        // const variableName = match.substring(2);
        // return this.GLOBAL_VARIABLES[variableName]
        //   ? await this.GLOBAL_VARIABLES[variableName].getValue()
        //   : match;
      })
    );
    console.log("Replacements", replacements);
  
    // Replace all instances in the string
    matches.forEach((match, index) => {
      let vvalue = replacements[index]
      // if (typeof vvalue === 'object' && vvalue !== null) {
      //   vvalue = JSON.stringify(vvalue)
      //   inputString = inputString.replace(match, `${vvalue}`);
      // } else {
      //   inputString = inputString.replace(match, `"${vvalue}"`);
      // }

      if (typeof vvalue === 'object' && vvalue !== null) {
        vvalue = JSON.stringify(vvalue);
        inputString = inputString.replace(`"${match}"`, `${vvalue}`);
      } else {
        if (inputString.includes(`"${match}"`)) {
          inputString = inputString.replace(`"${match}"`, `"${vvalue}"`);
        } else {
          inputString = inputString.replace(match, `${vvalue}`);
        }
      }
    });
  
    console.log("inputString", inputString);
  
    return inputString;
  }

  setVariable(variableName: any, variableValue: any) {

    let testVariableSubject = this.GLOBAL_VARIABLES[variableName];
    function parseString(input:string) {
      try {
        const parsedValue = JSON.parse(input);
    
        if (Array.isArray(parsedValue)) {
          return true;
        } else if (typeof parsedValue === 'object' && parsedValue !== null) {
          return true;
        } else {
         return false;
        }
    
      } catch (error) {
        return false;
      }
    }

    if(testVariableSubject){
      let updatedVariables = testVariableSubject.getValue();
      let isParsedValue=parseString(variableValue)
      if(isParsedValue){
        updatedVariables.value = JSON.parse(variableValue);
      }
      else{
        updatedVariables.value = variableValue;
      }
      testVariableSubject.next(updatedVariables);
    }

  }

  public getVariable(variableName: any) {
    return this.GLOBAL_VARIABLES[variableName];
  }

  getVariableByPath(variablePath: string) {
    // Split the path into the variable name and the rest of the path
    const pathParts = variablePath.split('.');
    const variableName = pathParts.shift();
    const restOfPath = pathParts.join('.');
  
    // Look up the variable in this.GLOBAL_VARIABLES
    let current = this.GLOBAL_VARIABLES[variableName];
    if (!current) {
      return undefined;
    }
  
    // If the variable is a BehaviorSubject, get its current value
    if (current instanceof BehaviorSubject) {
      current = current.getValue().value;
      if(current?.model?.entities) {
        current = current.model.entities;
      }
    }
  
    // If there's no more path to process, return the value
    if (!restOfPath) {
      return current;
    }
  
    // Split the rest of the path into keys
    let keys = restOfPath.replace(/\[(\w+)\]/g, '.$1').replace(/^\./, '').split('.');
  
    // Drill down into the variable's value using the rest of the path
    for(let key of keys) {
      if (!current || !current.hasOwnProperty(key)) {
        return undefined;
      }
      current = current[key];
    }
  
    return current;
  }

  // Function to replace variables in the input string with their corresponding values
  // variables: an object containing the variables and their values
  // Returns the modified input string
  public replaceVariables(inputString: string, variables: Variables): string {
    for (const [key, value] of Object.entries(variables)) {
      if(key!== 'dataID' && key!== 'elementID') {
      const placeholderPattern = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
      inputString = inputString.replace(placeholderPattern, `${value?.value?.value}`);
      }
    }
    console.log('inputString', inputString)
    return inputString;
  }

  public replaceByGlobalVariables(inputString: string): string {
    for (const [key, value] of Object.entries(this.GLOBAL_VARIABLES)) {
      const placeholderPattern = new RegExp(`{{\\s*${key}\\s*}}`, 'g');
      inputString = inputString.replace(placeholderPattern, value);
    }
    console.log('inputString', inputString)
    return inputString;
  }

  getDataLakeSource = async (sourceURL, offlineURL, filters, elID, recordsPerFetch, recordToFetch, fetchAllRecords) => {
    const data = await cache.getData(sourceURL, offlineURL, filters, elID, recordsPerFetch, recordToFetch, fetchAllRecords);
    return data;
  }

  getValueByPath = (obj, path) => {
    if (!obj || !path) {
      return undefined;
    }

    const keys = path.split('.');
    let value = obj;

    for (let key of keys) {
      const match = key.match(/^(\w+)(\[(\d+)\])?$/);
      if (match) {
        const propName = match[1];
        const index = match[3] ? parseInt(match[3]) : undefined;
        value = value[propName];
        if (index !== undefined && Array.isArray(value)) {
          value = value[index];
        }
      } else {
        return undefined;
      }
      if (value === undefined) {
        return undefined;
      }
    }

    return value;
  }

  public loadTemplate(appName, actionType) {
    return new Promise<any>((resolve, reject) => {
      try {
        var text = '';
        var xhttp = new XMLHttpRequest();
        console.log("./" + appName + "/" + appName + ".html")
        xhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var htmlResponse = xhttp.responseText || '';
            console.log(htmlResponse);
            if (htmlResponse != '') {

              var _template = htmlResponse;
              var template = $.parseHTML(_template);
              var final = find($(template).find("#vue-app").prev, ['id', 'vue-app']);
              var templateElements = $(final).html();
              text += templateElements;
              text = `<div class="${appName}">${templateElements}</div>`;
              console.log(text)

              $(document).ready(function () {
                if (actionType) {
                  if (actionType == 'INIT' || actionType == 'OVERWRITE') {
                    // $("#vue-app").html(text);
                  } else if (actionType == 'OVERLAY') {
                    // $("#vue-app").append(text);
                  }
                }
                document.getElementById('vue-app').style.visibility = 'visible';
                document.getElementById('vue-app').style.overflowY = 'auto';
                document.getElementById('vue-app').style.position = 'unset';
              });

              resolve(text)
            }
          }
        }
        xhttp.open("GET", "../" + appName + "/" + appName + ".html", true);
        xhttp.send();
      } catch (e) {
        console.log(e);
        reject(e)
      }
    })

  }

  public async loadConfig(appName) {
    return new Promise<any>((resolve, reject) => {
      try {
        var yhttp = new XMLHttpRequest();
        yhttp.onreadystatechange = function () {
          if (this.readyState == 4 && this.status == 200) {
            var response = yhttp.responseText || '';
            console.log(response);
            if (response != '') {
              var config = JSON.parse(response);
              console.log(config);
              resolve(config);
            }
          }
        }
        yhttp.open("GET", "../" + appName + "/config.json", true);
        yhttp.send();
      } catch (e) {
        reject(e)
      }
    });
  }

  public async initMBApp(bind_to, appId, tenantId, version, refresh, value, actionType, appName) {

    const config = await this.loadConfig(appName);
    console.log(config);

    const template = await this.loadTemplate(appName, actionType);
    console.log(template);

    const baApp = {
      'baAppConfig': {
        'id': appId,
        'config': config,
        'files': [
          {
            'path': appName + '.html',
            'type': 'template',
            'content': {
              'body': template,
              'post': '',
              'pre': ''
            }
          }
        ]
      }
    }
    console.log(baApp);
    // this.renderComponents(baApp.baAppConfig.config.renderComponents);

    this.BALoaded();
    await this.MBAppData(bind_to, baApp, tenantId, actionType);
    if (refresh && refresh == 'true') {
      setInterval(() => {
        let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
        this.renderComponents(response1);
      }, value);
    }

  }

  public addScripts = (file = []) => {
    let dynamicScripts = ['https://cdn.jsdelivr.net/npm/vue@2.6.14', 'https://cdnjs.cloudflare.com/ajax/libs/echarts/5.3.1/echarts.min.js'];
    // file = file || dynamicScripts;
    try {
      let ele: any = document.createElement('div');
      ele.setAttribute('id', 'tempEchart');
      document.body.appendChild(ele);
      // @ts-ignore
      echarts.init(document.getElementById('tempEchart'));
      dynamicScripts.pop();
      document.body.removeChild(document.getElementById('tempEchart'));
    } catch (e) {
      document.body.removeChild(document.getElementById('tempEchart'));
    }
    for (let i = 0; i < file.length; i++) {
      if (file[i].path == "../js/kepler.js") {
        dynamicScripts.push('../js/kepler.js');
        // let myScript = document.createElement("script");
        // myScript.setAttribute("src", "./js/kepler.js");
        // document.body.appendChild(myScript);
      }
    }
    if (file.length == 0) {
      dynamicScripts.push('../js/kepler.js');
    }
    for (let i = 0; i < dynamicScripts.length; i++) {
      let myScript = document.createElement("script");
      myScript.setAttribute("src", dynamicScripts[i]);
      document.body.appendChild(myScript);
    }
  }

  public initUntaggedBApp(bind_to, appId, tenantId, refresh, value) {

    let baApp = null
    CommonService.updateUserInfo({
      "appId": appId,
      "appid": appId
    })
    this.DataLoaders({
      type: 'GET',
      url: endpoints.BA_STORE_SERVICE_URL(tenantId, appId)
    })
      .then((result: any) => {
        baApp = JSON.parse(result);
        this.BAAppData = baApp;
        this.BAAppData.baAppConfig.bbandEntryPageUrl = this.BAAppData.baAppConfig.bbandEntryPageUrl || '';
        this.setBroadBandURL(this.BAAppData.baAppConfig.bbandEntryPageUrl);
        this.BALoaded();
        this.BAppData(bind_to, baApp, tenantId);
        // this.USER_INFO.tenantId = this.params.tenantId;
        let appName = baApp.baAppConfig.app_name;
        document.title = appName;
        let analyticsSchema = baApp.config.analyticsSchema || '6139dce6e8d3cd0001430b74';
        // this.USER_INFO.appName = appName;
        // this.USER_INFO.analyticsSchema = analyticsSchema;
        CommonService.updateUserInfo({
          "tenantId": this.params.tenantId,
          "appName": appName,
          "analyticsSchema": analyticsSchema
        })
        let BAOpenObj = {
          "eventType": "launch"
        }
        this.analytics.sendAnalytics('open', BAOpenObj, this.USER_INFO);
        if (refresh && refresh >= 5) {
          setInterval(() => {

            let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
            this.renderComponents(response1);
          }, refresh * 1000);
        } else if (this.BAAppData.baAppConfig.config.refresh) {
          setInterval(() => {

            let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
            this.renderComponents(response1);
          }, this.BAAppData.baAppConfig.config.refresh * 1000);
        }
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public resizePlayerScreen(screen) {

    switch (screen) {
      case 'FullScreen':
        try {
          // @ts-ignore
          System.resizePlayer(100);
        } catch (e) {
          console.info(e);
        }
        break;
      case 'HalfScreen':
        try {
          // @ts-ignore
          System.resizePlayer(50);
        } catch (e) {
          console.error(e);
        }
        break;
      case 'QuarterScreen':
        try {
          // @ts-ignore
          System.resizePlayer(25);
        } catch (e) {
          console.error(e);
        }
        break;
      default:
        try {
          // @ts-ignore
          System.resizePlayer(100);
        } catch (e) {
          console.error(e);
        }
        break;
    }
  }

  public formatDataLabels(data) {
    let obj = {};
    for (let item in data) {
      let key = Object.keys(data[item])[0];
      let value = Object.values(data[item])[0];
      obj[key] = value
    }
    return obj;
  }

  public refreshBA() {

    let ele = this.BAAppData.baAppConfig.config.dataSource;
    let dynamicData: any = this.formatResponse1(ele);
    let updatedDatasources: any = this.BAAppData.baAppConfig.config.updatedDatasources || '';
    if (updatedDatasources != '' && Object.keys(updatedDatasources).length > 0) {
      let requestOptions: any = {
        type: updatedDatasources.source.method.toUpperCase(),
        url: updatedDatasources.source.url,
        headers: updatedDatasources.source.headers || {},
        payload: updatedDatasources.source.body
      };
      requestOptions.headers = Object.assign({ 'Content-Type': 'application/json' }, requestOptions.headers)
      this.dataCount++;
      dataLoaders
        .processRequest(requestOptions)
        .then((result: any) => {
          try {
            result = JSON.parse(result) || '';
          } catch (e) {
          }
          this.jsonObj['source'] = result;
          this.responseCount++;
          if (this.dataCount == this.responseCount)
            this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        })
        .catch((err: any) => {
          if (dynamicData.source && dynamicData.source.id) {
            let Id = dynamicData.source.id
            let apiType = dynamicData.source.resource
            let type = 'source'
            this.dataCount++;
            this.getDataAttributes('', dynamicData.source, type, false)
          }
        })
    }
    if (dynamicData.custom) {
      let customDataSources = {};
      for (let key in dynamicData.custom) {
        customDataSources[dynamicData.custom[key].key] = dynamicData.custom[key].url;
        this.dataCount++;
      }
      dynamicData.custom = customDataSources;
      for (let key in dynamicData.custom) {
        this.getDataForCustomSources(key, dynamicData.custom[key]);
      }
    }
    // Latest data sources implementation - Ram
    let getDataSources = Object.keys(dynamicData);
    for (let key in getDataSources) {
      if (getDataSources[key] != 'custom') {
        if (dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
          this.dataCount++;
          this.getDataAttributes('', dynamicData[getDataSources[key]], getDataSources[key], false)
        }
      }
    }
  }

  public BAppData(bind_to, baApp, tenantId) {
    this.USER_INFO = CommonService.getUserInfo();
    this.APP_INFO.appId = baApp.id || '';
    this.APP_INFO.appName = baApp.baAppName || '';
    this.APP_INFO.version = baApp.version || '';


    this.BAAppData = baApp;
    baApp.baAppConfig.files = baApp.baAppConfig.files || '';
    // this.addScripts(baApp.baAppConfig.files);
    tenantId = tenantId || this.USER_INFO.tenantId
    let bgColor = baApp.baAppConfig.config.canvasBackgroundColor || 'transparent';
    document.body.style.backgroundColor = bgColor;
    if (bind_to) {
      document.getElementById(bind_to).style.backgroundColor = bgColor;
    }
    baApp.baAppConfig.config.resizePlayer = baApp.baAppConfig.config.resizePlayer || 'FullScreen';


    this.resizePlayerScreen(baApp.baAppConfig.config.resizePlayer);

    // alert(JSON.stringify(baApp.baAppConfig.config));
    // IterationDataLabels
    let labels = baApp.baAppConfig.config.iterationDataLabels || [];
    this.iterationDataLabels = this.formatDataLabels(labels);
    //Data Source
    let ele = baApp.baAppConfig.config.dataSource;
    let dynamicData: any = this.formatResponse1(ele);
    this.baDataConfig = {
      'bind_to': bind_to,
      'baApp': baApp
    };


    let updatedDatasources: any = baApp.baAppConfig.config.updatedDatasources || '';
    if (updatedDatasources != '' && Object.keys(updatedDatasources).length > 0) {

      for (let i = 0; i < Object.keys(updatedDatasources).length; i++) {
        // 
        let groupName = Object.keys(updatedDatasources)[i];
        let groupData = updatedDatasources[Object.keys(updatedDatasources)[i]];
        if (groupData && groupData.url && groupData.method) {
          let requestObj: any = {
            type: groupData.method.toUpperCase(),
            url: groupData.url,
            headers: groupData.headers || {},
            payload: groupData.body
          }
          requestObj.headers = Object.assign({ 'Content-Type': 'application/json' }, requestObj.headers);
          this.dataCount++;
          dataLoaders
            .processRequest(requestObj)
            .then((result: any) => {
              try {
                result = JSON.parse(result) || '';
              } catch (e) {
              }
              this.jsonObj[groupName] = result;
              this.responseCount++;
              if (this.dataCount == this.responseCount)
                this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
            })
            .catch((err: any) => {
              if (dynamicData.source && dynamicData.source.id) {
                let Id = dynamicData.source.id
                let apiType = dynamicData.source.resource
                let type = 'source'
                this.dataCount++;
                this.getDataAttributes(tenantId, dynamicData.source, type, false)
              }
            })
        }
      }
      // let requestOptions: any = {
      //   type: updatedDatasources.source.method.toUpperCase(),
      //   url: updatedDatasources.source.url,
      //   headers: updatedDatasources.source.headers || {},
      //   payload: updatedDatasources.source.body
      // };
      // requestOptions.headers = Object.assign({'Content-Type': 'application/json'}, requestOptions.headers);
      // this.dataCount++;
      // dataLoaders
      //   .processRequest(requestOptions)
      //   .then((result: any) => {
      //     try {
      //       result = JSON.parse(result) || '';
      //     } catch (e) {
      //     }
      //     this.jsonObj['source'] = result;
      //     this.responseCount++;
      //     if (this.dataCount == this.responseCount)
      //       this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
      //     })
      // .catch((err: any) => {
      //   if (dynamicData.source && dynamicData.source.id) {
      //     let Id = dynamicData.source.id
      //     let apiType = dynamicData.source.resource
      //     let type = 'source'
      //     this.dataCount++;
      //     this.getDataAttributes(tenantId, dynamicData.source, type)
      //   }
      // })
      // send source, requestOptions
    }
    // else if (dynamicData.source && dynamicData.source.id) {
    //   let Id = dynamicData.source.id
    //   let apiType = dynamicData.source.resource
    //   let type = 'source'
    //   this.dataCount++;
    //   this.getDataAttributes(tenantId, Id, type, apiType)
    // }
    // if (dynamicData.context && dynamicData.context.id) {
    //   let Id = dynamicData.context.id
    //   let apiType = dynamicData.context.resource
    //   let type = 'context'
    //   this.dataCount++;
    //   this.getDataAttributes(tenantId, Id, type, apiType)
    // }
    // if (dynamicData.target && dynamicData.target.id) {
    //   let Id = dynamicData.target.id
    //   let apiType = dynamicData.target.resource
    //   let type = 'target'
    //   this.dataCount++;
    //   this.getDataAttributes(tenantId, Id, type, apiType)
    // }
    if (dynamicData.custom) {
      let customDataSources = {};
      for (let key in dynamicData.custom) {
        customDataSources[dynamicData.custom[key].key] = dynamicData.custom[key].url;
        this.dataCount++;
      }
      dynamicData.custom = customDataSources;
      for (let key in dynamicData.custom) {
        this.getDataForCustomSources(key, dynamicData.custom[key]);
      }
    }

    // Latest data sources implementation - Ram
    let getDataSources = Object.keys(dynamicData);
    let updatedDatasources_key = Object.keys(updatedDatasources);
    for (let key in getDataSources) {
      if (updatedDatasources_key.length > 0) {
        for (let item in updatedDatasources_key) {
          if (updatedDatasources_key[item] == getDataSources[key]) {
            continue
          }
          else {
            if (getDataSources[key] != 'custom') {
              if (dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
                this.dataCount++;
                // need to change this later as datasources are now default 4 and if we add one it will be 5 
                var postFilters = false;
                if (getDataSources.length == 5) {
                  postFilters = true
                }
                this.getDataAttributes(tenantId, dynamicData[getDataSources[key]], getDataSources[key], postFilters)
              }
              // if(dynamicData[getDataSources[key]].filters && dynamicData[getDataSources[key]].filters.length > 0) {
              //   let finalFilter = {}
              //   for(let item in dynamicData[getDataSources[key]].filters) {
              //     let filter = dynamicData[getDataSources[key]].filters[item];
              //     let filterKeys = Object.keys(filter);
              //     let filterKey = filterKeys[0];
              //     let filterValue = filter[filterKeys[0]];
              //     for(let key in filterKeys){
              //       if(filterKeys[key] == 'key' || filterKeys[key] == 'value') {
              //         let type = filter[filterKeys[key]]
              //         switch(type) {
              //           case "string":
              //             if(filterKeys[key] == 'key') {
              //               finalFilter[filterKey] = ''
              //             } else {
              //               finalFilter[filterKey] = filterValue;
              //             }
              //             
              //           break;
              //           case 'dynamicVariable':
              //             
              //           break;
              //           case 'clientAttribute':
              //             
              //           break;
              //           default:
              //             
              //         }
              //       }
              //     }
              //     
              //   }
              //   this.dataCount++;
              //   this.getFilterData(dynamicData[getDataSources[key]].id, finalFilter, dynamicData[getDataSources[key]].name, dynamicData[getDataSources[key]].resource)
              // }
            }
          }
        }
      } else {
        if (getDataSources[key] != 'custom') {
          if (dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
            this.dataCount++;
            var postFilters = false;
            if (getDataSources.length == 5) {
              postFilters = true
            }
            // checking same group
            if (this.groupIds.includes(dynamicData[getDataSources[key]].id)) {
              this.responseCount++;
              this.duplicateGroupName.push(getDataSources[key]);
            } else {
              this.getDataAttributes(tenantId, dynamicData[getDataSources[key]], getDataSources[key], postFilters)
            }
          }
        }
      }
    }
    // for(let key in getDataSources) {
    //   for(let item in updatedDatasources_key) {
    //     if(updatedDatasources_key[item] == ) {
    //       if(getDataSources[key] != 'custom') {
    //         if(dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
    //           this.dataCount++;
    //           this.getDataAttributes(tenantId, dynamicData[getDataSources[key]], getDataSources[key])
    //         }
    // if(dynamicData[getDataSources[key]].filters && dynamicData[getDataSources[key]].filters.length > 0) {
    //   let finalFilter = {}
    //   for(let item in dynamicData[getDataSources[key]].filters) {
    //     let filter = dynamicData[getDataSources[key]].filters[item];
    //     let filterKeys = Object.keys(filter);
    //     let filterKey = filterKeys[0];
    //     let filterValue = filter[filterKeys[0]];
    //     for(let key in filterKeys){
    //       if(filterKeys[key] == 'key' || filterKeys[key] == 'value') {
    //         let type = filter[filterKeys[key]]
    //         switch(type) {
    //           case "string":
    //             if(filterKeys[key] == 'key') {
    //               finalFilter[filterKey] = ''
    //             } else {
    //               finalFilter[filterKey] = filterValue;
    //             }
    //             
    //           break;
    //           case 'dynamicVariable':
    //             
    //           break;
    //           case 'clientAttribute':
    //             
    //           break;
    //           default:
    //             
    //         }
    //       }
    //     }
    //     
    //   }
    //   this.dataCount++;
    //   this.getFilterData(dynamicData[getDataSources[key]].id, finalFilter, dynamicData[getDataSources[key]].name, dynamicData[getDataSources[key]].resource)
    // }
    //       }
    //     }
    //   }
    // }
    if (this.dataCount == 0)
      this.renderDomWithData(bind_to, baApp);
    if (baApp.baAppConfig.config.animations) {
      setTimeout(() => {
        this.sendAnimationMessagToOO(baApp.baAppConfig.config.animations);
      }, 2000);
    }
  }

  async MBAppData(bind_to, baApp, tenantId, actionType) {
    console.log("MBAppData")
    this.USER_INFO = CommonService.getUserInfo();
    this.APP_INFO.appId = baApp.id || '';
    this.APP_INFO.appName = baApp.baAppName || '';
    this.APP_INFO.version = baApp.version || '';

    this.BAAppData = baApp;
    baApp.baAppConfig.files = baApp.baAppConfig.files || '';
    this.addScripts(baApp.baAppConfig.files);
    tenantId = tenantId || this.USER_INFO.tenantId
    let bgColor = baApp.baAppConfig.config.canvasBackgroundColor || 'transparent';
    document.body.style.backgroundColor = bgColor;

    if (bind_to) {
      console.log(bind_to);
      if (!document.getElementById(bind_to)) {
        bind_to = 'vue-app'
      }
      // document.getElementById(bind_to).style.backgroundColor = bgColor;
    }
    baApp.baAppConfig.config.resizePlayer = baApp.baAppConfig.config.resizePlayer || 'FullScreen';

    this.resizePlayerScreen(baApp.baAppConfig.config.resizePlayer);

    // alert(JSON.stringify(baApp.baAppConfig.config));
    // IterationDataLabels
    let labels = baApp.baAppConfig.config.iterationDataLabels || [];
    this.iterationDataLabels = this.formatDataLabels(labels);
    //Data Source
    let ele = baApp.baAppConfig.config.dataSource;
    let dynamicData: any = this.formatResponse1(ele);
    this.baDataConfig = {
      'bind_to': bind_to,
      'baApp': baApp
    };
    let updatedDatasources: any = baApp.baAppConfig.config.updatedDatasources || '';

    console.log(baApp, updatedDatasources != '' && Object.keys(updatedDatasources).length > 0)

    if (updatedDatasources != '' && Object.keys(updatedDatasources).length > 0) {
      for (let i = 0; i < Object.keys(updatedDatasources).length; i++) {
        let groupName = Object.keys(updatedDatasources)[i];
        let groupData = updatedDatasources[Object.keys(updatedDatasources)[i]];
        if (groupData && groupData.url && groupData.method) {
          let requestObj: any = {
            type: groupData.method.toUpperCase(),
            url: groupData.url,
            headers: groupData.headers || {},
            payload: groupData.body
          }
          requestObj.headers = Object.assign({ 'Content-Type': 'application/json' }, requestObj.headers);
          this.dataCount++;
          dataLoaders
            .processRequest(requestObj)
            .then((result: any) => {
              console.log(result)
              try {
                result = JSON.parse(result) || '';
              } catch (e) {
              }
              this.jsonObj[groupName] = result;
              this.responseCount++;
              if (this.dataCount == this.responseCount)
                this.renderDomWithData(bind_to, baApp, actionType);
            })
            .catch((err: any) => {
              console.log(err)
              if (dynamicData.source && dynamicData.source.id) {
                let Id = dynamicData.source.id
                let apiType = dynamicData.source.resource
                let type = 'source'
                this.dataCount++;
                this.getDataAttributes(tenantId, dynamicData.source, type, false)
              }
            })
        }
      }
    }

    if (dynamicData.custom) {
      let customDataSources = {};
      for (let key in dynamicData.custom) {
        customDataSources[dynamicData.custom[key].key] = dynamicData.custom[key].url;
        this.dataCount++;
      }
      dynamicData.custom = customDataSources;
      for (let key in dynamicData.custom) {
        this.getDataForCustomSources(key, dynamicData.custom[key]);
      }
    }

    // Latest data sources implementation - Ram
    let getDataSources = Object.keys(dynamicData);
    let updatedDatasources_key = Object.keys(updatedDatasources);
    for (let key in getDataSources) {
      if (updatedDatasources_key.length > 0) {
        for (let item in updatedDatasources_key) {
          if (updatedDatasources_key[item] == getDataSources[key]) {
            continue
          }
          else {
            if (getDataSources[key] != 'custom') {
              if (dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
                this.dataCount++;
                // need to change this later as datasources are now default 4 and if we add one it will be 5
                var postFilters = false;
                if (getDataSources.length == 5) {
                  postFilters = true
                }
                this.getDataAttributes(tenantId, dynamicData[getDataSources[key]], getDataSources[key], postFilters)
              }
            }
          }
        }
      } else {
        if (getDataSources[key] != 'custom') {
          if (dynamicData[getDataSources[key]].id && dynamicData[getDataSources[key]].resource) {
            this.dataCount++;
            var postFilters = false;
            if (getDataSources.length == 5) {
              postFilters = true
            }
            this.getDataAttributes(tenantId, dynamicData[getDataSources[key]], getDataSources[key], postFilters)
          }
        }
      }
    }
    if (this.dataCount == 0) {
      this.renderDomWithData(bind_to, baApp, actionType);
    }
    if (baApp.baAppConfig.config.animations) {
      setTimeout(() => {
        this.sendAnimationMessagToOO(baApp.baAppConfig.config.animations);
      }, 2000);
    }
  }

  public getFilterData(id, filter, groupName, type) {
    let obj = {
      url: endpoints.DATA_URL( this.params.tenantId, type, id),
      filter: filter,
      name: groupName,
      page: 0,
      size: 100
    }
    this.getFilteredData(obj)
  }

  public getFilteredData(obj) {
    let updatedUrl;
    if (obj.url.includes('?')) {
      updatedUrl = `${obj.url}&page=${obj.page}&size=${obj.size}`;
    } else {
      updatedUrl = `${obj.url}?page=${obj.page}&size=${obj.size}`;
    }
    let requestOptions: any = {
      type: 'POST',
      url: updatedUrl,
      payload: obj.filter,
      headers: {
        'content-type': 'application/json'
      }
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        result = JSON.parse(result);
        obj.name = obj.name.replace(/ /g, '_');
        this.jsonObj[obj.name] = result;
        if (obj.page == 0) {
          this.jsonObj[obj.name] = result;
          this.responseCount++;
          if (this.dataCount == this.responseCount)
            this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        } else {
          for (let item in result.model.entities) {
            this.jsonObj[obj.name].model.entities.push(result.model.entities[item])
          }
        }
        if (result.model.entities.length != 0 && result.model.entities.length == obj.size) {
          obj.page = ++obj.page;
          this.getFilteredData(obj)
        }
        // this.responseCount++
        // if (this.dataCount == this.responseCount)
        //   this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);

      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public getCompleteData(reqObj) {
    let url = reqObj.url || '';
    let type = reqObj.apiType || 'groups';
    let groupName = reqObj.type || '';
    let page = reqObj.page || 0;
    let size = reqObj.size || 100;
    let filters = reqObj.filters || [];
    let updatedUrl;
    if (url.includes('?')) {
      updatedUrl = `${url}&page=${page}&size=${size}`;
    } else {
      updatedUrl = `${url}?page=${page}&size=${size}`;
    }

    if (type == 'groups' || type == 'analytics') {
      this.DataLoaders({
        type: 'GET',
        url: updatedUrl
      }).then((result: any) => {
        result = JSON.parse(result) || '';
        groupName = groupName.replace(/ /g, '_');
        this.dataIterationResultCount++
        if (page == 0) {
          this.completeGroupsData[groupName] = result;
          // 
          // this.jsonObj[groupName] = result;
          this.responseCount++;
          this.checkFilters_renderDOM(filters, groupName);
        } else {
          for (let item in result.model.entities) {
            this.completeGroupsData[groupName].model.entities.push(result.model.entities[item])
            // Need to change this condition
            // for(let subitem in this.completeGroupsData) {
            //   let group = Object.keys(this.completeGroupsData[subitem])[0];
            //   if(group == groupName) {
            //     this.completeGroupsData[subitem][group].model.entities.push(result.model.entities[item])
            //   }
            // }
            // this.jsonObj[groupName].model.entities.push(result.model.entities[item])
          }
          // this.checkFilters_renderDOM(filters, groupName)
        }
        if (result.model.entities.length != 0 && result.model.entities.length == size) {
          page = ++page;
          let obj = {
            apiType: type,
            url: url,
            page: page,
            size: 100,
            type: groupName,
            filters: filters
          }
          this.dataIterationCount++
          this.getCompleteData(obj)
        } else {
          if (this.dataIterationCount == this.dataIterationResultCount) {
            this.eventsRendering(this.baDataConfig.baApp.baAppConfig.config.elements);
            this.conditionalRenderingComponent(this.baDataConfig.baApp);
            this.checkFilters_renderDOM(filters, groupName)
          }
        }
      })
        .catch(err => {
          console.log(err)
          this.responseCount++;
        })
    }
  }

  public checkFilters_renderDOM(filters, groupName, element = null) {
    var filteredData = {};
    let filter = {}
    if (filters.length > 0) {
      for (let item in filters) {
        Object.keys(filters[item]).forEach(key => {
          if (key == 'key' && filters[item][key] == 'string') {
            filter[Object.keys(filters[item])[0]] = ''
          }
          if (key == 'value' && filters[item][key] == 'string') {
            filter[Object.keys(filters[item])[0]] = filters[item][Object.keys(filters[item])[0]]
          }
          // item1.countryName
          // item1 = groupName.model.entities[0].countryName;
          if (key == 'value' && filters[item][key] == 'dynamicValue') {
            let val = filters[item][Object.keys(filters[item])[0]];
            let DynamicValue;
            if (val.includes('.')) {
              if (val) {
                let type = val.split('.').shift();
                if (type.includes('item')) {
                  if (this.iterationDataLabels[type]) {
                    val = val.replace(type, this.iterationDataLabels[type]);
                    let value = val.split('.');
                    value.shift();
                    value = value.join('.')
                    DynamicValue = this.processVariable(this.jsonObj[val.split('.').shift()], value);
                  }
                } else {
                  DynamicValue = this.processVariable(this.jsonObj[val.split('.').shift()], val);
                }
              }
              // let DynamicValue = this.processDynamicVariableData(this.jsonObj[val.split('.').shift()], val.split('.').pop());
              filter[Object.keys(filters[item])[0]] = DynamicValue;
            }
          }
          if (key == 'value' && filters[item][key] == 'configVariable') {
            let val = filters[item][Object.keys(filters[item])[0]];
            if (this.BAAppData && this.BAAppData.baAppConfig.config.additionalVariables) {
              val = this.BAAppData.baAppConfig.config.additionalVariables[val]
            } else {
              val = '';
            }
            filter[Object.keys(filters[item])[0]] = val;
          }
          if (key == 'value' && filters[item][key] == 'userVariable') {
            let val = filters[item][Object.keys(filters[item])[0]];
            val = this.USER_INFO[val] || '';
            filter[Object.keys(filters[item])[0]] = val;
          }
          if (key == 'value' && filters[item][key] == 'innerText') {
            if (element) {
              filter[Object.keys(filters[item])[0]] = element.innerText || element.innerHTML;
            } else {
              filter[Object.keys(filters[item])[0]] = ''
            }
          }
          if (key == 'value' && filters[item][key] == 'attributeValue') {
            if (element) {
              filter[Object.keys(filters[item])[0]] = element.getAttribute(Object.keys(filters[item])[0]) || '';
            } else {
              filter[Object.keys(filters[item])[0]] = ''
            }
          }
        })
      }
    }

    if (Object.keys(filter).length > 0) {
      let groups = Object.keys(this.completeGroupsData);
      for (let i = 0; i < groups.length; i++) {
        let key = groups[i];
        if (key == groupName) {
          let data = this.completeGroupsData[groupName];
          if (data && data.model.entities) {
            var groupData = Object.assign({}, this.completeGroupsData[groupName]);
            filteredData[groupName] = JSON.parse(JSON.stringify(this.completeGroupsData[groupName]));
            filteredData[groupName].model.entities = [];
            for (let i = 0; i < Object.keys(filter).length; i++) {
              let filterKey = Object.keys(filter)[i];
              if (i < 1) {
                for (let j = 0; j < data.model.entities.length; j++) {
                  if (data.model.entities[j][filterKey] == filter[filterKey]) {
                    filteredData[groupName].model.entities.push(data.model.entities[j])
                  }
                }
              } else {
                data = [...filteredData[groupName].model.entities]
                filteredData[groupName].model.entities = [];
                for (let j = 0; j < data.length; j++) {
                  if (data[j][filterKey] == filter[filterKey]) {
                    filteredData[groupName].model.entities.push(data[j])
                  }
                }
              }
            }
          }
        }
      }
      this.jsonObj[groupName] = filteredData[groupName];

    } else {
      this.jsonObj[groupName] = this.completeGroupsData[groupName];
    }

    if (this.dataCount == this.responseCount) {
      if (this.domRendered) {
        this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
      }
    }

    // if(this.MAP_REF){

    //   var markersData = this.jsonObj[groupName].model ? this.jsonObj[groupName].model.entities : this.jsonObj[groupName].entities;

    //   // displayType can be : Tooltip Or MapContent
    //   this.MAP_REF.highlightMarkers(markersData, 15, {style: "RED", displayType: "MapContent",  xSize: 35, ySize: 45})
    // }


    if (this.randomMapInstance) {
      if (this.MAP_CONFIG && this.MAP_CONFIG.highlightMarkersEffect && this.MAP_CONFIG.highlightMarkersEffect.enabled) {
        var markersData = this.jsonObj[groupName].model ? this.jsonObj[groupName].model.entities : this.jsonObj[groupName].entities;
        this.randomMapInstance.highlightMarkers(
          markersData,
          this.MAP_CONFIG.highlightMarkersEffect.zoomLevel, {
          style: this.MAP_CONFIG.highlightMarkersEffect.style.markerStyle,
          displayType: this.MAP_CONFIG.highlightMarkersEffect.style.displayContentType,
          xSize: this.MAP_CONFIG.highlightMarkersEffect.style.xIconSize,
          ySize: this.MAP_CONFIG.highlightMarkersEffect.style.yIconSize
        },
          this.MAP_CONFIG.highlightMarkersEffect
        )
      }

    }

  }

  public getDataForCustomSources(label, url) {
    this.DataLoaders({
      type: 'GET',
      url: `./${label}.json`
    }).then((result: any) => {
      try {
        result = JSON.parse(result) || '';
      } catch (e) {
        console.log(e)
        this.getJSONResponse(label, url);
        return;
      }
      this.jsonObj[label] = result;
      this.responseCount++;
      if (this.dataCount == this.responseCount)
        this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
    })
      .catch(err => {
        console.log(err)
        this.getJSONResponse(label, url);
      });
  }

  private getJSONResponse(label, url) {
    this.DataLoaders({
      type: 'GET',
      url: url
    }).then((result: any) => {
      result = JSON.parse(result) || '';
      this.responseCount++;
      label = label.replace(/ /g, '_');
      this.jsonObj[label] = result;
      if (this.dataCount == this.responseCount)
        this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
    })
      .catch(err => {
        console.log(err)
        this.responseCount++;
        if (this.dataCount == this.responseCount)
          this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        // 
      });
  }

  public getLocalJsondata(url) {
    let urls = url
    let isLocalhost = urls.includes("localhost");
    if (isLocalhost) {
      let urlFileJson = urls.split("/").pop();
      return "http://" + location.host + "/assets/" + urlFileJson;
    } else {
      return url;
    }
  }

  public getFilteredSource(reqObj: any = {}, label: string = 'source') {
    this.DataLoaders(reqObj)
      .then((result: any) => {
        this.responseCount++;
        result = JSON.parse(result) || ''
        this.jsonObj[label] = result;
        if (this.dataCount == this.responseCount)
          this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
      })
      .catch(err => {
        console.log(err)
        this.responseCount++;
        if (this.dataCount == this.responseCount)
          this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        // 
      })
  }

  public getDataAttributes(tenantId, reqObj, type, postFilter) {

    let Id = reqObj.id || '';
    this.socketGroupIds.push(reqObj.id);
    let apiType = reqObj.resource || 'groups';
    let filters = reqObj.filters || [];
    this.groupIds.push(reqObj.id);
    this.DataLoaders({
      type: 'GET',
      url: `./${Id}.json`
    })
      .then((result: any) => {
        try {
          result = JSON.parse(result) || '';
          this.responseCount++;
          return true;
        } catch (e) {
          let obj;
          if (apiType == 'analytics') {
            obj = {
              apiType: apiType,
              url: endpoints.ANALYTIC_QUERIES_URL(tenantId,Id),
              page: 0,
              size: 100,
              type: type,
              filters: reqObj.filters
            }
          } else if (apiType === 'groups' || apiType === 'target') {
            obj = {
              apiType: apiType,
              url: endpoints.GROUPS_URL(tenantId, Id),
              page: 0,
              size: 100,
              type: type,
              filters: reqObj.filters
            }
          }
          this.dataIterationCount++;
          if (postFilter && reqObj.filters && reqObj.filters.length > 0) {
            let data = {
              filters: reqObj.filters,
              type: apiType,
              url: `${this.newBaseURL}/tf-web/v1.0/${tenantId}`,
              id: Id,
              name: type
            }
            this.getFilteredDataUsingPostRequest(data)
          } else {
            this.getCompleteData(obj);
          }
          // this.getJSONResponse(type, `${this.newBaseURL}/tf-web/v1.0/${tenantId}/groups/${Id}/data?page=0&size=20`);
        }
        // this.checkFilters_renderDOM(filters, type);
        // this.jsonObj[type] = result;
        // if (this.dataCount == this.responseCount)
        //   this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
      })
      .catch(err => {
        console.log(err)

        // this.responseCount++;
        // if (this.dataCount == this.responseCount)
        //   this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        // 
        if (apiType === 'groups' || apiType === 'target') {
          let obj = {
            apiType: apiType,
            url: endpoints.GROUPS_URL(tenantId, Id),
            page: 0,
            size: 100,
            type: type,
            filters: reqObj.filters
          }
          this.dataIterationCount++
          // this.getCompleteData(obj)
          if (postFilter && reqObj && reqObj.filters && reqObj.filters.length > 0) {
            let data = {
              filters: reqObj.filters,
              type: apiType,
              url: `${this.newBaseURL}/tf-web/v1.0/${tenantId}`,
              id: Id,
              name: type
            }
            this.getFilteredDataUsingPostRequest(data)
          } else {
            this.getCompleteData(obj);
          }
          // this.DataLoaders({
          //   type: 'GET',
            // url: endpoints.GROUPS_URL(tenantId, Id)
          // })
          //   .then((result: any) => {
          //     this.responseCount++;
          //     result = JSON.parse(result) || ''
          //     this.jsonObj[type] = result;
          //     if (this.dataCount == this.responseCount)
          //       this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
          //   })
          //   .catch(err => {
          //     this.responseCount++;
          //     if (this.dataCount == this.responseCount)
          //       this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
          //     // 
          //   })
        } else {
          let obj = {
            apiType: apiType,
            url: endpoints.ANALYTIC_QUERIES_URL(tenantId, Id),
            page: 0,
            size: 100,
            type: type,
            filters: reqObj.filters
          }
          this.dataIterationCount++
          if (postFilter && reqObj && reqObj.filters && reqObj.filters.length > 0) {
            let data = {
              filters: reqObj.filters,
              type: apiType,
              url: `${this.newBaseURL}/tf-web/v1.0/${tenantId}`,
              id: Id,
              name: type
            }
            this.getFilteredDataUsingPostRequest(data)
          } else {
            this.getCompleteData(obj);
          }
          // this.getCompleteData(obj)
          // this.DataLoaders({
          //   type: 'GET',
          // url: endpoints.ANALYTIC_QUERIES_URL(tenantId, Id)

          // })
          //   .then((result: any) => {
          //     this.responseCount++;
          //     result = JSON.parse(result) || ''
          //     this.jsonObj[type] = result;
          //     if (this.dataCount == this.responseCount)
          //       this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
          //   })
          //   .catch(err => {
          //     this.responseCount++;
          //     if (this.dataCount == this.responseCount)
          //       this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
          //     // 
          //   })
        }
      });
  }

  public processFilterJSON(filters, element = null) {
    let filter = {}
    if (filters.length > 0) {
      for (let item in filters) {
        Object.keys(filters[item]).forEach(key => {
          if (key == 'key' && filters[item][key] == 'string') {
            filter[Object.keys(filters[item])[0]] = ''
          }
          if (key == 'value' && filters[item][key] == 'string') {
            filter[Object.keys(filters[item])[0]] = filters[item][Object.keys(filters[item])[0]]
          }
          // item1.countryName
          // item1 = groupName.model.entities[0].countryName;
          if (key == 'value' && filters[item][key] == 'dynamicValue') {
            let val = filters[item][Object.keys(filters[item])[0]];
            let DynamicValue;
            if (val.includes('.')) {
              if (val) {
                let type = val.split('.').shift();
                if (type.includes('item')) {
                  if (this.iterationDataLabels[type]) {
                    val = val.replace(type, this.iterationDataLabels[type]);
                    let value = val.split('.');
                    value.shift();
                    value = value.join('.')
                    DynamicValue = this.processVariable(this.jsonObj[val.split('.').shift()], value);
                  }
                } else {
                  DynamicValue = this.processVariable(this.jsonObj[val.split('.').shift()], val);
                }
              }
              // let DynamicValue = this.processDynamicVariableData(this.jsonObj[val.split('.').shift()], val.split('.').pop());
              filter[Object.keys(filters[item])[0]] = DynamicValue;
            }
          }
          if (key == 'value' && filters[item][key] == 'configVariable') {
            let val = filters[item][Object.keys(filters[item])[0]];
            if (this.BAAppData && this.BAAppData.baAppConfig.config.additionalVariables) {
              val = this.BAAppData.baAppConfig.config.additionalVariables[val]
            } else {
              val = '';
            }
            filter[Object.keys(filters[item])[0]] = val;
          }
          if (key == 'value' && filters[item][key] == 'userVariable') {
            let val = filters[item][Object.keys(filters[item])[0]];
            console.log(this.USER_INFO);
            val = this.USER_INFO[val] || '';
            filter[Object.keys(filters[item])[0]] = val;
          }
          if (key == 'value' && filters[item][key] == 'innerText') {
            if (element) {
              filter[Object.keys(filters[item])[0]] = element.innerText || element.innerHTML;
            } else {
              filter[Object.keys(filters[item])[0]] = ''
            }
          }
        })
      }
      return filter;
    }
  }

  public getFilteredDataUsingPostRequest(obj) {
    let filters = this.processFilterJSON(obj.filters);
    let updatedUrl;
    if (obj.apiType == 'analytics') {
      updatedUrl = `${obj.url}/analytic-queries/${obj.id}/data`;
    } else {
      updatedUrl = `${obj.url}/groups/${obj.id}/data`;
    }
    let requestOptions: any = {
      type: 'POST',
      url: updatedUrl,
      payload: filters,
      headers: {
        'content-type': 'application/json'
      }
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        result = JSON.parse(result);
        var groupName = obj.name.replace(/ /g, '_');
        this.jsonObj[groupName] = result;
        // if(obj.page == 0 ) {
        //   this.jsonObj[obj.name] = result;
        //   this.responseCount++;
        //   if (this.dataCount == this.responseCount)
        //     this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);
        // } else {
        //   for(let item in result.model.entities) {
        //     this.jsonObj[obj.name].model.entities.push(result.model.entities[item])
        //   }
        // }
        this.responseCount++
        if (this.dataCount == this.responseCount)
          this.renderDomWithData(this.baDataConfig.bind_to, this.baDataConfig.baApp);

      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  public removeFocusable() {
    let closeIcons = document.getElementsByClassName('fa-times-circle') || [];
    for (let i = 0; i < closeIcons.length; i++) {
      if (closeIcons[i].tagName == 'SPAN')
        closeIcons[i].classList.remove('focusable');
      break;
    }
    // let ele = document.getElementById(bind_to);
    // let closeIcon = ele.getElementsByClassName('fa-times-circle');
    // if(closeIcon && closeIcon.length > 0) {
    //   for(let i = 0; i < closeIcon.length; i++) {
    //     closeIcon[i].classList.add('focusable');
    //   }
    // }
  }

  public conditionalRenderingComponent(baApp) {

    if (baApp.baAppConfig.config && baApp.baAppConfig.config.conditions) {
      let items = Object.keys(baApp.baAppConfig.config.conditions);
      for (let item in items) {
        // let element = document.getElementById(items[item]);
        let conditions = baApp.baAppConfig.config.conditions[items[item]].conditions;
        let actions = baApp.baAppConfig.config.conditions[items[item]].actions;
        // 

        // conditions related code
        if (conditions && conditions.length > 0) {
          let conditionOutput = [];
          for (let i = 0; i < conditions.length; i++) {
            let inputType = conditions[i].inputType || '';
            let outputType = conditions[i].outputType || '';
            let inputValue = conditions[i].inputValue || '';
            let outputValue = conditions[i].outputValue || '';
            let condition = conditions[i].conditionType || 'DEFAULT';
            let type = conditions[i].type;
            if (inputType) {
              switch (inputType) {
                case 'dynamicVariable':
                  let value = inputValue.split('.');
                  value.shift();
                  value = value.join('.')
                  inputValue = this.processVariable(this.jsonObj[inputValue.split('.').shift()], value);
                  break;
                case 'configVariable':
                  if (this.BAAppData && this.BAAppData.baAppConfig.config.additionalVariables) {
                    inputValue = this.BAAppData.baAppConfig.config.additionalVariables[inputValue]
                  } else {
                    inputValue = '';
                  }
                  break;
                case 'userVariable':
                  inputValue = this.USER_INFO[inputValue] || '';
                  break;
                case 'string':
                  inputValue = inputValue;
                  break;
              }
            }
            if (outputType) {
              switch (outputType) {
                case 'dynamicVariable':
                  // outputValue doesn't need to contain group name;
                  let value = outputValue.split('.');
                  value.shift();
                  value = value.join('.');
                  outputValue = this.processVariable(this.jsonObj[outputValue.split('.').shift()], value);
                  break;
                case 'configVariable':
                  if (this.BAAppData && this.BAAppData.baAppConfig.config.additionalVariables) {
                    outputValue = this.BAAppData.baAppConfig.config.additionalVariables[outputValue]
                  } else {
                    outputValue = '';
                  }
                  break;
                case 'userVariable':
                  outputValue = this.USER_INFO[outputValue] || '';
                  break;
                case 'string':
                  outputValue = outputValue;
                  break;
              }
            }
            // Value processing done!
            // Condition check
            switch (condition) {
              case 'IS EQUAL TO':
                if (inputValue == outputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS NOT EQUAL':
                if (inputValue != outputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS EXISTIS':
                if (inputValue || outputType) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'NOT EXISTIS':
                if (!inputValue || !outputType) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS LESS THAN':
                if (outputValue < inputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS GREATER THAN':
                if (inputValue > outputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS LESS THAN OR EQUAL TO':
                if (outputValue <= inputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              case 'IS GREATER THAN OR EQUAL TO':
                if (inputValue >= outputValue) {
                  this.validateConditions = true;
                  conditionOutput.push(true);
                } else {
                  conditionOutput.push(false);
                }
                break;
              default:

            }
          }
        }

        // actions related code
        if (this.validateConditions) {
          for (let i = 0; i < actions.length; i++) {
            if (actions[i].actionType && actions[i].actionType == 'display'
              && actions[i].actionValue) {
              let element = document.getElementById(actions[i].element);
              element.style.display = `${actions[i].actionValue}`
            }
          }
        }
      }
    }
  }

  public checkDataSources(baApp) {
    let ele = baApp.baAppConfig.config.dataSource;
    let dynamicData: any = this.formatResponse1(ele);
    let getDataSources = Object.keys(dynamicData);
    for (let i = 0; i < this.duplicateGroupName.length; i++) {
      const groupName = this.duplicateGroupName[i];
      for (let key in getDataSources) {
        if (getDataSources[key] == groupName) {
          for (let item in this.jsonObj) {
            if (this.jsonObj[item] && this.jsonObj[item].model && this.jsonObj[item].model.id) {
              if (this.jsonObj[item].model.id == dynamicData[getDataSources[key]].id) {
                this.completeGroupsData[getDataSources[key]] = this.jsonObj[item];
                this.checkFilters_renderDOM(dynamicData[getDataSources[key]].filters, getDataSources[key])
              }
            }
          }
        }
      }
    }
  }

  public renderDomWithData(bind_to, baApp, actionType?) {
    console.log("*********renderDomWithData*********");
    // let jsonObj: any = {
    //   SOURCE: [],
    //   TARGET: [],
    //   CONTEXT: []
    // };

    // TODO: Socket code become old, need to modify it
    // if (this.socketGroupIds.length > 0) {
    //   this.groupListenSocket();
    // }
    this.domRendered = false;
    let template_code = ''
    if (baApp.baAppConfig.files)
      baApp.baAppConfig.files.forEach(item => {
        if (item.type === 'template') {
          template_code = item.content.body
        }
      })
    let oldURL = `${endpoints.CONTENT_URL}/7bb3de69-33b5-4e1d-9a4d-5d784d0be743`;
    let newURL = 'https://moctobpltc-i.akamaihd.net/hls/live/571329/eight/playlist.m3u8'
    template_code = template_code.replace(oldURL, newURL);
    template_code.replace(/ auto/g, '');
    this.checkDataSources(baApp)
    if (actionType) {

      if (actionType == 'INIT' || actionType == 'OVERWRITE') {
        document.getElementById(bind_to).innerHTML = template_code;

      } else if (actionType == 'OVERLAY') {
        document.getElementById(bind_to).innerHTML += template_code;
      }
    } else {
      if (bind_to != '') {
        document.getElementById(bind_to).innerHTML = template_code;
      }
    }

    // this.scaleUI();
    this.convertResolution(bind_to);
    this.createVueComponent()
    // window.addEventListener('resize', this.scaleUI);
    // }
    let response1 = this.formatResponse1(baApp.baAppConfig.config.renderComponents);
    this.addFormRendering(baApp.baAppConfig.config.validation);

    this.conditionalRenderingComponent(baApp);

    let eventsData = this.eventsRendering(baApp.baAppConfig.config.elements);

    this.removeFocusable()

    setTimeout(() => {
      for (let key in response1) {
        if (response1[key].type == 'chart') {
          this.totalChartsInBA++
        }
      }
      this.renderComponents(response1);
      this.navigator.init();
      this.navigator.add({
        selector: '.focusable'
      })
      this.navigator.makeFocusable();
      this.OTTModeElements = document.querySelectorAll('[ottmode="true"]');
      if (!CommonService.OTTEnabled()) {
        for (let key in this.OTTModeElements) {
          this.OTTModeElements[key].style.display = 'none'
        }
      }

    }, 1900);
    setTimeout(() => {
      let ele = document.getElementById('vue-app');
      // @ts-ignore
      if (ele && ele.firstChild.className && ele.firstChild.className.includes('fa-times')) {
        // @ts-ignore
        ele.firstChild.classList.add('focusable-close');
        this.navigator.add({
          selector: '.focusable-close'
        });
        this.navigator.makeFocusable();
      }
    }, 2000);

    this.tempraryViewComponent = this.viewComponent;

    // setTimeout(() => {
    //   this.removeInitCSSAnimation();
    // }, 200);
    // @ts-ignore
    //   AOS.init({
    //     offset: 120, // offset (in px) from the original trigger point
    //     delay: 100, // values from 0 to 3000, with step 50ms
    //     duration: 1000, // values from 0 to 3000, with step 50ms
    //     easing: 'ease',
    // });
    // Flip Animation Code
    let IsFilpAvaiable = document.getElementsByClassName('animate-flip');
    let IsFilpContentAvaiable = document.getElementsByClassName('animate-flipContent');
    if (IsFilpAvaiable.length > 0 && IsFilpContentAvaiable.length > 0) {
      let that = this;
      let index = 0;
      setInterval(function () {
        index++
        that.processFlip(index)
      }, 5000);
    }

    try {
      multiBASObject.handleEventByBAID(baApp.baAppConfig.id);
    } catch (e) {
      console.log("PROBABLY IT'S A SINGLE BA", e)
    }
  }

  public processFlip(number) {

    let elements = document.getElementsByClassName('animate-flip');
    let flipShow = document.getElementsByClassName('animate-flipContent');
    if (number % 2 == 0) {
      // 
      for (let i = 0; i < flipShow.length; i++) {
        flipShow[i].classList.add('animate__animated', 'animate__fadeIn');
      }
      for (let j = 0; j < flipShow.length; j++) {
        flipShow[j].classList.remove('animate__fadeIn');
        // @ts-ignore
        flipShow[j].style.display = 'none';
      }
      for (let k = 0; k < elements.length; k++) {
        elements[k].classList.add('animate__animated', 'animate__fadeIn');
        // @ts-ignore
        elements[k].style.display = 'block';
      }
    } else {
      // 
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.add('animate__animated', 'animate__fadeIn');
      }
      for (let j = 0; j < elements.length; j++) {
        elements[j].classList.remove('animate__fadeIn');
        // @ts-ignore
        elements[j].style.display = 'none';
      }
      for (let k = 0; k < flipShow.length; k++) {
        flipShow[k].classList.add('animate__animated', 'animate__fadeIn');
        // @ts-ignore
        flipShow[k].style.display = 'block';
      }
    }
  }

  public convertResolution = (container) => {
    let elements: any = '';
    if (container != '')
      elements = document.getElementById(container).getElementsByClassName('ba-element');
    else
      elements = document.body.getElementsByClassName('ba-element');
    for (var i = 0; i < elements.length; i++) {
      let elementTag = elements[i].tagName;
      switch (elementTag) {
        case 'H5':
          elements[i].style.setProperty('white-space', 'pre-wrap', 'important');
          elements[i].style.setProperty('overflow', 'auto', 'important');
          break;
      }
      if (elements[i].style.display == 'none') {
        elements[i].style.display = 'block';
        this.forcedDisplayElements.push(elements[i].id);
      }
      let inset = elements[i].style.inset || '';
      if (inset) {
        inset = inset.split(' ');
        elements[i].style.setProperty('left', inset[3].trim(), 'important');
        elements[i].style.setProperty('top', inset[0].trim(), 'important');
      }
      let wdth, elementWidth = elements[i].offsetWidth;
      let higt, elementHeight = elements[i].offsetHeight;
      let lft, elementLeft = elements[i].offsetLeft;
      let tp, elementTop = elements[i].offsetTop;
      let lineHg, lineHeight = elements[i].style.lineHeight || '';
      // let globalFontSize: number = 10;
      // if(window.innerWidth > 1920) {
      //   globalFontSize = 10;
      // } else if(window.innerWidth < 1920 && window.innerWidth > 1300) {
      //   globalFontSize = 16;
      // } else if(window.innerWidth < 1400) {
      //   globalFontSize = 20;
      // }

      // let sourceHeight = 650
      let rootFontSize = document.getElementsByTagName('html')[0].style.fontSize ? document.getElementsByTagName('html')[0].style.fontSize : '16px';
      elements[i].style.fontSize = (Number(window.getComputedStyle(elements[i]).fontSize.split('px')[0]) / Number(rootFontSize.split('px')[0])) + 'rem';
      // elements[i].style.fontSize = (100 * (Number(window.getComputedStyle(elements[i]).fontSize.split('px')[0])) / 650) + 'vh';
      let marginLeft = Number(window.getComputedStyle(elements[i].parentNode).marginLeft.split('px')[0]);
      let marginRight = Number(window.getComputedStyle(elements[i].parentNode).marginRight.split('px')[0]);
      let canvasWidth: any = elements[i].parentNode.offsetWidth + marginLeft + marginRight;
      canvasWidth = Number(document.getElementsByTagName('body')[0].style.width.split('px')[0]) || screen.width;
      let canvasHeight: any = Number(document.getElementsByTagName('body')[0].style.height.split('px')[0]) || screen.height;
      let vueContainer: any = document.getElementById('vue-app') || '';
      canvasWidth = window.innerWidth;
      canvasHeight = window.innerHeight;
      let leftMenu: any = document.getElementsByClassName('leftMenu') || [];
      if (leftMenu.length == 0) {
        canvasWidth = vueContainer != '' ? vueContainer.offsetWidth : window.innerWidth; // screen.availWidth;
        canvasHeight = vueContainer != '' ? vueContainer.offsetHeight : window.innerHeight; // screen.availHeight;
      }
      // 
      // 
      // 
      // if (elementWidth.endsWith('px'))
      wdth = ((elementWidth / canvasWidth) * 100) + 'vw';
      // if (elementHeight.endsWith('px'))
      higt = ((elementHeight / canvasHeight) * 100) + 'vh';

      // if (elementLeft.endsWith('px'))
      lft = ((100 * elementLeft) / canvasWidth) + 'vw';
      // if (elementTop.endsWith('px'))
      tp = ((100 * elementTop) / canvasHeight) + 'vh';
      elements[i].style.removeProperty('right');

      elements[i].style.removeProperty('bottom');
      elements[i].style.setProperty('left', lft, 'important');
      elements[i].style.width = wdth;
      elements[i].style.height = higt;

      elements[i].style.setProperty('top', tp, 'important');
      elements[i].style.setProperty('right', `${lft + wdth}`, 'important');
      elements[i].style.setProperty('bottom', `${tp + higt}`, 'important');
      elements[i].style.cursor = 'default';
      if (lineHeight && lineHeight.includes('px')) {
        lineHeight = lineHeight.replace('px', '');
        lineHeight = Number(lineHeight);
        lineHg = ((lineHeight / canvasHeight) * 100) + 'vh';
        elements[i].style.removeProperty('line-height');
        elements[i].style.lineHeight = lineHg;
      }
      // elements[i].style.position = 'absolute';
    }
    for (let i = 0; i < this.forcedDisplayElements.length; i++) {
      document.getElementById(this.forcedDisplayElements[i]).style.display = 'none';
    }
    this.forcedDisplayElements = [];
  }

  public renderEvent = (targetDiv, innerTarget, renderPayload = null) => {

    this.renderComponents(renderPayload);
    // let ele = document.getElementById(targetDiv);
    // targetDiv.style.display = 'block';
    // if (targetDiv && targetDiv.children.length > 1) {
    //   for (let i = 0; i < targetDiv.children.length; i++) {
    //     // ele.children[i].style.display = "none"; // need to work
    //      (<HTMLElement>targetDiv.children[i]).style.display = 'none';
    //   }
    // } 
    // document.getElementById(innerTarget).style.display = 'block';
  }

  private getClosestParent = (node, attr, includeEle) => {
    let sourceElement: any = '';
    if (includeEle && node.getAttribute(attr)) {
      return node;
    }
    // let rootElement = '';
    while (sourceElement == '') {
      node = node.parentElement;
      sourceElement = node.getAttribute(attr) || '';
    }
    return node;
  }


  public eventsHandling = (e) => {
    // let currencyTarget = e.target.id;
    let node = e.currentTarget;
    let nodeID = e.currentTarget.id;
    // let idList = document.querySelectorAll(`[id="${nodeID}"]`);
    let elements = this.BAAppData.baAppConfig.config.elements;
    let SourceElement: any = '';
    // if (idList.length > 1)
    // SourceElement = this.getClosestParent(node, 'index', true);
    let key = elements[nodeID];
    let parentStyleNeeded;
    for (let val in key) {
      let data = key[val];
      let action = data.action;
      let target;
      let idList;
      if (action == 'filter') {
        target = data.target;
        idList = document.querySelectorAll(`[id="${nodeID}"]`);
      } else {
        target = data.target;
        idList = document.querySelectorAll(`[id="${target}"]`);
      }
      if (idList.length > 1) {
        let targetEle = '';
        SourceElement = this.getClosestParent(node, 'index', true) || '';
        if (action != 'filter') {
          while (targetEle == '') {
            targetEle = SourceElement.querySelector(`#${target}`) || '';
            if (targetEle == '') {
              node = SourceElement;
              SourceElement = this.getClosestParent(node, 'index', false) || '';
            }
          }
        }
        parentStyleNeeded = true;
      }
      if (SourceElement == '') {
        SourceElement = document;
      }
      switch (action) {
        case 'show':
          var ele = SourceElement.querySelector(`#${target}`);
          if (!CommonService.OTTEnabled()) {
            for (let key in this.OTTModeElements) {
              if (ele.id == this.OTTModeElements[key].id)
                return
            }
          }
          ele.style.display = 'block';
          if (ele.style.visibility && ele.style.visibility == 'hidden') {
            ele.style.visibility = 'visible';
          }
          if (parentStyleNeeded)
            setTimeout(() => {
              let targetWidth1: any = Number(ele.style.width.replace('vw', ''));
              let targetLeft1: any = Number(ele.style.left.replace('vw', ''));
              let targetHeight1: any = Number(ele.style.height.replace('vh', ''));
              let targetTop1: any = Number(ele.style.top.replace('vh', ''));
              let parentWidth1 = Number(ele.parentNode['style'].width.replace('vw', ''));
              let parentHeight1 = Number(ele.parentNode['style'].height.replace('vh', ''));
              if (targetWidth1 + targetLeft1 > (parentWidth1 + 2)) {
                ele.parentNode['style'].width = `${parentWidth1 + targetWidth1}vw`;
              }
              if (targetHeight1 + targetTop1 > (parentHeight1 + 2)) {
                ele.parentNode['style'].height = `${parentHeight1 + targetHeight1}vh`;
              }
            }, 100);
          break;
        case 'hide':
          if (!CommonService.OTTEnabled()) {
            for (let key in this.OTTModeElements) {
              if (target == this.OTTModeElements[key].id)
                return
            }
          }
          SourceElement.querySelector(`#${target}`).style.display = 'none';
          if (parentStyleNeeded)
            setTimeout(() => {
              let targetWidth1: any = Number(ele.style.width.replace('vw', ''));
              let targetLeft1: any = Number(ele.style.left.replace('vw', ''));
              let targetHeight1: any = Number(ele.style.height.replace('vh', ''));
              let targetTop1: any = Number(ele.style.top.replace('vh', ''));
              let parentWidth1 = Number(ele.parentNode['style'].width.replace('vw', ''));
              let parentHeight1 = Number(ele.parentNode['style'].height.replace('vh', ''));
              if (targetWidth1 + targetLeft1 > parentWidth1) {
                ele.parentNode['style'].width = `${parentWidth1 + targetWidth1}vw`;
              }
              if (targetHeight1 + targetTop1 > parentHeight1) {
                ele.parentNode['style'].height = `${parentHeight1 + targetHeight1}vh`;
              }
            }, 100);
          break;
        case 'API':
          if (data && data.requestType) {
            if (data.requestType == "GET") {
              let params = '';
              for (let compt in data.params) {
                params += `${data.params[compt].key}=${data.params[compt].value}&`;
              }
              let requestOptions = {
                type: data.requestType,
                url: `${data.api}?${params}`
              }
              dataLoaders
                .processRequest(requestOptions)
                .then((result: any) => {

                })
                .catch((err: any) => {
                  console.log(err)
                })
            }
            if (data.requestType == "POST") {
              let params = {};
              let headers = {};
              for (let compt in data.params) {
                if (data.params[compt].type == 'dynamicVariable') {
                  // params[data.params[compt].key] = this.processVariable(this.jsonObj, data.params[compt].value)
                  params[data.params[compt].key] = this.processVariable(this.jsonObj, data.params[compt].value);
                } else if (data.params[compt].type == 'configVariable') {
                  let value;
                  if (this.BAAppData && this.BAAppData.baAppConfig.config.additionalVariables) {
                    value = this.BAAppData.baAppConfig.config.additionalVariables[data.params[compt].value]
                  }
                  params[data.params[compt].key] = value || '';
                } else if (data.params[compt].type == 'userVariable') {
                  params[data.params[compt].key] = this.USER_INFO[data.params[compt].value] || '';
                } else if (data.params[compt].type == 'appVariable') {
                  params[data.params[compt].key] = this.APP_INFO[data.params[compt].value] || '';
                } else if (data.params[compt].type == 'randomNumber') {
                  params[data.params[compt].key] = this.generateRandomId() || '';
                } else {
                  params[data.params[compt].key] = data.params[compt].value;
                }
              }
              // for(let compt in data.params) {
              //   params[data.params[compt].key] = data.params[compt].value;
              // }
              for (let compt in data.headers) {
                headers[data.headers[compt].key] = data.headers[compt].value;
                if (data.headers[compt].key == 'content-type' && headers[data.headers[compt].key] == 'multipart/form-data') {
                  var filesPayload = new FormData();
                  for (let headerKey in params) {
                    filesPayload.append(headerKey, params[headerKey]);
                  }
                  params = filesPayload;
                }
              }
              if (Object.keys(data.headers).length == 0) {
                let finalParams = []
                for (let i = 0; i < Object.keys(params).length; i++) {
                  finalParams.push(`${Object.keys(params)[i]}=${params[Object.keys(params)[i]]}`)
                }
                var finalParams1 = finalParams.join('&');
                let split = data.api.split('?');
                data.api = `${split[0]}?${finalParams1}`;
                params = '';
              }
              // headers = {};
              let requestOptions: any = {
                type: 'POST',
                url: `${data.api}`,
                payload: params,
                headers: headers
              }
              dataLoaders
                .processRequest(requestOptions)
                .then((result: any) => {

                })
                .catch((err: any) => {
                  console.log(err)
                })

            }
          }
          break;
        case 'method':
          if (node.innerText == 'start') {
            if (this.Scale_OPTIONS.otaRmpUrl == "") {
              this.showOTAWidget(this.Scale_CONTAINER, this.Scale_OPTIONS);
            }
            this.showOTTWidget(this.Scale_CONTAINER, this.Scale_OPTIONS);
          }
          if (node.innerText == 'pause') {
            this.pauseOTAWidget();
          }
          if (node.innerText == 'scale') {
            this.scalingOTAWidget(this.Scale_CONTAINER, this.Scale_OPTIONS);
          }
          break;
        case 'save':
          if (node.id.includes("DivInput")) {
            if (node.children[0].tagName == 'INPUT') {
              if (node.children[0].getAttribute('type') == 'checkbox') {
                this.jsonObj[data.labelData] = node.children[0].checked;
              }
              else if (node.children[0].getAttribute('type') == 'file') {
                this.jsonObj[data.labelData] = node.children[0].files[0];


              } else {
                this.jsonObj[data.labelData] = node.children[0].value;
              }
            }
          }
          else {
            if (data.value && data.valueType == 'attributeValue') {
              this.jsonObj[data.labelData] = node.getAttribute(data.value) || '';
              // console.log(JSON.stringify(this.jsonObj) + "       JSON OBJ")
            } else {
              var attributeData = node.getAttribute('labeldata');
              var value = node.innerText.replace('$', '')
              this.jsonObj[data.labelData] = value;
            }
          }
          // this.viewComponent.$data[label1] = element.textContent;
          //@ts-ignore
          // var dataList = this.viewComponent.$data;
          // this.viewComponent_data(labelname) = element .textcontent
          // this.viewComponent.$data[jsonObj] = element.textContent;
          // document.getElementById(nodeID).innerText = data.labelData;
          break;
        case 'filter':
          this.processDataFilter(data, SourceElement)
          break;
        case 'animation':
          // Data need to be like this
          // {
          //   "action": "animation",
          //   "type": "click",
          //   "isuseDatasorce": null,
          //   "labelData": null,
          //   "filters": [],
          //   "source": "",
          //   "actionElements": ["image_144c45b47723c25"]
          // }
          this.processAnimations(data)
          break;
        case 'render':
          if (data && data.renderPayload) {
            let targetEle = SourceElement.querySelector(`#${target}`);
            this.renderEvent(targetEle, '', data.renderPayload);
          } else {

          }
          break;
        default:
          break;

      }
    }
  }

  public processAnimations(data) {

    let animationItems = [];
    for (let item in data.actionElements) {
      if (data.actionElements[item].key) {
        animationItems.push(data.actionElements[item].key)
      } else {
        animationItems.push(data.actionElements[item])
      }
    }
    if (animationItems.length > 0) {
      for (let i = 0; i < animationItems.length; i++) {
        let elms = document.querySelectorAll(`[id="${animationItems[i]}"]`);
        if (elms.length > 1) {
          let currentElement = elms[this.currentIndex];
          if (currentElement) {
            // currentElement.className.split('animate');
            for (let j = 0; j < currentElement.classList.length; j++) {
              if (currentElement.classList && currentElement.classList[j].includes('animate__')
                && currentElement.classList[j] != 'animate__animated' && !currentElement.classList[j].includes('animate__delay')) {
                let animClass = currentElement.classList[j];
                currentElement.classList.remove(animClass);
                setTimeout(() => {
                  currentElement.classList.add(animClass)
                }, 0);
              }
            }
          }
        } else {
          let elementEle = document.getElementById(animationItems[i]);
          if (elementEle) {
            for (let k = 0; k < elementEle.classList.length; k++) {
              if (elementEle.classList && elementEle.classList[k].includes('animate__')
                && elementEle.classList[k] != 'animate__animated' && !elementEle.classList[k].includes('animate__delay')) {
                let animClass = elementEle.classList[k];
                elementEle.classList.remove(animClass);
                setTimeout(() => {
                  elementEle.classList.add(animClass);
                }, 0);
              }
            }
          }
        }
      }
    }
  }

  public processDataFilter(data, ele) {
    if (data && data.source) {
      // 

      this.checkFilters_renderDOM(data.filters, data.source, ele)
      // let groups = Object.keys(this.jsonObj);
      // let groupExists = false;
      // let filterValue = null;
      // if(data.source.name && data.source.id && data.source.resource) {
      //   if(data.source.filter) {
      //     for(let key in data.source.filter) {
      //       if(data.source.filter[key] == 'innerText') {
      //         filterValue = ele.innerText
      //       }
      //     }
      //     let obj = {
      //       url : `${this.newBaseURL}/tf-web/v1.0/${CommonService.getUserInfo().tenantId}/${data.source.resource}/${data.source.id}/data`,
      //       filter: {
      //         [Object.keys(data.source.filter)[0]]: filterValue
      //       },
      //       name: data.source.name,
      //     }
      //     this.getFilteredData(obj)
      //     // this.getCompleteData(data.source.resource, `${this.newBaseURL}/tf-web/v1.0/${CommonService.getUserInfo().tenantId}/groups/${data.source.id}/data?${Object.keys(data.source.filter)[0]}=${filterValue}`, 0, 100, data.source.name)
      //   }
      // }
    }
  }

  // Processing nested object values with params as object and key - (obj in which we are searching the key | key is a object key)
  // RAM
  public processVariable(obj, key) {
    var keys = key.split("."), value;
    for (var i = 0; i < keys.length; i++) {
      if (Array.isArray(obj[keys[i]])) {
        if (obj[keys[i]].length > 0) {
          value = obj = obj[keys[i]][0];
          continue;
        } else {
          return value = '';
        }
      }
      if (typeof obj[keys[i]] !== "undefined") {
        value = obj = obj[keys[i]];
      } else {
        return '';
      }
    }
    return value;
  }

  public processDynamicVariableData(json, searchKey) {
    let finalValue;
    let keys = Object.keys(json);
    for (let i = 0; i < keys.length; i++) {
      const value = json[keys[i]];
      if (keys[i] === searchKey && typeof value !== 'object') {
        //  r.push(value);
        finalValue = value;
        return finalValue;
      } else if (typeof value === 'object') {
        this.processDynamicVariableData(json, searchKey);
      }
    }
    // Object.keys(json).forEach(key => {
    //   const value = json[key];
    //   if(key === searchKey && typeof value !== 'object'){
    //     //  r.push(value);
    //      finalValue = value;
    //      return false
    //   }else if(typeof value === 'object'){
    //     this.processDynamicVariableData(json, searchKey);
    //   }
    // });
    // return finalValue;
  }

  public eventsRendering(eventsData) {

    for (let ele in eventsData) {
      let key = eventsData[ele];
      let element = ele;
      for (let val in key) {
        let eventData = key[val];
        if (eventData.vueClick)
          continue;
        if (eventData.type && eventData.type == 'hover') {
          let eventType = 'mouseenter';
          document.getElementById(element).addEventListener(eventType, this.events);
          document.getElementById(element).style.cursor = 'pointer';
        }
        else if (eventData.action == 'content-change' && eventData.type == 'change') {
          document.getElementById(element).setAttribute("contenteditable", "true");
        } else {
          // document.getElementById(event.target).addEventListener('sn:enter-down', this.events);
          // document.getElementById(event.target).addEventListener(event.type, this.events);
          let elementsList = document.querySelectorAll(`[id="${element}"]`);
          for (let i = 0; i < elementsList.length; i++) {
            if (eventData.type == 'click' && eventData.defaultClick) {
              if (elementsList.length > 1) {
                if (i == 0) {
                  elementsList[i].addEventListener('sn:enter-down', this.eventsHandling);
                  elementsList[i].addEventListener(eventData.type, this.eventsHandling);
                  // @ts-ignore
                  elementsList[i].style.cursor = 'pointer';
                  // @ts-ignore
                  elementsList[i].click();
                  event.preventDefault();
                }
              }
            }
            elementsList[i].addEventListener('sn:enter-down', this.eventsHandling);
            elementsList[i].addEventListener(eventData.type, this.eventsHandling);
            // @ts-ignore
            elementsList[i].style.cursor = 'pointer';
            // elementsList[i].addEventListener(event.type, this.events);
          }
        }
      }
    }
    // let Events = {}
    // let subEvents = [];
    // let element;
    // let eventType;
    // let eventAction;
    // let eventTarget;
    // for (let ele in eventsData) {
    //   element = ele;
    //   let innerElements = eventsData[ele];
    //   subEvents = [];
    //   for (let val in innerElements) {
    //     eventType = innerElements[val].type
    //     eventAction = innerElements[val].action
    //     eventTarget = innerElements[val].target
    //     subEvents.push(innerElements[val])
    //     // 
    //     if (eventType === 'hover') {
    //       eventType = 'mouseenter'
    //       document.getElementById(element).addEventListener(eventType, this.events);
    //     } else {
    //       document.getElementById(element).addEventListener('sn:enter-down', this.events);
    //       document.getElementById(element).addEventListener(eventType, this.events);
    //     }

    //     if (eventType && eventAction === 'show' && eventTarget) {
    //       document.getElementById(eventTarget).style.display = 'none'
    //     }
    //     if (eventType && eventAction === 'hide' && eventTarget) {
    //       document.getElementById(eventTarget).style.display = 'block'
    //     }
    //     if (eventType && eventAction === 'render' && innerElements[val].renderPayload) {
    //       document.getElementById(eventTarget).style.display = 'none'
    //     }
    //   }
    //   Events[element] = subEvents;
    //   document.getElementById(element).setAttribute('eventsData', JSON.stringify(Events[element]));
    // }
  }

  public events = e => {

    let target = e.target.id;
    if (target == "") {
      return;
    }
    let element = document.getElementById(target);
    let data = JSON.parse(element.getAttribute('eventsdata'));
    for (let event in data) {
      let eventDetails = data[event];
      if (eventDetails.action && eventDetails.action == 'show') {
        document.getElementById(eventDetails.target).style.display = 'block';
      }
      if (eventDetails.action && eventDetails.action == 'hide') {
        document.getElementById(eventDetails.target).style.display = 'none';
      }
      if (eventDetails['innerTarger'] || eventDetails['renderPayload']) {
        var innerTarget = event['innerTarger'];
        var renderPayload = event['renderPayload'];
      }
      if (eventDetails.action && eventDetails.action === 'render') {
        if (innerTarget && renderPayload) {
          this.renderEvent(eventDetails.target, innerTarget, renderPayload);
        } else {

        }
      }
    }
    // let eventType;
    // let eventAction;
    // let targetDiv;
    // let innerTarget;
    // let renderPayload;

    // for (let i = 0; i < data.length; i++) {
    //   let event = data[i];
    //   eventType = event['type']; // click, hover, success , failure
    //   eventAction = event['action']; // render, show, hide, content-change
    //   targetDiv = event['target']; // target element
    //   if (event['innerTarger'] || event['renderPayload']) {
    //     innerTarget = event['innerTarger'];
    //     renderPayload = event['renderPayload'];
    //   }
    //   // stop immediate propogration
    //   if (eventType && eventAction && eventAction === 'render') {
    //     if (innerTarget && renderPayload) {
    //       this.renderEvent(targetDiv, innerTarget, renderPayload);
    //     } else {
    //       
    //     }
    //   }
    //   if ((eventType && eventAction && eventAction === 'show') || eventAction === 'hide') {
    //     let ele = document.getElementById(targetDiv);
    //     if (eventAction === 'show') {
    //       ele.style.display = 'block';
    //     } else if (eventAction === 'hide') {
    //       ele.style.display = 'none';
    //     } else {
    //       
    //     }
    //   }
    //   // Analytics code starts
    //   let eType;
    //   switch (eventType) {
    //     case 'click':
    //       eType = 'select';
    //       break;
    //     case 'hover':
    //       eType = 'hover'
    //       break;
    //   }
    //   let BAOpenObj = {
    //     "TargetElement": target,
    //     "eventType": eType
    //   }
    //   this.analytics.sendAnalytics(eType, BAOpenObj);
    //   return;
    //   // Analytics code ends
    // }
  }

  public addFormRendering = validate => {
    for (let ele in validate) {


      let buttonId = validate[ele].buttonId
      let button = document.getElementById(validate[ele].buttonId)
      button.setAttribute('targetElement', validate[ele].targetId)
      button.setAttribute('api', validate[ele].FormAPI)
      button.setAttribute('schemaid', validate[ele].schemaId)
      button.setAttribute('tenantid', validate[ele].tenantId)
      button.addEventListener('click', event => {
        this.formValidation(buttonId)
        event.preventDefault()
      })
    }
  }

  public formValidation = id => {

    let button = document.getElementById(id)
    let formAPI = button.getAttribute('api')
    let formContainer = button.getAttribute('targetElement')
    let schemaId = button.getAttribute('schemaid')
    let tenantId = button.getAttribute('tenantid')
    let events
    if (button.getAttribute('eventsdata')) {
      events = button.getAttribute('eventsdata')
    }
    let validateContainer = document.getElementById(formContainer)
    if (validateContainer) {

      let labels = validateContainer.getElementsByTagName('label');

      let keysArr = []
      for (let i = 0; i < labels.length; i++) {
        let val = labels[i].textContent.trim()
        val = val.replace(/\*/g, '')
        val = val.trim();

        // val = val.replace(/\s/g, '');
        val = this.camelize(val)

        keysArr.push(val)
      }
      let payload = {}
      let Forminputs = validateContainer.getElementsByTagName('input')
      for (let i = 0; i < Forminputs.length; i++) {
        let mandate: any = Forminputs[i].getAttribute('ismandatory') || ''
        mandate = mandate === '' ? false : mandate === 'true' ? true : false
        let inputType = Forminputs[i].getAttribute('type') || 'text'
        let defaultValue: any = ''
        switch (inputType) {
          case 'number':
            defaultValue = 0
            break
          case 'text':
            defaultValue = ''
            break
          default:
            defaultValue = ''
            break
        }
        if (Forminputs[i].value == '') {
          if (mandate) {
            return
          } else {
            Forminputs[i].value == defaultValue;
          }
        }
        let val
        if (inputType === 'number') {
          val = JSON.parse(Forminputs[i].value)
        } else {
          val = Forminputs[i].value
        }
        payload[keysArr[i]] = val
      }
      payload['timestamp'] = new Date().getTime()
      payload['dateTime'] = new Date()

      this.formSubmit(formAPI, payload, schemaId, tenantId, events)
    }
  }

  public camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return '' // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase()
    })
  }

  public formSubmit = (APIurl, params, schemaId, tenantId, events) => {

    APIurl = APIurl.split('/');
    tenantId = tenantId || this.USER_INFO.tenantId;
    tenantId = tenantId == undefined ? this.USER_INFO.tenantId : tenantId;
    APIurl[APIurl.length - 3] = this.USER_INFO.tenantId;
    APIurl = APIurl.join('/');

    let headers = {
      'Content-Type': 'application/json;charset=UTF-8'
    }
    let requestOptions: any = {
      type: 'POST',
      url: `${APIurl}${schemaId}?upsert=true`,
      payload: [params],
      headers: headers
    }
    dataLoaders
      .processRequest(requestOptions)
      .then((result: any) => {
        this.successFailureEvents(events, 'success');
      })
      .catch((err: any) => {
        console.log(err)
        this.successFailureEvents(events, 'failure');
      })
  }

  public successFailureEvents(events, type) {

    if (events) {
      let eventsData = JSON.parse(events)
      for (let i = 0; i < eventsData.length; i++) {
        let event = eventsData[i];
        let eventType = event['type'];
        let eventAction = event['action'];
        let targetDiv = event['target'];
        let innerTarget;
        let renderPayload;
        if (event['innerTarger'] || event['renderPayload']) {
          innerTarget = event['innerTarger'];
          renderPayload = event['renderPayload'];
        }
        if (eventType === 'success' && type === 'success') {
          let ele = document.getElementById(targetDiv);
          if (eventAction === 'show') {
            ele.style.display = 'block';
          } else if (eventAction === 'hide') {
            ele.style.display = 'none';
          } else if (eventAction === 'render') {
            this.renderEvent(targetDiv, innerTarget, renderPayload);
          }
        } else if (eventType === 'failure' && type === 'failure') {
          if (eventAction === 'show' || eventAction === 'hide') {
            let ele = document.getElementById(targetDiv);
            if (eventAction === 'show') {
              ele.style.display = 'block';
            } else if (eventAction === 'hide') {
              ele.style.display = 'none';
            } else if (eventAction === 'render') {
              this.renderEvent(targetDiv, innerTarget, renderPayload);
            }
          }
        }
      }
    }
  }

  // public initCSSAnimation(element) {
  //   if(element.className.includes('--init')) {
  //     let splitClass = element.className.split(' ');
  //     this.initCSSAnimationsList.push(splitClass.filter((item) => { return item.includes('--init') }))
  //   }
  // }

  // public removeInitCSSAnimation() {
  //   let flatArr = this.initCSSAnimationsList.flat();
  //   flatArr.forEach(element => {
  //     let ele = document.getElementsByClassName(element);
  //     for(let i = 0; i < ele.length; i++) {
  //       ele[i].classList.remove(element);
  //       i--
  //     }
  //   });
  // }

  public autoScroll(element, interval = 12, ind = -1) {
    try {
      // element.style.setProperty('overflow-x', 'scroll', 'important');
      if (ind == -1)
        this.autoScrollInfo.push({ id: element.id, interval: interval, timer: '' });
      let scrollContainer = document.getElementById(element.id);
      // scrollContainer.style.setProperty('scroll-behavior', 'smooth', 'important');
      let defaultClick = scrollContainer.getAttribute('defaultClick');
      // let autoScrollIntervals = [];
      if (element.children && element.children.length > 1) {
        if (element.querySelectorAll(`[currentfocuselement="true"]`).length == 0)
          element.children[0].setAttribute('currentfocuselement', true);
        // this.autoScrollIntervals[this.autoScrollIntervals.length]
        ind = ind == -1 ? this.autoScrollInfo.length - 1 : ind;
        this.autoScrollInfo[ind].timer = setInterval(() => {
          let currentElement = element.querySelectorAll(`[currentfocuselement="true"]`)[0];
          currentElement.setAttribute('currentfocuselement', false);
          currentElement.classList.remove('highlightBoxBorder');
          currentElement.style.borderColor = 'transparent';
          let nextElement = currentElement.nextElementSibling || element.children[0];
          nextElement.setAttribute('currentfocuselement', true);
          // nextElement.style.border = '1px solid red';
          nextElement.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
          if (scrollContainer.getAttribute('scrollbordercolor')) {
            nextElement.style.border = '1px solid';
            nextElement.style.borderColor = scrollContainer.getAttribute('scrollbordercolor');
          }
          else {
            nextElement.classList.add('highlightBoxBorder');
          }
          if (defaultClick) {
            nextElement.click();
          }
          let previousElement = currentElement.previousElementSibling || element.children[0];
          currentElement.style.border = 'none';
        }, interval * 1000)
      }
      // @ts-ignore
      // if(LAUNCHED_FROM == 'CDN') {
      //   this.createPauseButton('CDN')
      // }
      var that = this;
      window.onclick = function (event) {
        if (event.isTrusted && event.target.id == 'continueBtn') {

          // @ts-ignore
          that.resumeAutoScroll(LAUNCHED_FROM)
        } else if (event.isTrusted) {
          // @ts-ignore
          that.pauseAutoScroll(LAUNCHED_FROM)
        }
      }
    } catch (error) { throw error; }
  }

  // pause auto scroll content
  public pauseAutoScroll(type) {

    this.autoScrollInfo.forEach((item, ind) => {
      clearInterval(item.timer);
      this.autoScrollInfo[ind].timer = 0;
    });
    // let ele = document.getElementById('pauseBtn').remove();
    this.createContinueButton(type);
  }

  public resumeAutoScroll(type) {
    this.autoScrollInfo.forEach((item, ind) => {
      let itm = document.getElementById(item.id)
      this.autoScroll(itm, item.interval, ind);
    });
    document.getElementById('continueBtn').remove();
    // this.createPauseButton(type)
  }

  // Need to apply default scroll to parent.. so that all the child elements will be scrolable
  public autoScrollMethod(element, iterationType) {

    try {
      var flavoursContainer = document.getElementById(element.id);
      flavoursContainer.style.setProperty('scroll-behavior', 'smooth', 'important');
      flavoursContainer.classList.add('removeScroll');
      var defaultClick = flavoursContainer.getAttribute('defaultClick');
      if (iterationType === "IterationX") {
        var maxScrollLeft = flavoursContainer.scrollWidth - flavoursContainer.clientWidth;
        var node = 0;
        this.autoscrollTimer = setInterval(() => {
          if (flavoursContainer.scrollWidth !== 0 && flavoursContainer.scrollLeft !== flavoursContainer.scrollWidth) {
            node = ++node;
            this.currentIndex = node;
            let prevNode = node - 1;
            if (element.children[prevNode]) {
              element.children[prevNode].style.border = 'none';
            }
            flavoursContainer.scrollTo(flavoursContainer.scrollLeft + element.children[0].offsetWidth, 0);
            if (element.children[node]) {
              // element.children[node].style.border = '1px solid red';
              if (defaultClick) {
                // 
                element.children[node].click()
                // this.eventsHandling(event, element.children[node].id);
              }
            }
            if (maxScrollLeft == flavoursContainer.scrollLeft) {
              flavoursContainer.scrollLeft = 0;
              node = 0;
              this.currentIndex = node;
              if (element.children[prevNode])
                element.children[prevNode].style.border = 'none';
              // if (element.children[node])
              // element.children[node].style.border = '1px solid red';
              // flavoursContainer.scrollTo(flavoursContainer.scrollLeft - 1, 0);
            }
          }
        }, 12000);
      } else if (iterationType === "IterationY") {
        var maxScrollLeft = flavoursContainer.scrollHeight - flavoursContainer.clientHeight;
        var node = 0;
        this.autoscrollTimer = setInterval(() => {
          if (flavoursContainer.scrollHeight !== 0 && flavoursContainer.scrollTop !== flavoursContainer.scrollHeight) {
            flavoursContainer.scrollTo(0, flavoursContainer.scrollTop + element.children[0].offsetHeight);
            node = ++node;
            this.currentIndex = node;
            if (element.children[node]) {
              // element.children[node].style.border = '1px solid red';
            }
            let prevNode = node - 1;
            if (element.children[prevNode]) {
              element.children[prevNode].style.border = 'none';
            }
            if (defaultClick) {
              element.children[node].click();
            }
            if (maxScrollLeft == flavoursContainer.scrollTop) {
              flavoursContainer.scrollTop = 0;
              node = 0;
              this.currentIndex = node;
              if (element.children[prevNode])
                element.children[prevNode].style.border = 'none';
              // if (element.children[node])
              // element.children[node].style.border = '1px solid red';
            }

          }
        }, 12000);
      }
    } catch (error) { throw error; }
  }

  public stopAutoScroll(scrollTimer) {

    clearInterval(scrollTimer);
    // @ts-ignore
    // alert(LAUNCHED_FROM);
    this.createContinueButton(LAUNCHED_FROM);
    var that = this;
    setTimeout(() => {
      that.startAnimation(that.autoScrollElementDetails);
    }, 30000);
  }

  public startAnimation(autoScrollElementDetails) {

    for (let i = 0; i < autoScrollElementDetails.length; i++) {

      let obj = autoScrollElementDetails[i];
      this.autoScrollMethod(obj.element, obj.iterationType)
    }
    document.getElementById('continueBtn').remove();
  }

  public createContinueButton(type) {
    // let ele = `<button style="border-radius: 3px; padding: 3px; border: 2px solid black; position: absolute; top: 0; left: 0;" class="focusable">Continue</button>`;
    let elem = document.createElement('div');
    elem.setAttribute(
      'style',
      'z-index: 99999; cursor: pointer; border-radius: 3px; bottom: 0; left: 45%; font-size: 12px; font-family: Gotham-book; border: 1px solid black; padding: 3px 10px; background: black; color: white; text-transform: uppercase;',
    );
    elem.innerHTML = 'Continue';
    elem.id = 'continueBtn';
    elem.className = 'focusable ba-element';
    if (type == 'CDN') {
      document.getElementById('ba_app').appendChild(elem);
      var that = this;
      document.getElementById('continueBtn').addEventListener('click', function () {
        that.resumeAutoScroll(type)
      });
    } else {
      document.getElementsByTagName('body')[0].appendChild(elem);
      var that = this;
      document.getElementById('continueBtn').addEventListener('click', function () {
        that.resumeAutoScroll(type)
      });
    }
    this.navigator.init();
    this.navigator.add({
      selector: '.focusable'
    })
  }

  public createPauseButton(type) {
    // let ele = `<button style="border-radius: 3px; padding: 3px; border: 2px solid black; position: absolute; top: 0; left: 0;" class="focusable">Continue</button>`;
    let elem = document.createElement('div');
    elem.setAttribute(
      'style',
      'border-radius: 3px; bottom: 0; left: 45%; font-size: 12px; font-family: Gotham-book; border: 1px solid black; padding: 3px 10px; background: black; color: white; text-transform: uppercase;',
    );
    elem.innerHTML = 'Pause';
    elem.id = 'pauseBtn'
    elem.className = 'focusable ba-element';
    if (type == 'CDN') {
      document.getElementById('ba_app').appendChild(elem);
      var that = this;
      document.getElementById('pauseBtn').addEventListener('click', function () {
        that.pauseAutoScroll(type)
      });
    }
  }

  public createVueComponent() {
    // 
    let elements: any = document.getElementsByClassName('ba-element');
    for (let i = 0; i < elements.length; i++) {
      // initCSSAnimation
      // this.initCSSAnimation(elements[i])
      var click = elements[i].getAttribute('v-on:click');
      if (click) {
        elements[i].style.cursor = 'pointer';
      }
      let iterable = elements[i].getAttribute('v-for') || '';
      let iterationType = elements[i].getAttribute('iterationas') || 'IterationY';
      if (iterable != '') {
        let noOfElements: number = 0;
        if (iterable.includes('slice')) {
          let start = Number(iterable.split('slice')[1].split(',')[0].replace('(', ''));
          let end = Number(iterable.split('slice')[1].split(',')[1].replace(')', ''));
          noOfElements = end - start;
        }
        if (elements[i].children.length > 0) {
          var spanList = elements[i].getElementsByClassName("dynamicVariable");
          for (let j = 0; j < spanList.length; j++) {
            const ele = spanList[j].outerHTML;
            const replaceEle = `{{ ${spanList[j].getAttribute('title')} }}`;
            elements[i].innerHTML = elements[i].innerHTML.replace(ele, replaceEle);
            j = -1;
          }
        }
        if (iterationType == 'IterationX') {
          elements[i].style.setProperty('display', 'inline-block', 'important');
          elements[i].parentNode.style.setProperty('white-space', 'nowrap', 'important');
          if (noOfElements != 1)
            elements[i].style.setProperty('position', 'relative', 'important');
          else
            elements[i].style.setProperty('position', 'absolute', 'important');
          elements[i].parentNode.style.setProperty('overflow-x', 'auto', 'important');
        } else {
          if (noOfElements != 1)
            elements[i].style.setProperty('position', 'relative', 'important');
          else
            elements[i].style.setProperty('position', 'absolute', 'important');
          elements[i].parentNode.style.setProperty('overflow-y', 'auto', 'important');
        }
      } else if (elements[i].getElementsByClassName("dynamicVariable").length > 0) {
        if (elements[i].children.length > 0) {
          var spanList = elements[i].getElementsByClassName("dynamicVariable");
          for (let j = 0; j < spanList.length; j++) {
            const ele = spanList[j].outerHTML;
            const replaceEle = `{{ ${spanList[j].getAttribute('title')} }}`;
            this.conditionalRenderingData(spanList[j], null);
            elements[i].innerHTML = elements[i].innerHTML.replace(ele, replaceEle);
            j = -1;
          }
        }
        // elements[i].style.setProperty('position', 'relative', 'important');
        // elements[i].parentNode.style.setProperty('overflow', 'auto', 'important');
      } else if (elements[i].getElementsByClassName('bindSrc')) {
        var bindList = elements[i].getElementsByClassName("bindSrc");
        for (let k = 0; k < bindList.length; k++) {
          let attr = bindList[k].getAttribute('v-bind:src')
          if (attr) {
            this.conditionalRenderingData(bindList[k], attr)
          }
        }
      }
    }
    this.BAAppData.baAppConfig.config.iterationLabels = this.BAAppData.baAppConfig.config.iterationLabels || [];
    this.BAAppData.baAppConfig.config.iterationLabels = this.BAAppData.baAppConfig.config.iterationLabels.map((item) => {
      if (typeof item == 'object') {
        return item.key;
      }
    })
    for (let i = 0; i < this.BAAppData.baAppConfig.config.iterationLabels.length; i++) {
      this.jsonObj[this.BAAppData.baAppConfig.config.iterationLabels[i]] = {};
    }

    for (let key in this.BAAppData.baAppConfig.config.renderComponents) {
      for (let j = 0; j < this.BAAppData.baAppConfig.config.renderComponents[key].length; j++) {
        if (this.BAAppData.baAppConfig.config.renderComponents[key][j].key == 'type') {
          if (this.BAAppData.baAppConfig.config.renderComponents[key][j].value == 'slideShowImages') {
            document.getElementById(key).innerHTML = '';
          }
          else if (this.BAAppData.baAppConfig.config.renderComponents[key][j].value == 'DATE') {
            document.getElementById(key).childNodes[0].nodeValue = '.';
          }
        }
      }
    }
    // this.BAAppData.baAppConfig.config.dataLabel = this.BAAppData.baAppConfig.config.dataLabel || [];
    // for (let i = 0; i < this.BAAppData.baAppConfig.config.dataLabel.length; i++) {
    //   this.jsonObj[this.BAAppData.baAppConfig.config.dataLabel[i]] = "";
    // }
    this.jsonObj = Object.assign(this.jsonObj, this.USER_INFO)
    if (this.viewComponent == '') {
      if (this.jsonObj != '') {
        var that = this;
        //@ts-ignore
        this.viewComponent = new Vue({
          el: '#vue-app',
          data: this.jsonObj,
          methods: {
            clickHandler(obj, event) {

              for (let key in obj) {
                // this._data[key] = Object.assign({}, obj[key]);
                obj[key] = obj[key];
                this._data[key] = obj[key];
              }
              that.eventsHandling(event);
            },
          }
        });
      }
      if (this.BAAppData.baAppConfig.files) {
        this.BAAppData.baAppConfig.files = this.BAAppData.baAppConfig.files || [];
        this.BAAppData.baAppConfig.files.forEach((fileObj) => {
          if (fileObj.isThirdParty) {
            switch (fileObj.type) {
              case 'script':
                let scriptHolder: any = document.createElement('script');
                scriptHolder.type = 'text/javascript';
                scriptHolder.src = fileObj.url;
                document.body.appendChild(scriptHolder);
                break;
              case 'css':
                let linkHolder: any = document.createElement('link');
                linkHolder.rel = 'stylesheet';
                linkHolder.type = 'text/css';
                linkHolder.src = fileObj.url;
                document.head.appendChild(linkHolder);
                break;
              default:
                break;
            }
          }
        })
      }
    } else {
      this.viewComponent._data = this.jsonObj;
    }
    try {
      let intercounter = 0;
      let interTrigger = setInterval(() => {

        intercounter++;
        // @ts-ignore
        let containers: any = document.querySelectorAll('#vue-app') || [];
        if (containers.length > 0) {
          for (let i = 0; i < containers.length; i++) {
            containers[i].style.visibility = 'visible';
          }
        }
        if (intercounter == 15) {
          clearInterval(interTrigger);
        }
      }, 300);
    } catch (e) { }
    // 

    let tagNamesForTitle = [];
    // let tagNamesForTitle = ['H1', 'H2', 'H3', 'H4', 'H5', 'P'];
    elements = document.getElementsByClassName('ba-element');
    for (let i = 0; i < elements.length; i++) {
      let tagName = elements[i].tagName;
      if (tagNamesForTitle.indexOf(tagName) >= 0) {
        elements[i].setAttribute('title', elements[i].innerHTML);
        elements[i].style.overflow = 'hidden';
        elements[i].style.textOverflow = 'ellipsis';
        elements[i].style.whiteSpace = 'nowrap';
      }
    }
    var int = 12;
    for (let j = 0; j < elements.length; j++) {
      if (elements[j].getAttribute('defaultscroll')) {
        let iterationType = elements[j].children[0].getAttribute('iterationas') || 'IterationX';
        // this.autoScrollMethod(elements[j], iterationType);
        //first time initiate auto scroll
        // if(j > 1) {
        //   int = int - 0.4;
        // }
        int = elements[j].getAttribute('scrollinterval') || int;
        this.autoScroll(elements[j], int);
        this.autoScrollElementDetails.push({
          "element": elements[j],
          "iterationType": iterationType
        });
        // var that = this;
        // setTimeout(() => {
        //   
        //   // let element = document.getElementById(elements[j])
        //   elements[j].addEventListener('click', function() {
        //     that.stopAutoScroll(that.autoscrollTimer)
        //   })
        // }, 2000);
      }
    }

  }

  public conditionalRenderingData = (elem, text) => {
    let condition = text;
    if (!text) {
      condition = elem.getAttribute('title').split('.');
    } else {
      condition = condition.split('.');
    }
    let attr;
    if (condition && condition.length > 2) {
      attr = `${condition[0]}.${condition[1]}`
    } else {
      attr = condition[0]
    }
    elem.parentElement.setAttribute('v-if', `${attr}`)
  }

  public formatResponse1(response) {
    let resp = {}
    try {
      for (let key in response) {
        let obj = response[key]
        let obj1 = {}
        if (key == 'secondarySources') {
          for (let i = 0; i < obj.length; i++) {
            obj1[Object.keys(obj[i])[0]] = obj[i][Object.keys(obj[i])[0]];
          }
        } else {
          for (let i = 0; i < obj.length; i++) {
            obj1[obj[i].key] = obj[i].value
          }
        }
        resp[key] = obj1
      }
    } catch (e) {
      resp = ''
    }
    return resp
  }

  public sendAnimationMessagToOO(animateMsgObj = {}) {
    animateMsgObj = this.formatResponse1(animateMsgObj);
    if (animateMsgObj) {
      Object.keys(animateMsgObj).forEach(key => {
        if (animateMsgObj.hasOwnProperty(key)) {
          try {
            const animationObj = animateMsgObj[key];
            const animationCommand = this.animationsCommand[animationObj.animationType.toLowerCase()] || "";
            if (animationCommand) {

              animationObj.mplName = animationObj.mplName.includes('.MPL') ? animationObj.mplName : `${animationObj.mplName}.MPL`;
              let animationAnalytics = {
                "eventType": animationObj.type,
                "id": animationObj.animationID,
                "command": animationCommand,
                "mplName": animationObj.mplName
              }
              this.analytics.sendAnalytics('animation', animationAnalytics);

              // @ts-ignore
              System.sendMsgToOO(`OO_MINT1${animationCommand},${animationObj.mplName}`);
            }
          } catch (e) { }
        }
      });
    }
  }

  public groupListenSocket() {

    // socket config for socket retry
    let socketConfig = {
      "reconnection": true,
      "reconnectionDelay": 1000,
      "reconnectionDelayMax": 5000,
      "reconnectionAttempts": 50
    };

    this.groupUpdateSocket = io(this.GROUP_SOCKET, socketConfig);

    this.groupUpdateSocket.on('connect', () => {

      // if (this.socketGroupIds.length >= 1) {
      this.groupUpdateSocket.emit("newConnection", "groupSession", '', [this.socketGroupIds], []);
      this.groupUpdateSocket.on('updateGroup', (groupId, updatedData) => {
        // 
        // 
        // 
        this.updateGroupData(groupId, updatedData);
      });
      // } else {
      //   
      // }
    });

    this.groupUpdateSocket.on("connect_error", () => {

    })
    this.groupUpdateSocket.on("connect_timeout", () => {

    })
    this.groupUpdateSocket.on("reconnect_error", () => {

    })
    this.groupUpdateSocket.on("reconnect_failed", () => {

    })
  }

  public updateGroupData(groupId, updatedData) {
    let spcChr = ['-', '.', ':', ';', '#', '*', '!', '@', '%', '^', '+', '/'];
    spcChr.forEach((char) => {
      if (updatedData.groupName.includes(char)) {
        updatedData.groupName = updatedData.groupName.replace(char, '');
      }
    });
    for (let grpName in this.jsonObj) {
      let updatedGroupName = updatedData.groupName.replace(/ /g, '_');
      if (grpName == updatedGroupName) {
        this.jsonObj[updatedGroupName].model.entities.unshift(updatedData.entities[0].entity);
      }
    }
  }

  // sample test code added here
  public autoSlidingElements(obj) {
    let elements = document.querySelectorAll(`[id="${obj.bind_to}"]`);
    let currentIndex = 0;
    // No of elements
    for (let i = 0; i < elements.length; i++) {
      var sliderID = `Slider_${this.generateRandomId()}`;
      // @ts-ignore
      renderItem(currentIndex)
      // @ts-ignore
      sliderID = setInterval(() => {
        renderItem();
      }, obj.interval * 1000);
    };
    function renderItem() {
      for (let j = 0; j < elements.length; j++) {
        var node = elements[j].children;
        for (let k = 0; k < node.length; k++) {
          const element = node[k];
          // @ts-ignore
          element.style.display = 'none';
          // @ts-ignore
          node[currentIndex].style.visibility = 'visible';
        }
        if (node[currentIndex]) {
          // @ts-ignore
          node[currentIndex].style.display = 'block';
          // @ts-ignore
          node[currentIndex].style.visibility = 'visible';
        }
      }
      if (node.length == currentIndex) {
        currentIndex = 0;
      } else {
        currentIndex = ++currentIndex
      }
    }
  }

  public autoSlidingSingleElements(elements, obj) {
    var nodeChildren = elements[0].children;
    var index = 0;
    setInterval(() => {
      for (var i = 0; i < nodeChildren.length; i++) {
        // @ts-ignore
        nodeChildren[i].style.display = 'none';
      }
      if (nodeChildren[index]) {
        // @ts-ignore
        nodeChildren[index].style.display = "block";
        // @ts-ignore
        nodeChildren[index].style.visibility = 'visible';
      }
      index = ++index;
      if (nodeChildren.length == index) {
        index = 0;
      }
    }, obj.interval * 1000)
  }

  public async fetchFirst100(url: string): Promise<any> {
    const response = await fetch(`${url}?page=0&size=100`);
    const data = await response.json();
    return data.model.entities;
  }
  
  public async fetchAllOtherPages(url: string, totalPages: number): Promise<any> {
    const promises: Promise<any>[] = [];
  
    for (let page = 2; page <= totalPages; page++) {
      const pageUrl = `${url}?page=${page}&size=2`;
      promises.push(fetch(pageUrl).then(response => response.json()));
    }
  
    return Promise.all(promises);
  }
  
  
  public async pluginApiRetriever(apiUrl, recordsPerPage = 100, fetchTotalRecords = true){
    let totalRecords = 1000;
    let completeRecords = [];
    // Fetch the first 100 records synchronously
    if(!fetchTotalRecords){
      let first100Records = await this.fetchFirst100(apiUrl);
      completeRecords = [...first100Records.model.entities];
    } else {
      let first100Records = await this.fetchFirst100(apiUrl);
      // Fetch the rest of the records asynchronously
      const otherPagesData = await this.fetchAllOtherPages(apiUrl, Math.ceil(totalRecords / recordsPerPage));
      let otherPagesFormattedData = [];
      for (let i = 0; i < otherPagesData.length; i++) {
        let pageData = otherPagesData[i].model.entities;
        otherPagesFormattedData = [...otherPagesFormattedData, ...pageData];
      }
    }
    return completeRecords;
  }

  public async retrievePluginData(apiUrl: string) {
    let finalData;
    if (this.paramsDetails.type == "cohort" && apiUrl.includes("/cohorts/")) {
      let apiCohortId = apiUrl.split("/")[6];
      if (apiCohortId == this.paramsDetails?.params?.cohortId) {
        let { cohortId, ...apiBody } = this.paramsDetails.params;
        finalData = await this.fetchTheData(apiUrl, apiBody);
        finalData = finalData?.model?.entities;
      } else {
        finalData = await this.fetchTheData(apiUrl);
        finalData = finalData?.model?.entities;
      }
    } else if (this.paramsDetails.type == "schema" && apiUrl.includes("/schemas/")) {
      let { schemaId, ...apiBody } = this.paramsDetails.params;
      let apiSchemaId = apiUrl.split("/")[6];
      if (apiSchemaId == this.paramsDetails.params.schemaId) {
        finalData = await this.fetchTheData(apiUrl, apiBody);
        finalData = finalData?.entities;
      } else {
        finalData = await this.fetchTheData(apiUrl);
        return finalData?.model?.entities;
      }
    } else if (this.paramsDetails.type == "bq" && apiUrl.includes("/big-queries/")) {
      let { bigQueryId, ...apiBody } = this.paramsDetails.params;
      let apiBQId = apiUrl.split("/")[6];
      if (apiBQId == this.paramsDetails.params.bigQueryId) {
        finalData = await this.fetchTheData(apiUrl, apiBody);
        finalData = finalData?.model?.entities;
      } else {
        finalData = await this.fetchTheData(apiUrl);
        finalData = finalData?.model?.entities;
      }
    } else {
      finalData = await this.fetchTheData(apiUrl);
      finalData = finalData?.model?.entities;
    }
    return finalData;
  }

  public async fetchTheData(apiUrl: string, requestBody = {}) {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getToken()}`
    };

    let payload = Object.keys(requestBody).length == 0 ? {
      method: 'GET',
      headers: headers
    } : {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestBody)
    };

    let response = await fetch(apiUrl, payload);
    let finalData = await response.json();
    return finalData;
  }

  public getToken() {
    const cookieArray = document.cookie.split(';');
    let token = null;
    for (let i = 0; i < cookieArray.length; i++) {
        const cookie = cookieArray[i].trim();
        if (cookie.startsWith('token=')) {
            token = cookie.substring('token='.length);
            break;
        }
    }
    return token;
  }

}

export { BaFramework }

import { dataLoaders } from './dataLoader'
import { BaFramework } from './baFramework'
import { CommonService } from '../services/app.service';
import $ from "jquery"
import lodash from 'lodash'
import { BehaviorSubject, Subscription } from 'rxjs';
const { find } = lodash

enum EVENT_TYPE {
  CLICK = 'CLICK',
  DOUBLE_CLICK = 'DOUBLE_CLICK',
  DEFAULT = 'click',
  MOUSE_ENTER = 'MOUSE_ENTER',
  TIMER = 'TIMER'
}

enum ACTION_TYPE {
  INIT = 'INIT',
  OVERWRITE = 'OVERWRITE',
  OVERLAY = 'OVERLAY'
}

interface Variables {
  [key: string]: any;
}

interface ExpVariables {
   name: string,
   default: any
}

interface RouteState{
   appletId:string
}


class MultipleBaFramework {

  engagement: any
  content: any
  baOptions: any
  framework: any
  multiBaData:any
  GLOBAL_VARIABLES: Variables = {};
  subscriptions:Subscription[] = [];


  constructor(config?, framework?,data?) {
    this.engagement = config;
    this.framework = framework
    this.multiBaData = data;
    this.initMultipleBAs(config.channel.contents)
  }

  public async initMultipleBAs(engagementContents?) {
    console.log("initMultipleBAs", engagementContents)
    this.handleMultiAppVariables(this.multiBaData?.globalVariables || [])
    this.content = (engagementContents) ? engagementContents : [
      {
        contentType: 'BA',
        baId: '630e4e78ea3a640001d9d84a',
        userId: '1194',
        serviceId: 'gaian',
        datasources: [],
        contentConfig: {
          events: [],
          customEvents: [
            {
              id:
                'id_630e4e78ea3a640001d9d84a9146f654-303c-499b-aa88-a0987cd9b960',
              name: 'wait 10seconds',
              type: 'TIMER',
              details: {
                timer_input: '00:00:05',
                element_selected: ''
              },
              action: {
                type: 'OVERLAY',
                executed_for: '-1',
                executed_each: '-1'
              },
              target: [
                {
                  baId: '62516dc061bfd30001d52278',
                  trigger: {}
                }
              ]
            },
            {
              id:
                'id_630e4e78ea3a640001d9d84a9146f654-303c-499b-aa88-a0987cd9b960',
              name: 'wait 20seconds',
              type: 'TIMER',
              details: {
                timer_input: '00:00:10',
                element_selected: ''
              },
              action: {
                type: 'OVERWRITE',
                executed_for: '-1',
                executed_each: '-1'
              },
              target: [
                {
                  baId: '630e4ee8ea3a640001d9d84f',
                  trigger: {}
                }
              ]
            }
          ]
        }
      },
      {
        contentType: 'BA',
        baId: '630e4ee8ea3a640001d9d84f',
        userId: '1194',
        serviceId: 'gaian',
        datasources: [],
        contentConfig: {
          events: [],
          customEvents: []
        }
      }
    ]
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);

// Extract the pathname
  const pathname = url.pathname;

// Get the part after the last '/' in the pathname
// const extractedPart = pathname.substring(pathname.lastIndexOf('/') + 1);

  // if(extractedPart && extractedPart.split('?')[0]!=='index.html'){
  //   let actualPath=pathname.split('index.html/')[1].split('?')[0];
  //   homeApplet = this.content.find(applet => applet.contentConfig.data.path===actualPath);
  // }
  // else{
  //   homeApplet = this.content.find(applet => applet.contentConfig.home);
  // }  
  let homeApplet = this.content.find(applet => applet.contentConfig?.home);
  if(window.history.state?.appletId){
    let appletConf = this.content.find(applet => applet.baId == window.history.state.appletId);
    if(appletConf){
      this.handleContent(appletConf, ACTION_TYPE.INIT);
      return;
    }
  }
  if (!homeApplet) {
    homeApplet = this.content[0];
  }
  try{
    const currentUrl = window.location.href;
    const rouetState:RouteState = {appletId:homeApplet.baId};
    window.history.pushState(rouetState, '', `/${homeApplet.contentConfig.data.routePath}?${currentUrl.split('?')[1]}`);
    this.handleContent(homeApplet, ACTION_TYPE.INIT);
  }
  catch(error){
      console.log(error);
  }
  // this.handleContent(this.content[0], ACTION_TYPE.INIT);
}

  public storeDataFromGlobalToFramework(id = ''): void {
    try {
      const mapData = this.multiBaData?.globalVariablesToApplets[id];
      if (!mapData) {
        console.warn(`No mapping data found for id: ${id}`);
        return;
      }
  
      for (const [source, target] of mapData) {
        const desTVariable = this.framework.GLOBAL_VARIABLES[target];
        if (desTVariable) {
          try {
            desTVariable.next({value:this.GLOBAL_VARIABLES[source]});
          } catch (error) {
            console.error(`Error updating global variable from framework for source: ${source} to target: ${target}`, error);
          }
        } else {
          console.warn(`Source variable not found in framework for source: ${source}`);
        }
      }
    } catch (error) {
      console.error('Error in storeDataFromGlobalToFramework method', error);
    }
  }
  
  public storeDataFromFrameworkToGlobal(id = ''): void {
    try {
      const mapData = this.multiBaData?.storeInGlobalVariables[id];
      if (!mapData) {
        console.error(`No mapping data found for id: ${id}`);
        return;
      }
  
      for (const [source, target] of mapData) {
        const sourceVariable = this.framework.GLOBAL_VARIABLES[source];
        if (sourceVariable) {
          try {
            this.subscriptions.push(
              sourceVariable.subscribe(data => {
                this.GLOBAL_VARIABLES[target] = data?.value || null;
              })
            );
          } catch (error) {
            console.error(`Error subscribing to framework variable for source: ${source} to target: ${target}`, error);
          }
        } else {
          console.warn(`Source variable not found in framework for source: ${source}`);
        }
      }
    } catch (error) {
      console.error('Error in storeDataFromFrameworkToGlobal method', error);
    }
  }
  
  public unSubscribe(): void {
    try {
      this.subscriptions.forEach(subscription => {
        try {
          subscription.unsubscribe();
        } catch (error) {
          console.error('Error unsubscribing from subscription', error);
        }
      });
      this.subscriptions = []; // Clear the subscriptions array after unsubscribing
    } catch (error) {
      console.error('Error in unSubscribe method', error);
    }
  }
  

  public async handleContent(content: any, actionType): Promise<void> {
    try {
       // Access the current URL
     const currentUrl = window.location.href;
     this.unSubscribe();
     // Update the URL using replaceState
    //  if (content.contentConfig.data.hasOwnProperty('path')&&content.contentConfig.data.path!=='') {
    //      window.history.pushState({}, '', `/index.html/${content.contentConfig.data.path}?${currentUrl.split('?')[1]}`);
    //  }
    //  else{
    //   window.history.pushState({}, '', `/index.html?${currentUrl.split('?')[1]}`);
    //  }
      // this.framework = new BaFramework()
      this.framework.initBAppWithMulti(
        'ba_app',
        content.baId,
        CommonService.getUserInfo().tenantId,
        'v_1',
        false,
        30000,
        true
      ).then(() => {
        setTimeout(() => {
          console.log(content);
          this.storeDataFromFrameworkToGlobal(content?.baId);
          this.storeDataFromGlobalToFramework(content?.baId);
          // Handle Custom Events
          content.contentConfig.data.customEvent = (content.contentConfig.data.customEvent) ? content.contentConfig.data.customEvent : [];
          console.log(content.contentConfig.data.customEvent)
          for (const event of content.contentConfig.data.customEvent) {
            this.HandleEvent(event, content.baId)
          }
          content.contentConfig.events = (content.contentConfig.events) ? content.contentConfig.events : [];
          for (const event of content.contentConfig.events) {
            this.HandleEvent(event, content.baId)
          }
          window.scrollTo(0, 0);
          setTimeout(() => {
            this.hideLoader()
          }, 50);
        }, 200);
      })
    } catch (error) {
      console.log(error)
    }
  }


  public async handleMultiAppVariables(baVariables: ExpVariables[]) {
    try{
      baVariables?.forEach(variable => {
        this.GLOBAL_VARIABLES[variable.name] = variable.default;
      });
    }catch(error){
      console.log(error);
    }

  }

  public hideContent(baName) {
    try {
      this.framework.hideTemplate(baName);
    } catch (e) {
      console.log(e);
    }

  }

  public async handleEventByBAID(baid: any) {
    try {
      console.log(baid);
      const content = find(this.content, ['baId', baid])

      console.log(content);
      // Handle Custom Events
      content.contentConfig.customEvents = (content.contentConfig.customEvents) ? content.contentConfig.customEvents : [];
      console.log(content.contentConfig.customEvents)
      for (const event of content.contentConfig.customEvents) {
        this.HandleEvent(event, content.baId)
      }
      content.contentConfig.events = (content.contentConfig.events) ? content.contentConfig.events : [];
      for (const event of content.contentConfig.events) {
        this.HandleEvent(event, content.baId)
      }

    } catch (e) {
      console.log(e)
    }
  }

  public async HandleEvent(event: any, contentBaId?: string): Promise<void> {
    console.log(event, contentBaId)
    switch (event.type) {
      case EVENT_TYPE.CLICK:
        this.handleClickEvent(event, 'click')
        break
      case EVENT_TYPE.DOUBLE_CLICK:
          this.handleClickEvent(event,'dblclick')
          break
      case EVENT_TYPE.MOUSE_ENTER:
        this.handleClickEvent(event,'mouseenter')
        break
      case EVENT_TYPE.DEFAULT:
        this.handleClickEvent(event)
        break
      case EVENT_TYPE.TIMER:
        this.handleTimerEvent(event, contentBaId)
        break
      default:
        break
    }
  }

  public replacePlaceholdersInRoute(routeTemplate) {
    // Use a regular expression to find all placeholders in the format {placeholder}
    const placeholderPattern = /\{(\w+)\}/g;

    // Replace each placeholder with the corresponding value from framework.GLOBAL_VARIABLES
    const route = routeTemplate.replace(placeholderPattern, (match, p1) => {
        const variableName = p1;
        
        // Check if the variable is defined in the global variables
        if (this.framework.GLOBAL_VARIABLES[variableName] && this.framework.GLOBAL_VARIABLES[variableName]._value) {
            return this.framework.GLOBAL_VARIABLES[variableName]._value.value;
        } else {
            console.error(`framework.GLOBAL_VARIABLES['${variableName}']._value.value is not defined.`);
            return `{${variableName}}`; // Leave the placeholder unchanged if not found
        }
    });

    return route;
}

  public async handleClickEvent(event: any, eventType:string = 'click'): Promise<void> {
    console.log("EVENT", event)
        $(document).on(`${eventType} sn:enter-down`, `[id^="${event.details.elementId}"]`, (e: any) => {
          e.stopPropagation();
          const routePath = this.replacePlaceholdersInRoute(event?.route);
          const currentUrl = window.location.href;
          const rouetState:RouteState = {appletId:event.destination};
          window.history.pushState(rouetState, '', `/${routePath}?${currentUrl.split('?')[1]}`);
          this.handleOverwriteAction(ACTION_TYPE.OVERWRITE, event.destination,event.showLoader);
        });
  }



  public async handleTimerEvent(event: any, contentBaId?: string): Promise<void> {
    const timer = calculSecond(event.details.timer_input)
    const intervalFor = calculSecond(event.action.executed_for)
    return new Promise(resolve => {
      setTimeout(async () => {
        if (event.action.executed_each === '-1') {
          console.log(event.action)
          await this.handleAction(event.action, event.destination)
          if (event.action.executed_for === '-1') {
            resolve()
          } else {
            setTimeout(async () => {
              // Hide after a period
              this.hideContent(event.target[0].baName);
              resolve()
            }, intervalFor);
          }
        } else if (event.action.executed_each !== '-1') {
          this.handleRecursiveAction(event, contentBaId)
        }
      }, timer)
    })
  }

  public async handleAction(action: any, target: any) {
    switch (action.type) {
      case ACTION_TYPE.OVERLAY:
        this.handleOverlayAction(action, target)
        break
      case ACTION_TYPE.OVERWRITE:
        console.log(action, target)
        this.handleOverwriteAction(action, target)
        break
      default:
        break
    }
  }

  public async handleRecursiveAction(event: any, contentBaId: string) {
    const intervalEach = calculSecond(event.action.executed_each)
    const intervalFor = calculSecond(event.action.executed_for)
    this[contentBaId + '_' + event.id] = true
    if (this[contentBaId + '_' + event.id]) {
      const content = this.findNextBa(event.target[0].baId)
      console.log(content)
      this.handleContent(content, ACTION_TYPE.OVERLAY)
      setTimeout(() => {
        this.hideContent(event.target[0].baName);
        setTimeout(() => this.handleRecursiveAction(event, contentBaId), intervalEach);
      }, intervalFor);
    }
  }

  public async handleOverlayAction(action: any, target: any) {
    const content = this.findNextBa(target.baId)
    this.handleContent(content, ACTION_TYPE.OVERLAY)
  }

  public async handleOverwriteAction(action: any, target: any,loader:any=true) {
     // Remove all existing click events in previous BaApp to avoid recursive calls
     $(document).off('click');
    try {
       let content=await this.findNextBa(target)
      if(loader){
        this.showLoader();
      }
      this.handleContent(content, ACTION_TYPE.OVERWRITE)
    } catch (error) {
      console.log("Overrite Action Error", error)
      this.hideLoader();
    }
  }

  public showLoader(){
    if(document.getElementById('monetLoader')){
      let loader = document.getElementById('monetLoader');
      loader.style.display = 'block';
      let overlay = document.getElementById('monetOverlay');
      overlay.style.display = 'block';
    } else {
      // Create a new div element for the loader
      let loader = document.createElement('div');
      loader.id = 'monetLoader';
      loader.style.display = 'block';
      loader.style.position = 'fixed';
      loader.style.zIndex = '9999999';
      loader.style.overflow = 'show';
      loader.style.margin = 'auto';
      loader.style.top = '0';
      loader.style.left = '0';
      loader.style.bottom = '0';
      loader.style.right = '0';
      loader.style.width = '40px';
      loader.style.height = '40px';
      loader.style.border = '4px solid #f3f3f3'; 
      loader.style.borderTop = '4px solid #3498db'; 
      loader.style.borderRadius = '50%';
      loader.style.animation = 'spin 2s linear infinite';
      // loader.style.background = 'rgba(0, 0, 0, 0.5)';

      // Create a new div element for the overlay
      let overlay = document.createElement('div');
      overlay.id = 'monetOverlay';
      overlay.style.display = 'block';
      overlay.style.position = 'fixed';
      overlay.style.zIndex = '9999999'; // One less than the loader, so it appears behind it
      overlay.style.top = '0';
      overlay.style.left = '0';
      overlay.style.width = '100%';
      overlay.style.height = '100%';
      overlay.style.background = 'rgba(0, 0, 0, 0.5)'; // Semi-transparent black
      
      
      // Append the loader to the body
      document.body.appendChild(loader);
      document.body.appendChild(overlay);

      // Add keyframes for spin animation
      let style = document.createElement('style');
      style.innerHTML = `@keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }`;
      document.head.appendChild(style);

      // You can hide the loader by setting the display to 'none'
      // loader.style.display = 'none';
    }
    
  }

  public hideLoader(){
    let loader = document.getElementById('monetLoader');
    let overlay = document.getElementById('monetOverlay');
    if(loader && overlay){
      loader.style.display = 'none';
      overlay.style.display = 'none';
    }
  }

  private findNextBa(id: string): number {
    return find(this.content, ['baId', id])
  }
}

// HELPERS
function getObject(path: string): any {
  return new Promise(function (resolve, reject) {
    try {
      let requestOptions = {
        type: 'GET',
        path
      }
      dataLoaders.processRequest(requestOptions).then(data => {
        resolve(data)
      })
    } catch (error) {
      console.log(error)
      reject(error)
    }
  })
}

function calculSecond(stringTime: any): number {
  const [hours, minutes, second] = stringTime.split(':')
  var numHour: number = +hours
  var numMonth: number = +minutes
  var numSec: number = +second
  return (numHour * 3600 + numMonth * 60 + numSec) * 1000
}

// EXPORTS
export { MultipleBaFramework }
